// import { ToastConsumer } from "react-toast-notifications";
import { toast } from 'react-toastify';
import { CatchError } from '../CatchError';
import { ProfileActions } from '../Profile/ProfileRedux';
import {
  CreateContact,
  CreateContactAddress,
  CreateContactDate,
  CreateContactEmail,
  CreateContactPhone,
  DeleteContact,
  DeleteContactAddress,
  DeleteContactDate,
  DeleteContactEmail,
  DeleteContactPhone,
  GetAllContacts,
  GetContactAddressById,
  GetContactById,
  GetContactDateById,
  GetContactEmailById,
  GetContactPhoneById,
  UpdateContact,
  UpdateContactAddress,
  UpdateContactDate,
  UpdateContactEmail,
  UpdateContactPhone
} from './ContactCrud';

const initialState = {
  data: [],
  selected: {},
  is_loaded: false,
  is_loading: false,
  is_send: false
};

const ActionTypes = {
  START_LOADING: 'CONTACT_START_LOADING',
  STOP_LOADING: 'CONTACT_STOP_LOADING',
  SET_DATA: 'CONTACT_SET_DATA',
  SET_SELECTED: 'CONTACT_SET_SELECTED',
  START_SEND_DATA: 'CONTACT_START_SEND_DATA',
  STOP_SEND_DATA: 'CONTACT_STOP_SEND_DATA'
};

export const ContactReducers = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SET_DATA:
      return {
        ...state,
        data: action.data || [],
        is_loaded: true,
        is_loading: false
      };
    case ActionTypes.START_LOADING:
      return { ...state, is_loading: true };
    case ActionTypes.STOP_LOADING:
      return { ...state, is_loading: false };
    case ActionTypes.START_SEND_DATA:
      return { ...state, is_send: true };
    case ActionTypes.STOP_SEND_DATA:
      return { ...state, is_send: false };
    case ActionTypes.SET_SELECTED:
      return { ...state, selected: action.data || {} };
    default:
      return { ...state };
  }
};

export const ContactActions = {
  loadContacts: () => async (dispatch) => {
    dispatch({ type: ActionTypes.START_LOADING });
    try {
      let res = await GetAllContacts();
      // console.log('contact result from dispatch', res);
      if (res.code === 200) {
        dispatch({ type: ActionTypes.SET_DATA, data: res.data });
      }
    } catch (err) {
      CatchError(err);
    }
    dispatch({ type: ActionTypes.STOP_LOADING });
  },
  getContactById: (id) => async (dispatch) => {
    let ret;
    dispatch({ type: ActionTypes.START_LOADING });
    try {
      let res = await GetContactById(id);
      if (res.code === 200) {
        dispatch({ type: ActionTypes.SET_SELECTED, data: res.data });
        ret = res.data;
      }
    } catch (err) {
      CatchError(err);
    }
    dispatch({ type: ActionTypes.STOP_LOADING });
    return ret;
  },
  createContact: (data) => async (dispatch) => {
    let ret;
    dispatch({ type: ActionTypes.START_SEND_DATA });
    try {
      let res = await CreateContact(data);
      if (res.code === 201) {
        dispatch(ContactActions.loadContacts());
        toast.success('Create Contact Success');
        ret = res;
      }
    } catch (err) {
      CatchError(err);
    }
    dispatch({ type: ActionTypes.STOP_SEND_DATA });
    return ret;
  },
  updateContact: (data) => async (dispatch) => {
    let ret;
    dispatch({ type: ActionTypes.START_SEND_DATA });
    try {
      let res = await UpdateContact(data);
      if (res.code === 200) {
        dispatch(ContactActions.loadContacts());
        toast.success('Update Contact Success');
        ret = res;
      }
    } catch (err) {
      CatchError(err);
    }
    dispatch({ type: ActionTypes.STOP_SEND_DATA });
    return ret;
  },
  deleteContact: (data) => async (dispatch) => {
    let ret;
    dispatch({ type: ActionTypes.START_SEND_DATA });
    try {
      let res = await DeleteContact(data);
      if (res.code === 200) {
        dispatch(ContactActions.loadContacts());
        toast.success('Delete Contact Success');
        ret = res;
      }
    } catch (err) {
      CatchError(err);
    }
    dispatch({ type: ActionTypes.STOP_SEND_DATA });
    return ret;
  },
  createContactAddress: (data) => async (dispatch) => {
    let ret;
    dispatch({ type: ActionTypes.START_SEND_DATA });
    try {
      let res = await CreateContactAddress(data);
      // console.log("result create address", res);
      if (res.code === 201) {
        dispatch(ContactActions.getContactById(data.contact_id));
        toast.success('Create Address Success');
        ret = res;
      }
    } catch (err) {
      CatchError(err);
    }
    dispatch({ type: ActionTypes.STOP_SEND_DATA });
    return ret;
  },
  getContactAddressById: (id) => async (dispatch) => {
    let ret;
    dispatch({ type: ActionTypes.START_LOADING });
    try {
      let res = await GetContactAddressById(id);
      if (res.code === 200) {
        ret = res.data;
      }
    } catch (err) {
      CatchError(err);
    }
    dispatch({ type: ActionTypes.STOP_LOADING });
    return ret;
  },
  updateContactAddress: (data) => async (dispatch) => {
    let ret;
    dispatch({ type: ActionTypes.START_SEND_DATA });
    try {
      let res = await UpdateContactAddress(data);
      if (res.code === 200) {
        dispatch(ContactActions.getContactById(data.contact_id));
        toast.success('Update Address Success');
        ret = res;
      }
    } catch (err) {
      CatchError(err);
    }
    dispatch({ type: ActionTypes.STOP_SEND_DATA });
    return ret;
  },
  deleteContactAddress: (data) => async (dispatch) => {
    let ret;
    dispatch({ type: ActionTypes.START_SEND_DATA });
    try {
      let res = await DeleteContactAddress(data);
      if (res.code === 200) {
        dispatch(ContactActions.getContactById(data.contact_id));
        toast.success('Delete Address Success');
        ret = res;
      }
    } catch (err) {
      CatchError(err);
    }
    dispatch({ type: ActionTypes.STOP_SEND_DATA });
    return ret;
  },
  createContactEmail: (data) => async (dispatch) => {
    let ret;
    dispatch({ type: ActionTypes.START_SEND_DATA });
    try {
      let res = await CreateContactEmail(data);
      if (res.code === 201) {
        dispatch(ContactActions.getContactById(data.contact_id));
        toast.success('Create Email Success');
        ret = res;
      }
    } catch (err) {
      CatchError(err);
    }
    dispatch({ type: ActionTypes.STOP_SEND_DATA });
    return ret;
  },
  getContactEmailById: (id) => async (dispatch) => {
    let ret;
    dispatch({ type: ActionTypes.START_LOADING });
    try {
      let res = await GetContactEmailById(id);
      if (res.code === 200) {
        ret = res.data;
      }
    } catch (err) {
      CatchError(err);
    }
    dispatch({ type: ActionTypes.STOP_LOADING });
    return ret;
  },
  updateContactEmail: (data) => async (dispatch) => {
    let ret;
    dispatch({ type: ActionTypes.START_SEND_DATA });
    try {
      let res = await UpdateContactEmail(data);
      if (res.code === 200) {
        dispatch(ContactActions.getContactById(data.contact_id));
        toast.success('Update Email Success');
        ret = res;
      }
    } catch (err) {
      CatchError(err);
    }
    dispatch({ type: ActionTypes.STOP_SEND_DATA });
    return ret;
  },
  deleteContactEmail: (data) => async (dispatch) => {
    let ret;
    dispatch({ type: ActionTypes.START_SEND_DATA });
    try {
      let res = await DeleteContactEmail(data);
      if (res.code === 200) {
        dispatch(ContactActions.getContactById(data.contact_id));
        toast.success('Delete Email Success');
        ret = res;
      }
    } catch (err) {
      CatchError(err);
    }
    dispatch({ type: ActionTypes.STOP_SEND_DATA });
    return ret;
  },
  createContactPhone: (data) => async (dispatch) => {
    let ret;
    dispatch({ type: ActionTypes.START_SEND_DATA });
    try {
      let res = await CreateContactPhone(data);
      if (res.code === 201) {
        dispatch(ContactActions.getContactById(data.contact_id));
        toast.success('Create Phone Number Success');
        ret = res;
      }
    } catch (err) {
      CatchError(err);
    }
    dispatch({ type: ActionTypes.STOP_SEND_DATA });
    return ret;
  },
  getContactPhoneById: (id) => async (dispatch) => {
    let ret;
    dispatch({ type: ActionTypes.START_LOADING });
    try {
      let res = await GetContactPhoneById(id);
      if (res.code === 200) {
        ret = res.data;
      }
    } catch (err) {
      CatchError(err);
    }
    dispatch({ type: ActionTypes.STOP_LOADING });
    return ret;
  },
  updateContactPhone: (data) => async (dispatch) => {
    let ret;
    dispatch({ type: ActionTypes.START_SEND_DATA });
    try {
      let res = await UpdateContactPhone(data);
      if (res.code === 200) {
        dispatch(ContactActions.getContactById(data.contact_id));
        toast.success('Update Phone Number Success');
        ret = res;
      }
    } catch (err) {
      CatchError(err);
    }
    dispatch({ type: ActionTypes.STOP_SEND_DATA });
    return ret;
  },
  deleteContactPhone: (data) => async (dispatch) => {
    let ret;
    dispatch({ type: ActionTypes.START_SEND_DATA });
    try {
      let res = await DeleteContactPhone(data);
      if (res.code === 200) {
        dispatch(ContactActions.getContactById(data.contact_id));
        toast.success('Delete Phone Number Success');
        ret = res;
      }
    } catch (err) {
      CatchError(err);
    }
    dispatch({ type: ActionTypes.STOP_SEND_DATA });
    return ret;
  },
  createContactDate: (data) => async (dispatch) => {
    let ret;
    dispatch({ type: ActionTypes.START_SEND_DATA });
    try {
      let res = await CreateContactDate(data);
      if (res.code === 201) {
        dispatch(ContactActions.getContactById(data.contact_id));
        dispatch(ProfileActions.loadProfile());
        toast.success('Create Event Success');
        ret = res;
      }
    } catch (err) {
      CatchError(err);
    }
    dispatch({ type: ActionTypes.STOP_SEND_DATA });
    return ret;
  },
  getContactDateById: (id) => async (dispatch) => {
    let ret;
    dispatch({ type: ActionTypes.START_LOADING });
    try {
      let res = await GetContactDateById(id);
      if (res.code === 200) {
        ret = res.data;
      }
    } catch (err) {
      CatchError(err);
    }
    dispatch({ type: ActionTypes.STOP_LOADING });
    return ret;
  },
  updateContactDate: (data) => async (dispatch) => {
    let ret;
    dispatch({ type: ActionTypes.START_SEND_DATA });
    try {
      let res = await UpdateContactDate(data);
      if (res.code === 200) {
        dispatch(ContactActions.getContactById(data.contact_id));
        dispatch(ProfileActions.loadProfile());
        toast.success('Update Event Success');
        ret = res;
      }
    } catch (err) {
      CatchError(err);
    }
    dispatch({ type: ActionTypes.STOP_SEND_DATA });
    return ret;
  },
  deleteContactDate: (data) => async (dispatch) => {
    let ret;
    dispatch({ type: ActionTypes.START_SEND_DATA });
    try {
      let res = await DeleteContactDate(data);
      if (res.code === 200) {
        dispatch(ContactActions.getContactById(data.contact_id));
        dispatch(ProfileActions.loadProfile());
        toast.success('Delete Event Success');
        ret = res;
      }
    } catch (err) {
      CatchError(err);
    }
    dispatch({ type: ActionTypes.STOP_SEND_DATA });
    return ret;
  }
};
