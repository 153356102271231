const defaultState = {
  loadingPage: false,
  loadingSend: false,
  restData: {},
  code: 500,
  message: '',
  session_id: '',
};

let response = {};
const Auth_lib = (state = defaultState, action = {}) => {
  const { data } = action;

  switch (action.type) {
    case 'SUCCESS_AUTH':
      if (data) {
        _setAdmin('user_status', action.code);
        _setAdmin('session_id', data.session_id);
      }

      response = {
        restData: action.data ? action.data : {},
        code: 200,
        loadingPage: false,
        loadingSend: false,
        message: action.message ? action.message : '',
        session_id: data.session_id ? data.session_id : null,
      };

      break;
    case 'LOADING_AUTH':
      response = {
        ...state,
        loadingPage: true,
        loadingSend: true,
      };
      break;
    case 'ERROR_AUTH':
      response = {
        ...state,
        loadingPage: false,
        loadingSend: false,
        message: action.message ? action.message : '',
        code: action.code ? action.code : 500,
      };
      break;
    default:
      return {
        ...state,
        session_id: _getLocalStorage('session_id') ? _getLocalStorage('session_id') : null,
      };
  }

  return response;
};

export const _setAdmin = (key, value) => {
  return localStorage.setItem(key, value);
};

const _getLocalStorage = (key) => {
  return localStorage.getItem(key);
};

export default Auth_lib;
