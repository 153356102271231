import axios from 'axios';
import { toast } from 'react-toastify';
import { CONFIG_ } from './Config';

// const timeout = {
//   timeout: 1000 * 5
// };

var jwt_strapi = '';

export const strapi_endpoint = process.env.REACT_APP_STRAPIENDPOINT;
export const strapi_token = process.env.REACT_APP_STRAPI_EXTRA_TOKEN;

export const CONNECTION = (config) => {
  return axios({ ...config })
    .then((response) => {
      const { code } = response.data;
      if (code === 200 || code === 201) {
        return response.data;
      }
      return response.data;
    })
    .catch((response) => {
      // console.log(response);
      return { code: 500, message: 'Network error!!', errors: response };
    });
};

export const CONNECTION_2 = () => {
  return axios.interceptors.request.use((config) => {
    // console.log(config);
    return config;
  });
};

export const loginStrapi = async () => {
  const authEndpoint = process.env.REACT_APP_PATH_AUTH;
  try {
    let config = CONFIG_({ url: authEndpoint + 'strapi-token', method: 'GET' });
    let res = await CONNECTION(config);
    if (res.code === 200) {
      jwt_strapi = res.data.strapi_token;
      return jwt_strapi;
    } else {
      toast.warning(res.message || 'Error get strapi token');
    }
  } catch (err) {
    // console.log(err);
    toast.error('Error connection while get strapi token');
  }
};

export const STRAPI = async ({ path = '', method, data = {}, headers = {} }) => {
  // console.log('current_jwt before login', jwt_strapi);
  if (jwt_strapi === '') {
    // await loginStrapi();
  }
  // console.log('current_jwt after login', jwt_strapi);

  try {
    let res = await axios({
      method: method,
      url: strapi_endpoint + path,
      data: data,
      headers: {
        ...headers,
      },
    });
    return res;
  } catch (err) {
    // console.log('error found', err);
    return {};
  }
};

export const flattenObj = (data) => {
  const isObject = (data) => Object.prototype.toString.call(data) === '[object Object]';
  const isArray = (data) => Object.prototype.toString.call(data) === '[object Array]';

  const flatten = (data) => {
    if (!data.attributes) return data;

    return {
      id: data.id,
      ...data.attributes,
    };
  };

  if (isArray(data)) {
    return data.map((item) => flattenObj(item));
  }

  if (isObject(data)) {
    if (isArray(data.data)) {
      data = [...data.data];
    } else if (isObject(data.data)) {
      data = flatten({ ...data.data });
    } else if (data.data === null) {
      data = null;
    } else {
      data = flatten(data);
    }

    for (const key in data) {
      data[key] = flattenObj(data[key]);
    }

    return data;
  }

  return data;
};
