import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Card, CardBody, Form, Row, FormGroup } from 'reactstrap';
// import { InputField } from "../../components";
// import imageVerified from "../../../images/verified.png";
import { __getDataVerify } from '../../modules/Member';

class Verify extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: '',
      title: ''
    };
  }

  componentDidMount() {
    const { match } = this.props;
    const { params } = match;
    const { token } = params;

    if (token !== undefined) {
      return this.props.__getDataVerify({ token: token });
    }
  }

  componentDidUpdate(prevProps) {
    const { memberVerify } = this.props;

    if (prevProps.memberVerify.code !== memberVerify.code && memberVerify.code === 400) {
      return this.setReport();
    }
    if (prevProps.memberVerify.code !== memberVerify.code && memberVerify.code === 200) {
      return this.setReport();
    }
  }

  setReport = () => {
    const { memberVerify } = this.props;
    if (memberVerify.code === 500) {
      return this.setState((state) => ({
        ...state,
        title: 'Verified Email',
        message: 'Congratulations, You have succesfully verified Your account'
      }));
    }

    return this.setState({
      message:
        'Your verification link has expired. Please <a href="https://giftano.com/faq/how-to-request-for-a-new-verification-email" target="_blank">request for a new verification email</a> or contact us at <a target="_blank" href="mailto:support@giftano.com">support@giftano.com</a>.',
      title: 'Verification link expired'
    });
  };

  render() {
    const { memberVerify } = this.props;
    const { loadingPage } = memberVerify;
    const { title, message } = this.state;

    return (
      <div className="wrapper-login">
        <div className="login-container">
          <Card className="py-2 px-2">
            <CardBody className="text-center">
              <Row>
                <img
                  src="https://giftano.imgix.net/assets/Giftano-Logo-with-text.png?auto=format"
                  className="auth-logo mx-auto d-block"
                  alt={'Giftano logo'}
                />
              </Row>

              {!loadingPage ? (
                <>
                  <h4 className=" mt-4 card-title text-center text-primary">{title}</h4>
                  <Form onSubmit={this.handleSubmit}>
                    <h6
                      className="font-weight-bold text-center mb-3"
                      dangerouslySetInnerHTML={{ __html: message }}
                    ></h6>
                    <a
                      className="btn btn-primary  w-100 btn-google m-0 btn-block btn-outline-primary text-secondary"
                      href="https://giftano.com/"
                    >
                      Go to Homepage
                    </a>
                    <FormGroup className="text-right"></FormGroup>
                  </Form>
                </>
              ) : (
                'loading...'
              )}
            </CardBody>
          </Card>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ memberVerify }) => ({
  memberVerify
});

const mapDispatchToProps = (dispatch) => ({
  __getDataVerify: (params) => [dispatch(__getDataVerify(params))]
});

export default connect(mapStateToProps, mapDispatchToProps)(Verify);
