import { CONNECTION } from '../../config/Connections';
import { CONFIG_ } from '../../config/Config';

var path = process.env.REACT_APP_PATH_MEMBER;
var get = 'email-verification/';
// var post = "";
var isLive = true;
export const __getDataVerify = (params) => {
  const { token } = params;
  return (dispatch) => {
    let config = CONFIG_({
      url: get + token,
      method: 'GET',
      path: path,
      live: isLive
    });
    dispatch({ type: 'LOADING_VERIFY_MEMBER' });
    CONNECTION(config)
      .then((response) => {
        const { code, data, message } = response;
        if (code === 200) {
          return dispatch({
            type: 'SUCCESS_VERIFY_MEMBER',
            message: 'Success get data',
            data: data,
            code: code
          });
        } else {
          return dispatch({
            type: 'ERROR_VERIFY_MEMBER',
            message: message,
            code: 400
          });
        }
        // console.log(message);
      })
      .catch((response) => {
        // console.log(response);
        return dispatch({
          type: 'ERROR_VERIFY_MEMBER',
          message: 'Network error..',
          code: 400,
          errors: response
        });
      });
  };
};
