import React, { Component, createRef } from 'react';
import { Button, Form, FormGroup, FormText } from 'reactstrap';
import { InputField } from '../../../components';

class FormCreatePassword extends Component {
  constructor(props) {
    super(props);
    this.fields = [createRef(), createRef()];
    this.state = {
      data: {
        password: '',
        repassword: '',
      },

      error: '',
    };
  }
  handleSubmit = (e) => {
    e.preventDefault();
    var valid = true;
    var isValid = true;
    let { data } = this.state;
    const { password, repassword } = data;
    // trim data
    data.password = data.password.trim();

    for (let index = 0; index < this.fields.length; index++) {
      valid = this.fields[index].current.validate();
      if (!valid) {
        isValid = false;
      }
    }

    if (isValid) {
      if (password.trim() !== repassword.trim() && repassword !== '') {
        isValid = false;
        this.setState(() => ({
          error: 'Your password and repassword not match',
        }));
      }
    }

    if (isValid) {
      //   funtion to send data
      this.props.callback({ password: repassword }, 2);
    }
  };

  handleChange = (name, value) => {
    const { data } = this.state;
    this.setState((state) => ({
      ...state,
      data: {
        ...data,
        [name]: value,
      },
    }));
  };
  render() {
    const { data, error } = this.state;
    const { repassword, password } = data;
    return (
      <div>
        <div className=' d-flex justify-content-center'>
          <h4 className='my-3 text-black'>Set your password</h4>
        </div>
        <Form onSubmit={this.handleSubmit}>
          <FormGroup>
            <InputField
              type='password'
              clasname='login-input'
              placeholder='Enter your password'
              name='password'
              validation='required'
              value={password}
              message='Please enter your password'
              ref={this.fields[0]}
              onChange={this.handleChange}
              label='Password'
            />
          </FormGroup>

          <FormGroup>
            <InputField
              type='password'
              clasname='login-input'
              placeholder='Enter your password'
              name='repassword'
              validation='required'
              value={repassword}
              message='Please enter your repassword'
              ref={this.fields[1]}
              onChange={this.handleChange}
              label='Confirm Password'
            />
          </FormGroup>
          <FormGroup>
            <FormText color='danger'>{error}</FormText>
          </FormGroup>
          <FormGroup className='text-right form-group'>
            <Button type='submit' color='primary' className='w-100 mt-3 btn-login'>
              Create Password
            </Button>
          </FormGroup>
        </Form>
      </div>
    );
  }
}

export default FormCreatePassword;
