import { CONNECTION } from "../../config/Connections";
import { CONFIG_ } from "../../config/Config";
import { notifDanger, notifSuccess } from "../../components";

var path = process.env.REACT_APP_PATH_AUTH;
var post = "reset-password";
var isLive = false;

export const __postDataResetPassword = (params) => {
  // console.log(params);
  const { action, email } = params;
  var dataFormData = new FormData();
  dataFormData.append("action", action);
  dataFormData.append("email", email);
  return (dispatch) => {
    let config = CONFIG_({
      url: post,
      data: dataFormData,
      method: "POST",
      path: path,
      live: isLive,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
    dispatch({ type: "LOADING_RESETPASSWORD_RESET" });
    CONNECTION(config)
      .then((response) => {
        return dispatch({
          type: "SUCCESS_RESETPASSWORD_RESET",
          message:
            "An email has been sent to you with instructions on how to reset your password.",
          code: 200,
          errors: response,
        });
      })
      .catch((response) => {
        return dispatch({
          type: "ERROR_RESETPASSWORD_RESET",
          message: "Network error..",
          errors: response,
        });
      });
  };
};

export const __postResetPassword = (params) => {
  const { key, confirm_password, password, action } = params;
  var dataFormData = new FormData();
  dataFormData.append("confirm_password", confirm_password);
  dataFormData.append("password", password);
  dataFormData.append("key", key);
  dataFormData.append("action", action);

  return (dispatch) => {
    let config = CONFIG_({
      url: post,
      data: dataFormData,
      method: "POST",
      path: path,
      live: isLive,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
    dispatch({ type: "LOADING_RESETPASSWORD_RESET" });
    CONNECTION(config)
      .then((response) => {
        const { code } = response;
        if (code === 200) {
          notifSuccess("Your password has been reset successfully");
          return dispatch({
            type: "SUCCESS_RESETPASSWORD_RESET",
            message: "Success post data",
            code: code,
          });
        }
        notifDanger("Failed to change password");

        return dispatch({
          type: "ERROR_RESETPASSWORD_RESET",
          message: "failed",
        });
      })
      .catch((response) => {
        return dispatch({
          type: "ERROR_RESETPASSWORD_RESET",
          message: "Network error..",
          erros: response,
        });
      });
  };
};
