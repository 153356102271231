const defaultState = {
	loadingPage: true,
	loadingSend: true,
	restData: {},
	code: 500,
	message:
		"An email has been sent to you with instructions on how to reset your passsword",
};

let response = {};
const ResetPassword_lib = (state = defaultState, action = {}) => {
  switch (action.type) {
    case "SUCCESS_RESETPASSWORD_RESET":
      response = {
        ...state,
        restData: action.data ? action.data : {},
        code: action.code ? action.code : 500,
        loadingPage: false,
        loadingSend: false,
        message: action.message ? action.message : "",
      };

      break;
    case "LOADING_RESETPASSWORD_RESET":
      response = {
        ...state,
        loadingPage: true,
        loadingSend: true,
      };
      break;
    case "ERROR_RESETPASSWORD_RESET":
      response = {
        ...state,
        loadingPage: false,
        loadingSend: false,
        message: action.message ? action.message : "",
        code: action.code ? action.code : 500,
      };
      break;
    default:
      return {
        ...state,
        loadingPage: false,
        loadingSend: false,
      };
  }

  return response;
};

export default ResetPassword_lib;
