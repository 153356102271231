import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Card,
  CardBody,
  Form,
  Row,
  FormGroup,
  Label,
} from 'reactstrap';
import { InputField } from '../../components';

import { __postDataResetPassword } from '../../modules/Reset';
import { Link, NavLink } from 'react-router-dom';
import { BsArrowLeft } from 'react-icons/bs';

const locationWindow = window.location.origin;

export class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.fields = [createRef()];
    this.state = {
      data: {
        action: 'sendkey',
        email: '',
      },
    };
  }
  handleSubmit = (e) => {
    e.preventDefault();
    var valid = true;
    var isValid = true;
    let { data } = this.state;

    //trim email
    data.email = data.email.trim();

    // const formdata = new URLSearchParams();
    // console.log(data, formdata);
    for (let index = 0; index < this.fields.length; index++) {
      valid = this.fields[index].current.validate();
      if (!valid) {
        isValid = false;
      }
    }
    if (isValid) {
      //   funtion to send data
      this.props.__postDataResetPassword(data);
    }
  };

  handleChange = (name, value) => {
    const { data } = this.state;
    this.setState((state) => ({
      ...state,
      data: {
        ...data,
        [name]: value,
      },
    }));
  };

  render() {
    const { data } = this.state;
    const { modulResetPassword } = this.props;
    const { loadingSend, code, message } = modulResetPassword;
    const { email } = data;
    return (
      <div className='wrapper-login'>
        <div className='login-container'>
          <Card className='py-2 px-2'>
            <CardBody>
              <a href='https://giftano.com/'>
                <img
                  src='https://giftano.imgix.net/assets/Giftano-Logo-with-text.png?auto=format'
                  className='auth-logo mx-auto d-block'
                  alt={'Giftano logo'}
                />
              </a>
              {code !== 200 ? (
                <div>
                  <div className=' d-flex justify-content-between align-items-center mt-4 mb-3'>
                    <Link to={'/'}>
                      <h6 className='font-weight-600 m-0 btn-back'>
                        <BsArrowLeft /> Back
                      </h6>
                    </Link>
                  </div>
                  <h4 className=' mb-4 mt-4 text-center'>
                    Reset Your Password
                  </h4>
                  <div className=' d-flex justify-content-between align-items-center mb-3 d-none'>
                    <h6 className='font-weight-600 m-0'>
                      Remember your account?
                    </h6>
                    <h6 className='m-0'>
                      <NavLink
                        to='/'
                        className='nav-link text-orange'>
                        Back to Sign in page
                      </NavLink>
                    </h6>
                  </div>

                  <Form onSubmit={this.handleSubmit}>
                    <FormGroup className='form-group mt-4'>
                      <Label className=''>
                        Please Enter Your Email Address
                      </Label>
                      <InputField
                        type='text'
                        className='login-input'
                        placeholder='Your email address'
                        ref={this.fields[0]}
                        name='email'
                        onChange={this.handleChange}
                        validation={'required|match:email'}
                        value={data.email}
                      />
                      <Label>
                        We will send you an email with the instruction to reset
                        your password
                      </Label>
                    </FormGroup>

                    <FormGroup className='text-right'>
                      <Button
                        type='submit'
                        color='primary'
                        className='w-100 my-3 btn-login '
                        disabled={loadingSend}>
                        {!loadingSend ? 'Reset Password' : 'Loading...'}
                      </Button>
                    </FormGroup>
                  </Form>
                </div>
              ) : (
                <div>
                  <h4 className=' mb-4 mt-4 text-center'>
                    Reset Your Password
                  </h4>
                  <Row>
                    <img
                      src='https://account.giftano.com/assets/images/reset-email.png'
                      className='forgot-logo mx-auto'
                      alt=''
                    />
                  </Row>
                  <p className=' mb-4 text-center text-giftano'>{message}</p>
                  <h6 className=' mb-4 text-center text-giftano font-weight-bold'>
                    {email}
                  </h6>

                  <a
                    className='btn btn-primary  w-100 btn-google m-0 btn-block btn-outline-primary text-secondary d-none'
                    href={locationWindow + '?page=login'}>
                    Login
                  </a>
                </div>
              )}
            </CardBody>
          </Card>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ modulResetPassword }) => ({
  modulResetPassword,
});

const mapDispatchToProps = (dispatch) => ({
  __postDataResetPassword: (params) => [
    dispatch(__postDataResetPassword(params)),
  ],
});

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
