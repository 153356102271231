import { CONNECTION } from '../../config/Connections';
import { CONFIG_ } from '../../config/Config';
import { notifDanger, notifSuccess, notifWarning } from '../../components';

// var get = "";
// var post = "";
// var isLive = false;
var path = process.env.REACT_APP_PATH_AUTH;

export const __postSignUpNewAccount = (params) => {
  return (dispatch) => {
    const { session_id } = params;
    let config = CONFIG_({
      url: 'setup-account',
      path: path,
      method: 'POST',
      headers: { session_id: session_id },
      data: params,
    });
    dispatch({ type: 'LOADING_SIGNUP' });
    CONNECTION(config)
      .then((response) => {
        const { code } = response;
        if (code === 200) {
          notifSuccess('Account has been Create');
          return dispatch({
            type: 'SUCCESS_SIGNUP',
            message: 'Success get data',
            code: 200,
            data: { session_id: session_id },
          });
        }

        notifWarning('Unable to update member account.');
        return dispatch({ type: 'SUCCESS_SIGNUP', message: 'failed' });
      })
      .catch((response) => {
        console.log(response);
        return dispatch({ type: 'ERROR_SIGNUP', message: 'Network error..' });
      });
  };
};

// validateSignUP link
export const __postDataValidateLink = (params) => {
  return (dispatch) => {
    let config = CONFIG_({
      url: 'login-without-password',
      path: path,
      data: params,
      method: 'POST',
    });
    dispatch({ type: 'LOADING_SIGNUP' });
    CONNECTION(config)
      .then((response) => {
        const { code, data, message } = response;
        if (code === 200) {
          return dispatch({
            type: 'SUCCESS_SIGNUPLINK',
            code: 200,
            message: message,
            data: data,
          });
        }
        return dispatch({
          type: 'SUCCESS_SIGNUPLINK',
          code: 500,
        });
      })
      .catch((response) => {
        console.log(response);
        return dispatch({ type: 'ERROR_SIGNUP', message: 'Network error..' });
      });
  };
};

// send email signup
export const __postSendEmailSignup = (params) => {
  return (dispatch) => {
    let config = CONFIG_({
      url: 'create-account',
      data: params,
      method: 'POST',
      path: path,
    });
    dispatch({ type: 'LOADING_SIGNUP' });
    CONNECTION(config)
      .then((response) => {
        const { code, data } = response;
        if (code === 200) {
          notifSuccess('Email has been sent successfully');
          return dispatch({
            type: 'SUCCESS_SIGNUPSENDEMAIL',
            message: 'Success post data',
            code: 200,
            data: data,
          });
        }

        notifDanger('Unable to send Welcome Email');
        return dispatch({
          type: 'SUCCESS_SIGNUPSENDEMAIL',
          message: 'failed',
          code: 401,
        });
      })
      .catch((response) => {
        console.log(response);
        return dispatch({ type: 'ERROR_SIGNUP', message: 'Network error..' });
      });
  };
};
