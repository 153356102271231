import { CONNECTION } from '../../config/Connections';
import { CONFIG_ } from '../../config/Config';

// var get = "";
var post = 'apps';
var isLive = true;
var path = process.env.REACT_APP_PATH_MANAGE_APP;

export const __detailDataApp = (params) => {
  return (dispatch) => {
    const { param } = params;
    let config = CONFIG_({
      url: post + '/' + param,
      method: 'GET',
      live: isLive,
      path: path
    });
    dispatch({ type: 'LOADING_APP' });
    CONNECTION(config)
      .then((response) => {
        const { code, data, message } = response;
        if (code === 200) {
          return dispatch({
            type: 'SUCCESS_APP',
            message: message,
            code: code,
            data: data
          });
        }
        return dispatch({ type: 'ERROR_APP', message: 'failed' });
      })
      .catch((response) => {
        //console.log(response);
        return dispatch({ type: 'ERROR_APP', message: 'Network error..', errors: response });
      });
  };
};

export const manualGettingApplication = (params) => {
  const { param } = params;
  let config = CONFIG_({
    url: post + '?q=slug=' + param,
    method: 'GET',
    live: isLive,
    path: path
  });
  return CONNECTION(config);
};

export const manualDispatchApps = (params) => {
  const { type, code, data, message } = params;
  return (dispatch) => {
    return dispatch({
      type: type,
      code: code,
      data: data ? data : [],
      message: message ? message : ''
    });
  };
};
