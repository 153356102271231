import React, { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import { Input, Label, FormText, FormGroup } from 'reactstrap';
import ReactSelect from '../select/MaterialAutocomplate';
import {
  __validUrl,
  __validInt,
  __validateString,
  __validateStringAndInt,
  __validateStringAndIntAndSpace,
  __validateStringAndCharackter_1,
  __linkValidate,
  __validateRolePassword,
  __validateJustNumber,
} from '../validate/Validate';
// import DatePicker from "react-datepicker";
import InputPhone from '../input-phone/InputPhone';
import { BsDatepicker } from '../datepicker';
import InputAdornment from './inputAddorment';

const InputField = forwardRef((props, ref) => {
  // console.log(ref.current);

  const refs = useRef();
  const [value, setValue] = React.useState(
    props.value && props.value.toString().trim() ? props.value : ''
  );
  const [error, setError] = React.useState('');

  const HandleChange = (event) => {
    // console.log(ref);
    setValue(event.target.value);
    const isPasted = event.nativeEvent.inputType
      ? event.nativeEvent.inputType.startsWith('insertFromPaste')
      : false;
    if (props.onChange) props.onChange(event.target.name, event.target.value, event, ref, isPasted);
  };

  const HandlePaste = (event, ref) => {
    let value = event.clipboardData.getData('Text');

    if (props.onPaste) props.onPaste(event.target.name, value, event, ref, true);
  };

  const HandleGeneral = (event, ref) => {
    setValue(event.target.value);
    if (props.onChange) props.onChange(event.target.name, event.target.value, event, ref);
  };

  // const HandleDate = (event) => {
  //   console.log(event.target.value);
  // };

  const handlePhoneChange = (data, event) => {
    // const { country } = data;
    // const { dial_code, name } = country;
    // console.log(data);
    setValue(event.target.value);

    if (props.onChange) props.onChange(event.target.name, data);
  };

  const HandleSelect = (values, name) => {
    if (values !== null) {
      setValue(name);
      if (props.onChange) props.onChange(name, values);
    }
  };

  useEffect(() => {
    const { defaultValue } = props;
    if (defaultValue !== value) {
      setValue(defaultValue);
    }
  }, [props, value]);

  const Validate = () => {
    let defaultValue = value ? value : props.value;
    // console.log(defaultValue);
    if (props.validation) {
      const rules = props.validation.split('|');

      for (let i = 0; i < rules.length; i++) {
        const current = rules[i];

        if (current === 'required') {
          if (!defaultValue) {
            setError(
              props.message
                ? props.message
                : `${props.label ? props.label : props.name} is required`
            );
            return false;
          }
        }

        const pair = current.split(':');

        if (pair[0] === 'min' || pair[0] === 'max') {
          switch (pair[0]) {
            case 'min':
              if (defaultValue.length < pair[1]) {
                setError(`This field must be at least ${pair[1]} charactesr long`);
                return false;
              }
              break;
            case 'max':
              if (defaultValue.length > pair[1]) {
                setError(`This field must be no longer than ${pair[1]} charactesr long`);
                return false;
              }
              break;
            default:
              break;
          }
        }

        if (pair[0] === 'match') {
          switch (pair[1]) {
            case 'email':
              // if (!__validEmail(defaultValue)) {
              if (!/^[A-Z0-9._%+-/']+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(defaultValue)) {
                setError(`${props.label ? props.label : props.name} is invalid`);
                return false;
              }

              break;
            case 'url':
              if (!__validUrl(defaultValue)) {
                setError(`${props.label ? props.label : props.name} is invalid`);
                return false;
              }
              break;

            case 'number':
              if (!__validateJustNumber(defaultValue)) {
                setError(`${props.label ? props.label : props.name} is just numbers`);
                return false;
              }
              break;

            case 'int':
              if (!__validInt(defaultValue)) {
                setError(`${props.label ? props.label : props.name} is invalid`);
                return false;
              }
              break;
            case 'float':
              if (!__validInt(defaultValue)) {
                setError(`${props.label ? props.label : props.name} is invalid`);
                return false;
              }
              break;
            case 'string':
              if (!__validateString(defaultValue)) {
                setError(`${props.label ? props.label : props.name} is invalid`);
                return false;
              }
              break;
            case 'string_2':
              if (!__validateStringAndInt(defaultValue)) {
                setError(`${props.label ? props.label : props.name} is invalid`);
                return false;
              }
              break;
            case 'string_3':
              if (!__validateStringAndIntAndSpace(defaultValue)) {
                setError(`${props.label ? props.label : props.name} is invalid`);
                return false;
              }
              break;
            case 'string_4':
              if (!__validateStringAndCharackter_1(defaultValue)) {
                setError(`${props.label ? props.label : props.name} is invalid`);
                return false;
              }
              break;
            case 'link':
              if (!__linkValidate(defaultValue)) {
                setError(`${props.label ? props.label : props.name} is invalid`);
                return false;
              }
              break;
            case 'password':
              if (!__validateRolePassword(defaultValue)) {
                setError(
                  `${
                    props.label ? props.label : props.name
                  } password must contain at least one uppercase, lowercase and number`
                );
                return false;
              }
              break;
            default:
              break;
          }
        }
      }
    }
    setError('');
    return true;
  };

  useImperativeHandle(ref, () => {
    return {
      validate: () => Validate(),
      input: refs.current ? refs.current.value : '',
    };
  });

  if (props.type === 'select') {
    return (
      <div className='d-flex'>
        {props.label && <Label>{props.label}</Label>}
        <Input
          placeholder={props.placeholder}
          name={props.name}
          onChange={(event) => HandleChange(event)}
          type={props.type}
          defaultValue={props.value ? props.value : ''}
          autoComplete={props.autoComplete}
          disabled={props.disabled ? 'disabled' : ''}
          readOnly={props.readOnly ? 'readOnly' : ''}
          value={props.value}
        >
          {props.children}
        </Input>
        {error && (
          <FormText color='danger' className='error'>
            {error}
          </FormText>
        )}
      </div>
    );
  } else if (props.type === 'checkbox' || props.type === 'radio') {
    return (
      <div className='d-block'>
        <div className='mr-4 p-0 d-block'>
          <Label check className='d-flex justify-content-start'>
            <Input
              placeholder={props.placeholder}
              name={props.name}
              onChange={(event) => HandleGeneral(event)}
              type={props.type}
              autoComplete={props.autoComplete}
              checked={props.checked === props.value}
              value={props.value}
            />
            <h6 className='pl-3 mr-4' style={{ marginLeft: '10px' }}>
              {props.label}
            </h6>
          </Label>
        </div>
        {error && (
          <FormText color='danger' className='error'>
            {props.message
              ? typeof props.message === 'function'
                ? props.message()
                : props.message
              : error}
          </FormText>
        )}
      </div>
    );
  } else if (props.type === 'reactselect') {
    return (
      <>
        <Label>{props.label}</Label>

        <ReactSelect
          name={props.name}
          label={props.label && props.name}
          options={props.options}
          onCallback={(params, name) => HandleSelect(params, name)}
          message={error}
          placeholder={props.placeholder}
          isLoading={props.isLoading}
          defaultValue={props.value}
          validation={props.validation}
          isDisabled={props.disabled}
          defaultValues={props.defaultValues}
        />
        {error && (
          <FormText color='danger' className='error'>
            {error}
          </FormText>
        )}
      </>
    );
  } else if (props.type === 'datepicker') {
    return (
      <>
        <Label>{props.label}</Label>
        <BsDatepicker
          onCallback={HandleGeneral}
          value={props.value}
          validation={props.validation}
          disabled={props.disabled}
          defaultValue={props.value}
          name={props.name}
        />
        {error && (
          <FormText color='danger' className='error'>
            {error}
          </FormText>
        )}
      </>
    );
  } else if (props.type === 'phone_number') {
    return (
      <>
        <FormGroup>
          <Label>{props.label}</Label>

          <InputPhone
            // value={phone.dial_code + phone.phone_number}
            onChange={(val, country, event) => {
              handlePhoneChange({ val: val, country: country }, event);
            }}
            validation={props.validation}
            name={props.name}
          />
          {error && (
            <FormText color='danger' className='error'>
              {error}
            </FormText>
          )}
        </FormGroup>
      </>
    );
  } else if (props.type === 'addorment') {
    return (
      <>
        {props.label && <Label>{props.label}</Label>}
        <InputAdornment
          validation={props.validation}
          name={props.name}
          textAdorment={props.textAdorment}
          value={props.value}
          onChange={(event) => HandleChange(event)}
          defaultValue={props.defaultValue}
        />
        {error && (
          <FormText color='danger' className='error'>
            {error}
          </FormText>
        )}
      </>
    );
  } else {
    return (
      <>
        {props.label && <Label>{props.label} </Label>}
        {props.useDefaultValue ? (
          <Input
            autoFocus={props.onFocus ? true : false}
            placeholder={props.placeholder}
            name={props.name}
            onChange={(event, ref) => HandleChange(event, ref)}
            type={props.type}
            value={props.defaultValue ? props.defaultValue : ''}
            autoComplete={props.autoComplete}
            disabled={props.disabled}
            readOnly={props.readOnly ? props.readOnly : ''}
            rows={props.rows}
            className={`${props.className} ${!props.errorMessage && error ? 'field-error' : ''}`}
            onPaste={(event, ref) => HandlePaste(event, ref)}
            ref={refs}
          />
        ) : (
          <Input
            autoFocus={props.onFocus}
            placeholder={props.placeholder}
            name={props.name}
            onChange={(event, ref) => HandleChange(event, ref)}
            type={props.type}
            autoComplete={props.autoComplete}
            disabled={props.disabled}
            defaultValue={props.value ? props.value : ''}
            readOnly={props.readOnly ? props.readOnly : ''}
            rows={props.rows}
            className={`${props.className} ${!props.errorMessage && error ? 'field-error' : ''}`}
            onBlur={(event, ref) => HandleChange(event, ref)}
            ref={refs}
          />
        )}
        {error && props.errorMessage && (
          <FormText color='danger' className='error'>
            {error}
          </FormText>
        )}
      </>
    );
  }
});

InputField.defaultProps = {
  placeholder: '',
  name: '',
  type: 'text',
  value: '',
  disable: '',
  autoComplete: 'off',
  validation: '',
  onFocus: false,
  isLoading: false,
  className: '',
  rows: '2',
  options: [],
  errorMessage: true,
  useDefaultValue: false,
};

InputField.displayName - 'InputField';

export default InputField;
