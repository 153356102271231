import React, { Component, createRef } from 'react';
import { Button, Form, FormGroup, Label } from 'reactstrap';
import { InputField } from '../../../components';
import { date_formatold } from '../../../config/Helper';
// import { LoginSso } from "../../../components/loginsso/LoginSso";

export default class FormCreateBirthday extends Component {
  constructor(props) {
    super(props);
    this.fields = [createRef()];
    this.state = {
      data: {
        user: '',
      },
      step: 1,
    };
  }

  handleSumit = (e) => {
    e.preventDefault();
    var valid = true;
    var isValid = true;
    let { data } = this.state;

    // trim data
    data.user = data.user.trim();

    for (let index = 0; index < this.fields.length; index++) {
      valid = this.fields[index].current.validate();
      if (!valid) {
        isValid = false;
      }
    }
    if (isValid) {
      //   funtion to send data
      const { user } = data;
      // check email
      return this.props.callback({ birthday: user }, 4);
    }
  };
  handleChange = (name, value) => {
    const { data } = this.state;
    this.setState((state) => ({
      ...state,
      data: {
        ...data,
        [name]: value,
      },
    }));
  };
  render() {
    const { data } = this.state;
    const { user } = data;
    return (
      <div>
        <div className=' d-flex justify-content-center'>
          <h4 className='my-3 text-black'>Your birthday</h4>
        </div>
        <div className=' d-flex justify-content-center'>
          <Label className='my-3 form-subtitle'>What is your date of birth?</Label>
        </div>
        <Form onSubmit={this.handleSumit}>
          <FormGroup>
            <InputField
              type='datepicker'
              clasname='login-input'
              name='user'
              value={date_formatold(user)}
              message='Please enter your birthday'
              ref={this.fields[0]}
              onChange={this.handleChange}
              label='Your birthday ( mm/dd/yyyy )'
            />
          </FormGroup>
          <FormGroup className='text-right form-group'>
            <Button type='submit' color='primary' className='w-100 mt-3 btn-login'>
              Next
            </Button>
          </FormGroup>
        </Form>
      </div>
    );
  }
}
