import { CONNECTION } from '../../config/Connections';
import { CONFIG_ } from '../../config/Config';
// import { __getDataPermission } from "./Permission_act";
import { notifDanger } from '../../components';

// var get = "";
var post = 'login-sso';
var isLive = true;
var path = process.env.REACT_APP_PATH_AUTH;

export const __postDataSso = (params) => {
  const { authenticate, user } = params;
  let profile = `${JSON.stringify(user)}`;
  return (dispatch) => {
    let config = CONFIG_({
      url: post,
      data: authenticate,
      method: 'POST',
      live: isLive,
      path: path,
    });
    dispatch({ type: 'LOADING_SSO_AUTH' });
    CONNECTION(config)
      .then((response) => {
        const { code, data, message } = response;
        if (code === 200) {
          const { session_id } = data;
          localStorage.setItem('session_id', session_id);
          // console.log('set session');
          return dispatch({
            type: 'SUCCESS_SSO_AUTH',
            message: 'Success post data',
            data: {
              profile: 'profile=' + btoa(profile),
              ...data,
            },
            code: code,
          });
        }

        notifDanger('Your account is cannot be found');
        dispatch({ type: 'CONFIRM_LOGIN', open: false });

        return dispatch({ type: 'ERROR_SSO_AUTH', message: message, code });
      })
      .catch((response) => {
        console.log(response);
        return dispatch({ type: 'ERROR_SSO_AUTH', message: 'Network error..' });
      });
  };
};
