// import React from 'react';
// import { ToastConsumer } from "react-toast-notifications";
import { toast } from 'react-toastify';
import { CatchError } from '../CatchError';
// import { GetAllContacts } from '../Contact/ContactCrud';
import {
  CreateProfile,
  CreateProfileAddress,
  CreateProfileEmail,
  CreateProfilePhone,
  DeleteProfileAddress,
  DeleteProfileEmail,
  DeleteProfilePhone,
  GetProfileAddressById,
  GetProfileEmailById,
  GetProfilePhoneById,
  LoadProfile,
  UpdateProfile,
  UpdateProfileAddress,
  UpdateProfileEmail,
  UpdateProfilePhone,
} from './ProfileCRUD';

const initialState = {
  data: {},
  is_loading: false,
  is_loaded: false,
  is_send: false,
};

const ActionTypes = {
  SET_DATA: 'PROFILE_SET_DATA',
  START_LOADING: 'PROFILE_START_LOADING',
  STOP_LOADING: 'PROFILE_STOP_LOADING',
  START_SEND_DATA: 'PROFILE_START_SEND_DATA',
  STOP_SEND_DATA: 'PROFILE_STOP_SEND_DATA',
};

export const ProfileReducer = (state = initialState, action) => {
  let newState = { ...state };
  switch (action.type) {
    case ActionTypes.SET_DATA:
      newState.data = action.data ? action.data : {};
      newState.is_loaded = true;
      break;
    case ActionTypes.START_LOADING:
      newState.is_loading = true;
      break;
    case ActionTypes.STOP_LOADING:
      newState.is_loading = false;
      break;
    case ActionTypes.START_SEND_DATA:
      newState.is_send = true;
      break;
    case ActionTypes.STOP_SEND_DATA:
      newState.is_send = false;
      break;
    default:
      //   newState.is_loading = false;
      break;
  }
  return newState;
};

export const ProfileActions = {
  loadProfile: () => async (dispatch) => {
    let ret;
    dispatch({ type: ActionTypes.START_LOADING });
    try {
      let res = await LoadProfile();
      // console.log("result profile", res);

      if (res.code === 200) {
        let data = { ...res.data };
        /* end get contact */
        dispatch({ type: ActionTypes.SET_DATA, data: data });
        ret = data;
      } else {
        if (res.code === 404) {
          //profile not found
          toast.warning('Profile not found');
          //redirect memory router to /register
          ret = { error: 'profile_not_found' };
        }
        // console.log('not 200', res.code);
      }
    } catch (err) {
      CatchError(err);
    }
    dispatch({ type: ActionTypes.STOP_LOADING });
    return ret;
  },
  createProfile: (data) => async (dispatch) => {
    let ret;
    dispatch({ type: ActionTypes.START_SEND_DATA });
    try {
      let res = await CreateProfile(data);
      if (res.code === 201) {
        dispatch(ProfileActions.loadProfile());
        toast.success('Create Profile Success');
        ret = res;
      }
    } catch (err) {
      CatchError(err);
    }
    dispatch({ type: ActionTypes.STOP_SEND_DATA });
    return ret;
  },
  updateProfile: (data) => async (dispatch) => {
    dispatch({ type: ActionTypes.START_LOADING });
    try {
      let res = await UpdateProfile(data);
      // console.log('result update from actions', res);
      if (res.code === 200) {
        dispatch(ProfileActions.loadProfile());
        toast.success('Update Profile Success');
      }
    } catch (err) {
      CatchError(err);
    }
    dispatch({ type: ActionTypes.STOP_LOADING });
  },
  createProfileAddress: (data) => async (dispatch) => {
    let ret;
    dispatch({ type: ActionTypes.START_SEND_DATA });
    try {
      let res = await CreateProfileAddress(data);
      // console.log('result create address', res);
      if (res.code === 201) {
        dispatch(ProfileActions.loadProfile());
        toast.success('Create Address Success');
        ret = res;
      }
    } catch (err) {
      CatchError(err);
    }
    dispatch({ type: ActionTypes.STOP_SEND_DATA });
    return ret;
  },
  getProfileAddressById: (id) => async (dispatch) => {
    let ret;
    dispatch({ type: ActionTypes.START_LOADING });
    try {
      let res = await GetProfileAddressById(id);
      if (res.code === 200) {
        dispatch({ type: ActionTypes.STOP_LOADING });
        ret = res.data;
      }
    } catch (err) {
      CatchError(err);
    }
    dispatch({ type: ActionTypes.STOP_LOADING });
    return ret;
  },
  updateProfileAddress: (data) => async (dispatch) => {
    let ret;
    dispatch({ type: ActionTypes.START_SEND_DATA });
    try {
      let res = await UpdateProfileAddress(data);
      if (res.code === 200) {
        dispatch(ProfileActions.loadProfile());
        toast.success('Update Address Success');
        ret = res;
      }
    } catch (err) {
      CatchError(err);
    }
    dispatch({ type: ActionTypes.STOP_SEND_DATA });
    return ret;
  },
  deleteProfileAddress: (data) => async (dispatch) => {
    let ret;
    dispatch({ type: ActionTypes.START_SEND_DATA });
    try {
      let res = await DeleteProfileAddress(data);
      if (res.code === 200) {
        dispatch(ProfileActions.loadProfile());
        toast.success('Delete Address Success');
        ret = res;
      }
    } catch (err) {
      CatchError(err);
    }
    dispatch({ type: ActionTypes.STOP_SEND_DATA });
    return ret;
  },
  createProfileEmail: (data) => async (dispatch) => {
    let ret;
    dispatch({ type: ActionTypes.START_SEND_DATA });
    try {
      let res = await CreateProfileEmail(data);
      if (res.code === 201) {
        dispatch(ProfileActions.loadProfile());
        toast.success('Create Email Success');
        ret = res;
      }
    } catch (err) {
      CatchError(err);
    }
    dispatch({ type: ActionTypes.STOP_SEND_DATA });
    return ret;
  },
  getProfileEmailById: (id) => async (dispatch) => {
    let ret;
    dispatch({ type: ActionTypes.START_LOADING });
    try {
      let res = await GetProfileEmailById(id);
      if (res.code === 200) {
        ret = res.data;
      }
    } catch (err) {
      CatchError(err);
    }
    dispatch({ type: ActionTypes.STOP_LOADING });
    return ret;
  },
  updateProfileEmail: (data) => async (dispatch) => {
    let ret;
    dispatch({ type: ActionTypes.START_SEND_DATA });
    try {
      let res = await UpdateProfileEmail(data);
      if (res.code === 200) {
        dispatch(ProfileActions.loadProfile());
        toast.success('Update Email Success');
        ret = res;
      }
    } catch (err) {
      CatchError(err);
    }
    dispatch({ type: ActionTypes.STOP_SEND_DATA });
    return ret;
  },
  deleteProfileEmail: (data) => async (dispatch) => {
    let ret;
    dispatch({ type: ActionTypes.START_SEND_DATA });
    try {
      let res = await DeleteProfileEmail(data);
      if (res.code === 200) {
        dispatch(ProfileActions.loadProfile());
        toast.success('Delete Email Success');
        ret = res;
      }
    } catch (err) {
      CatchError(err);
    }
    dispatch({ type: ActionTypes.STOP_SEND_DATA });
    return ret;
  },
  createProfilePhone: (data) => async (dispatch) => {
    let ret;
    dispatch({ type: ActionTypes.START_SEND_DATA });
    try {
      let res = await CreateProfilePhone(data);
      if (res.code === 201) {
        dispatch(ProfileActions.loadProfile());
        toast.success('Create Phone Number Success');
        ret = res;
      }
    } catch (err) {
      CatchError(err);
    }
    dispatch({ type: ActionTypes.STOP_SEND_DATA });
    return ret;
  },
  getProfilePhoneById: (id) => async (dispatch) => {
    let ret;
    dispatch({ type: ActionTypes.START_LOADING });
    try {
      let res = await GetProfilePhoneById(id);
      if (res.code === 200) {
        ret = res.data;
      }
    } catch (err) {
      CatchError(err);
    }
    dispatch({ type: ActionTypes.STOP_LOADING });
    return ret;
  },
  updateProfilePhone: (data) => async (dispatch) => {
    let ret;
    dispatch({ type: ActionTypes.START_SEND_DATA });
    try {
      let res = await UpdateProfilePhone(data);
      if (res.code === 200) {
        dispatch(ProfileActions.loadProfile());
        toast.success('Update Phone Number Success');
        ret = res;
      }
    } catch (err) {
      CatchError(err);
    }
    dispatch({ type: ActionTypes.STOP_SEND_DATA });
    return ret;
  },
  deleteProfilePhone: (data) => async (dispatch) => {
    let ret;
    dispatch({ type: ActionTypes.START_SEND_DATA });
    try {
      let res = await DeleteProfilePhone(data);
      if (res.code === 200) {
        dispatch(ProfileActions.loadProfile());
        toast.success('Delete Phone Number Success');
        ret = res;
      }
    } catch (err) {
      CatchError(err);
    }
    dispatch({ type: ActionTypes.STOP_SEND_DATA });
    return ret;
  },
};
