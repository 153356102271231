import React, { Component } from 'react'
import GoogleLogin from 'react-google-login'
import { Button } from 'reactstrap'
import googleicon from '../../../assets/flat-color-icons_google.png'
const GOOGLE_CLIENTID = process.env.REACT_APP_GOOGLE_CLIENT

class Google extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  responseGoole = (params) => {
    const { callback } = this.props
    if (typeof callback === 'function') {
      return this.props.callback(params)
    }
  }

  render() {
    const { disabled } = this.props
    return (
      <GoogleLogin
        clientId={GOOGLE_CLIENTID}
        render={(renderProps) => (
          <Button
            type="button"
            id="google"
            className="btn-google m-0 btn-block btn-outline-dark w-100"
            onClick={renderProps.onClick}
            disabled={renderProps.disabled}
          >
            <img src={googleicon} alt="Google icon" />
            {this.props.label ? this.props.label : 'Sign in with Google'}
          </Button>
        )}
        disabled={disabled}
        buttonText="Login"
        onSuccess={this.responseGoole}
        onFailure={this.responseGoole}
        cookiePolicy={'single_host_origin'}
      ></GoogleLogin>
    )
  }
}

Google.defaultProps = {
  disabled: false,
}

export default Google
