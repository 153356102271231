import React, { Component, createRef } from 'react';
import { Button, Form, FormGroup, FormText } from 'reactstrap';
import { InputField } from '../../../components';

class FormCreateFullname extends Component {
  constructor(props) {
    super(props);
    this.fields = [createRef(), createRef(), createRef()];
    this.state = {
      data: {
        first_name: '',
        middle_name: '',
        last_name: '',
      },

      error: '',
    };
  }

  componentDidMount() {
    const { dataUser } = this.props;
    this.setState((state) => ({
      ...state,
      data: {
        first_name: dataUser.first_name,
        middle_name: dataUser.middle_name,
        last_name: dataUser.last_name,
      },
    }));
  }

  handleSubmit = (e) => {
    e.preventDefault();
    var valid = true;
    var isValid = true;
    let { data } = this.state;
    // trim data

    for (let index = 0; index < this.fields.length; index++) {
      valid = this.fields[index].current.validate();
      if (!valid) {
        isValid = false;
      }
    }
    if (isValid) {
      //   funtion to send data
      this.props.callback({ ...data }, 3);
    }
  };

  handleChange = (name, value) => {
    const { data } = this.state;
    this.setState((state) => ({
      ...state,
      data: {
        ...data,
        [name]: value,
      },
    }));
  };
  render() {
    const { data, error } = this.state;
    const { last_name, first_name, middle_name } = data;
    const { dataUser } = this.props;
    return (
      <div>
        <div className=' d-flex justify-content-center'>
          <h4 className='my-3 text-black'>Your Name</h4>
        </div>
        <Form onSubmit={this.handleSubmit}>
          <FormGroup>
            <InputField
              type='text'
              clasname='login-input'
              placeholder='Enter your first name'
              name='first_name'
              validation='required'
              value={first_name || dataUser.first_name}
              message='Please enter your first name'
              ref={this.fields[0]}
              onChange={this.handleChange}
              label='First Name'
            />
          </FormGroup>

          <FormGroup>
            <InputField
              type='text'
              clasname='login-input'
              placeholder='Enter your middle name'
              name='middle_name'
              value={middle_name || dataUser.middle_name}
              message='Please enter your middle name'
              ref={this.fields[1]}
              onChange={this.handleChange}
              label='Middle Name'
            />
          </FormGroup>
          <FormGroup>
            <InputField
              type='text'
              clasname='login-input'
              placeholder='Enter your last name'
              name='last_name'
              value={last_name || dataUser.last_name}
              message='Please enter your last name'
              ref={this.fields[2]}
              onChange={this.handleChange}
              label='Last Name'
            />
          </FormGroup>
          <FormGroup>
            <FormText color='danger'>{error}</FormText>
          </FormGroup>
          <FormGroup className='text-right form-group'>
            <Button
              type='submit'
              color='primary'
              className='w-100 mt-3 btn-login'>
              Next
            </Button>
          </FormGroup>
        </Form>
      </div>
    );
  }
}

export default FormCreateFullname;
