import { CONNECTION } from '../../config/Connections';
import { CONFIG_ } from '../../config/Config';
import { __getDataPermission } from './Permission_act';
import { notifDanger } from '../../components';
// import axios from "axios";

// var get = "";
var post = 'login';
var isLive = true;
var path = process.env.REACT_APP_PATH_AUTH;
// var session_set = "https://account.dev/set/";
export const __postDataAuth = (params) => {
  const { appname } = params;
  return (dispatch) => {
    let config = CONFIG_({
      url: post,
      data: params,
      method: 'POST',
      path: path,
      live: isLive,
    });
    dispatch({ type: 'LOADING_AUTH' });
    CONNECTION(config)
      .then((response) => {
        const { code, message, data } = response;
        const { session_id } = data;
        if (code === 200) {
          dispatch({
            type: 'SUCCESS_AUTH',
            message: 'Success post data',
            code: 200,
            data: { session_id: session_id },
          });
          return dispatch(
            __getDataPermission({ session_id: session_id, appname: appname }, function (response) {
              return dispatch({
                type: 'SUCCESS_PERMISSION_AUTH',
                message: 'Success get data',
                data: { ...response },
                code: 200,
              });
            })
          );
        }
        notifDanger('Upps.. something wrong, Please try again');
        dispatch({
          type: 'ERROR_RECAPTCHA_VERIFY',
          message: 'failed',
          code: 400,
        });
        return dispatch({ type: 'ERROR_AUTH', message: message, code: 400 });
      })
      .catch((response) => {
        const { message } = response;
        notifDanger('Your email or password is incorrect, please try again.');
        dispatch({
          type: 'ERROR_RECAPTCHA_VERIFY',
          message: 'failed',
          code: 400,
        });
        return dispatch({
          type: 'ERROR_AUTH',
          message: message,
          code: 400,
        });
      });
  };
};

export const manualPermission = (params) => {
  const { session_id } = params;
  let config = CONFIG_({
    url: 'get',
    method: 'GET',
    live: isLive,
    path: path,
    headers: { session_id: session_id },
  });

  return CONNECTION(config);
};

export const __dummyAuth = () => {
  return (dispatch) => {
    dispatch({ type: 'LOADING_AUTH' });

    setTimeout(() => {
      return dispatch({
        type: 'SUCCESS_AUTH',
        message: 'Success post data',
        data: {
          session_id: '1309128312',
          member: {
            user: '10293',
            fullname: 'Asto Nugroho',
          },
        },
        code: 200,
      });
    }, 3000);
  };
};
