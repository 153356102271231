import { CONNECTION } from '../../config/Connections';
import { CONFIG_ } from '../../config/Config';
import { _setAdmin } from './Auth_lib';

var get = 'user-info';
// var post = "";
var path = process.env.REACT_APP_PATH_AUTH;

export const __getDataInfo = (sessionID) => {
  return (dispatch) => {
    let config = CONFIG_({
      url: get,
      headers: { session_id: sessionID },
      method: 'GET',
      path: path,
    });
    dispatch({ type: 'LOADING_INFO_AUTH' });
    CONNECTION(config)
      .then((response) => {
        const { code, data } = response;
        if (code === 200) {
          // replace session id localstorage
          _setAdmin('session_id', sessionID);
          return dispatch({
            type: 'SUCCESS_INFO_AUTH',
            message: 'Success get data',
            data: data,
            code: 200,
          });
        }
        // clear localstorage
        return dispatch({ type: 'ERROR_INFO_AUTH', message: 'failed' });
      })
      .catch((response) => {
        // console.log(response);
        return dispatch({
          type: 'ERROR_INFO_AUTH',
          message: 'Network error..',
          errors: response,
        });
      });
  };
};
