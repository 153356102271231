export const getHostUrlNameUrl = (websiteURL) => {
  var getTheHostName;
  if (websiteURL.indexOf('//') > -1) {
    getTheHostName = websiteURL.split('/')[2];
  } else {
    getTheHostName = websiteURL.split('/')[0];
  }
  //   getTheHostName = getTheHostName.split(":")[0];
  getTheHostName = getTheHostName.split('?')[0];
  return getTheHostName;
};

export const getProtocol = (websiteUrl) => {
  if (websiteUrl.indexOf('//') > -1) {
    return websiteUrl;
  }
  return 'https://' + websiteUrl;
};
export const __validUrl = (params) => {
  var re =
    /(http|https|com.giftano.redemptionapp|com.giftano.redemptionappsandbox):\/\/(\w+:{0,1}\w*)?(\S+)(:[0-9]+)?(|([\w#!:.?+=&%!]))?/;
  if (!re.test(params)) {
    return false;
  }

  return true;
};

export const getUrl = (url, index = 0) => {
  if (url !== '' && typeof url !== 'boolean') {
    const slug = url.split('|');
    // console.log(slug.length);

    if (slug.length === 2) {
      __validUrl(slug[0]);
      if (index === 0) {
        return __validUrl(slug[0]) ? slug[0] : false;
      } else {
        return slug[index] ? slug[index] : '';
      }
    }
    return '';
  }
  return '';
};

export const hideemail = (email) => {
  //  console.log(email.length);
  var result = '';
  for (var i = 0; i < email.length; i++) {
    //  console.log(i);
    if (i >= 2 && i < email.indexOf('@')) {
      result += '*';
    } else {
      result += email[i];
    }
  }

  return result;
};

export const date_formatold = (value) => {
  // yyyy-mm-dd
  var date = value.split('-');

  return date[2] + '/' + date[1] + '/' + date[0];
};

export const compareAppLegacy = (data) => {
  // console.log(data);
  let app = [];
  var apps = [];

  data.map((item) => {
    apps = app.filter((ap) => ap.slug === item.slug);
    if (apps.length === 0) {
      app.push(item);
    }
    // console.log(app);
  });

  return app;
};

export const isBase64 = (params) => {
  console.log(params);
  var re = /^([A-Za-z0-9+/]{4})*([A-Za-z0-9+/]{3}=|[A-Za-z0-9+/]{2}==)?$/;
  if (!re.test(params)) {
    return false;
  }

  return true;
};
