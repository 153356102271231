const defaultState = {
  loadingSendEmail: true,
  loadingVerify: true,
  restData: {},
  code: 500,
  message: "",
  codeSendEmail: 500,
};

let response = {};
const Loginwithoutpassword_lib = (state = defaultState, action = {}) => {
  switch (action.type) {
    case "SUCCESS_LOGINWITHOUTPASSWORD_AUTH":
      response = {
        ...state,
        restData: action.data ? action.data : {},
        code: action.code ? action.code : 500,
        loadingSendEmail: false,
        loadingVerify: false,
        message: action.message ? action.message : "",
      };

      break;

    case "SUCCESS_LOGINWITHOUTPASSWORDSENDEMAIL_AUTH":
      response = {
        ...state,
        restData: action.data ? action.data : {},
        codeSendEmail: action.code ? action.code : 500,
        loadingSendEmail: false,
        loadingVerify: false,
        message: action.message ? action.message : "",
      };

      break;
    case "LOADING_LOGINWITHOUTPASSWORD_AUTH":
      response = {
        ...state,
        loadingSendEmail: true,
        loadingVerify: true,
      };
      break;
    case "ERROR_LOGINWITHOUTPASSWORD_AUTH":
      response = {
        ...state,

        message: action.message ? action.message : "",
        code: action.code ? action.code : 500,
      };
      break;
    default:
      return {
        ...state,
        loadingSendEmail: false,
        loadingVerify: false,
        codeSendEmail: 500,
      };
  }

  return response;
};

export default Loginwithoutpassword_lib;
