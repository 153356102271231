import React, { Component } from 'react'

import { connect } from 'react-redux'
import { Modal, ModalHeader, ModalBody, Button } from 'reactstrap'
import { __postDataSso } from '../../modules/Auth'
import { __openModal } from '../../modules/Modals'
import { NavLink } from 'reactstrap'

export class Confirmation extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  clickHandle = () => {}

  render() {
    const { modal, redirectBack } = this.props
    const { CONFIRM_LOGIN_DINAMYC } = modal
    return (
      <Modal centered isOpen={CONFIRM_LOGIN_DINAMYC}>
        <ModalHeader
          toggle={() =>
            this.props.__openModal({
              modal: 'CONFIRM_LOGIN_DINAMYC',
              open: false,
            })
          }
          className="modal__no-header"
        ></ModalHeader>
        <ModalBody className="mb-2">
          <img
            src="http://giftano.imgix.net/wassets20/bemembericon.png?auto=format"
            className="m-auto d-block mb-3 w-15"
            alt='logo'
          />
          <h4 className="text-center mb-4">Become A Member!</h4>
          <h6 className="font-weight-400 text-center fs-16 text-black">
            By becoming a member you can enjoy the following benefits:
          </h6>
          <ul className="my-4">
            <li>Track your gift</li>
            <li>Download of e-receipt</li>
            <li>Priority customer support</li>
            <li>Faster delivery</li>
            <li>Access to history of purchases</li>
            <li>Receive special invitations to gift guides</li>
            <li>Easier buying process</li>
          </ul>
          <div className="cta my-4 d-flex justify-content-around">
            {redirectBack !== '' ? (
              <NavLink
                className="btn-cta-guest text-gf-neutral"
                href={redirectBack}
              >
                Continue as Guest
              </NavLink>
            ) : (
              ''
            )}
            <Button
              color="primary"
              size="sm"
              className="w-45"
              onClick={() =>
                this.props.__openModal({
                  modal: 'CONFIRM_LOGIN_DINAMYC',
                  open: false,
                })
              }
            >
              Join us
            </Button>
          </div>
        </ModalBody>
      </Modal>
    )
  }
}

const mapStateToProps = ({ modal }) => ({
  modal: modal,
})

const mapDispatchToProps = (dispatch) => ({
  __openModal: (params) => [dispatch(__openModal(params))],
  __postDataSso: (params) => [dispatch(__postDataSso(params))],
})

export default connect(mapStateToProps, mapDispatchToProps)(Confirmation)
