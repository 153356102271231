import axios from 'axios';
import { toast } from 'react-toastify';

export const CatchError = (err) => {
  if (err.response) {
    switch (err.response.status) {
      case 401: //unauthorized
        toast.warning(
          'You are not signed in or your session has expired, please sign in.',
          {
            onClose: () => {
              // window.location = (window.dataAuthUrl || 'https://account.giftano.com') + '?redirect=' +
              // btoa(window.location.href);
              // sign out then reload
              axios('http://localhost/test-membership/logout.php').then(
                (res) => {
                  if (res.status === 200) {
                    localStorage.removeItem('GiftMembershipSession');
                    window.location.reload();
                  }
                }
              );
            },
          }
        );
        break;
      default:
        toast.warning(err);
    }
  } else {
    // console.log(err)
    toast.error('Oops something went wrong please try again later', {
      autoClose: false,
    });
  }
  let ToastEl = document.querySelector('div.Toastify');
  if (ToastEl) {
    ToastEl.remove();
  }
};
