import React from 'react';
import * as Sentry from '@sentry/react';
// import { BrowserTracing } from '@sentry/tracing';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './vendor/css/style.css';
import App from './app/index';
import { rootReducer } from './app/modules';
import reportWebVitals from './reportWebVitals';
import thunk from 'redux-thunk';
// import pkg from '../package.json';
import { createStore, applyMiddleware } from 'redux';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
// import MatomoProvider from './app/layouts/MatomoProvider';
// import { STRAPI } from './app/config/Connections';
const stored = createStore(rootReducer, applyMiddleware(thunk));
const RECHAPTCHA_CLIENT = process.env.REACT_APP_RECHAPTCHA;

const APPMODE = process.env.REACT_APP_BUILD_MODE;
// const STRAPI_MODE = process.env.REACT_APP_ENABLE_SENTRY_REPLAYS;
// import { MatomoProvider, createInstance } from '@datapunt/matomo-tracker-react';

// const instance = createInstance({
//   urlBase: 'https://ga.giftano.com/',
//   siteId: 3,
//   userId: 'UID76903202', // optional, default value: `undefined`.
//   trackerUrl: 'https://ga.giftano.com/matomo.php', // optional, default value: `${urlBase}matomo.php`
//   srcUrl: 'https://ga.giftano.com/js/container_wolNcdy8.js', // optional, default value: `${urlBase}matomo.js`
//   disabled: false, // optional, false by default. Makes all tracking calls no-ops if set to true.
//   heartBeat: {
//     // optional, enabled by default
//     active: true, // optional, default value: true
//     seconds: 10, // optional, default value: `15
//   },
//   linkTracking: false, // optional, default value: true
//   configurations: {
//     // optional, default value: {}
//     // any valid matomo configuration, all below are optional
//     disableCookies: true,
//     setSecureCookie: true,
//     setRequestMethod: 'POST',
//   },
// });
// const getContent = async () => {
//   const res = await STRAPI({ method: 'GET', path: 'account' });
//   return res;
// };

(async () => {
  let sentry_enable = true;
  let enable_sentry_replays = false;
  let respStrapi = {};
  try {
    respStrapi = await fetch(process.env.REACT_APP_STRAPIENDPOINT + 'account')
      .then((result) => {
        if (result.status === 200) {
          return result.json();
        }
        return {};
      })
      .catch((error) => {
        console.log(error);
        throw new Error(error);
      });

    const response = respStrapi;
    if (Object.keys(response).length) {
      sentry_enable =
        response.data.attributes.enable_sentry !== undefined
          ? response.data.attributes.enable_sentry
          : false;
      enable_sentry_replays =
        response.data.attributes.enable_sentry_replays !== undefined
          ? response.data.attributes.enable_sentry_replays
          : false;
    }
    // console.log(response);
  } catch (error) {
    throw new Error(error);

    // console.log(error);
  }

  if (sentry_enable) {
    if (APPMODE !== 'local') {
      let integrations = [new Sentry.BrowserTracing()];
      if (enable_sentry_replays) {
        integrations.push(new Sentry.Replay({ maskAllText: true, blockAllMedia: true }));
      }
      try {
        Sentry.init({
          dsn: 'https://4e500e678092440d8b6f1a87abdeac5a@o1089454.ingest.sentry.io/6111030',
          environment: process.env.REACT_APP_BUILD_MODE.toLowerCase(),
          initialScope: {
            tags: {
              team: 'front-end',
              customer: true,
            },
          },
          denyUrls: [/https:\/\/static.cloudflareinsights.com\/beacon.min.js/i],
          replaysSessionSampleRate: APPMODE === 'PRODUCTION' ? 0.1 : 1, // tried both 0.1 and 1.0
          replaysOnErrorSampleRate: 1.0,
          // Set tracesSampleRate to 1.0 to capture 100%
          // of transactions for performance monitoring.
          // We recommend adjusting this value in production
          tracesSampleRate: 1,
          // integrations: [new Sentry.Replay({ maskAllText: true, blockAllMedia: true })],
          ignoreErrors: ['Non-Error exception captured', 'Non-Error promise rejection captured'],
          beforeSend(event) {
            console.log(event);
            // Filter out non-error promise rejections
            if (event.exception && event.exception.values && event.exception.values[0]) {
              const error = event.exception.values[0];
              if (error.type === 'Non-Error promise rejection captured with value') {
                return null; // Ignore this event
              }
            }
            return event;
          },
          integrations: integrations,
        });
      } catch (error) {
        console.log(error);
      }
    }
  }

  // eslint-disable-next-line react/no-deprecated
  ReactDOM.render(
    <React.StrictMode>
      <GoogleReCaptchaProvider reCaptchaKey={RECHAPTCHA_CLIENT}>
        <App store={stored} />
      </GoogleReCaptchaProvider>
    </React.StrictMode>,
    document.getElementById('root')
  );
})();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
