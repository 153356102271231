import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Card, CardBody, Form, FormGroup, Row, Button, Label } from 'reactstrap';
import { InputField, SSO_GOOGLE } from '../../components';
import { __dummyAuth, __postDataAuth } from '../../modules/Auth/Auth_act';
import { __getDataSession, __postDataSso } from '../../modules/Auth';
import { ReCaptcha, loadReCaptcha } from 'react-recaptcha-v3';
import { __postDataRecaptcha } from '../../modules/Verify';
import PackageInfo from '../../../../package.json';
import Facebook from '../../components/sso/Facebook';
// import AppleLogin from 'react-apple-login'
import './style.scss';
import ModalConfirm from './ModalConfirm';
import jwtDecode from 'jwt-decode';
import { __openModal } from '../../modules/Modals';
import ButtonDinamyc from '../../components/dinamyc/ButtonDinamyc';
// import { FaApple } from 'react-icons/fa'

const RECHAPTCHA_CLIENT = process.env.REACT_APP_RECHAPTCHA;

export class Login extends Component {
  constructor(props) {
    super(props);
    this.fields = [createRef(), createRef()];
    this.state = {
      data: {
        user: '',
        password: '',
        appname: '',
        recaptcha: '',
      },
      dataSso: {
        code: null,
        token: null,
      },
      isAuthenticate: false,
      title: 'Welcome back',
      textBtnJoin: 'Sign up Now',
    };
  }

  handleSubmit = (e) => {
    e.preventDefault();
    var valid = true;
    var isValid = true;
    let { data } = this.state;

    // trim data
    data.user = data.user.trim();
    data.password = data.password.trim();

    for (let index = 0; index < this.fields.length; index++) {
      valid = this.fields[index].current.validate();
      if (!valid) {
        isValid = false;
      }
    }
    if (isValid) {
      //   funtion to send data
      return this.props.__postDataRecaptcha(data);
    }
  };

  componentDidMount() {
    loadReCaptcha(RECHAPTCHA_CLIENT);
  }

  componentDidUpdate(prevProps) {
    const { modulAuth, modulRecaptcha } = this.props;
    const { code } = modulAuth;
    if (prevProps.modulAuth !== modulAuth && code === 400) {
      this.recaptcha.execute();
    }

    if (modulRecaptcha !== prevProps.modulRecaptcha && modulRecaptcha.code === 400) {
      this.recaptcha.execute();
    }
  }

  handleChange = (name, value) => {
    const { data } = this.state;
    this.setState((state) => ({
      ...state,
      data: {
        ...data,
        [name]: value,
      },
    }));
  };

  responseGoole = (response) => {
    const { tokenId, profileObj } = response;
    // console.log(response);
    const { data } = this.state;
    const { appname } = data;
    if (tokenId && profileObj) {
      const data = {
        token: tokenId,
        type_of_account: 'google',
      };
      const dataUser = {
        first_name: profileObj.name,
        last_name: profileObj.givenName,
        avatar: profileObj.imageUrl,
        emails: [{ email: profileObj.email, name: profileObj.name }],
      };
      let dataSent = {
        authenticate: data,
        user: dataUser,
        appname: appname,
      };

      return this.props.__postDataSso(dataSent);
    }
  };

  reChaptcha = (response) => {
    // console.log(response)
    return response;
  };

  setRecaptcha = (response) => {
    // console.log('set recaptcha')
    const { data } = this.state;
    return this.setState((state) => ({
      ...state,
      data: {
        ...data,
        recaptcha: response,
      },
    }));
  };

  responseApple = (res) => {
    const { data } = this.state;
    const { appname } = data;

    if (res.error) {
      // console.log('error login apple', res);
    } else if (res.authorization) {
      const { id_token } = res.authorization;
      // const { user } = res;
      const data = {
        token: res.authorization.id_token,
        code: res.authorization.code,
        type_of_account: 'apple',
      };

      var decodedHeader = jwtDecode(id_token);
      const { email } = decodedHeader;

      let dataSent = {
        authenticate: data,
        appname: appname,
        user: {
          emails: [{ email: email, name: '' }],
        },
      };
      const domainEmail = email.split('@').pop();
      if (domainEmail !== 'privaterelay.appleid.com') {
        return this.props.__postDataSso(dataSent);
      }

      return this.props.__openModal({
        modal: 'CONFIRM_LOGIN',
        open: true,
        data: dataSent,
      });
    }
  };

  // change title
  changeTitleState = (params) => {
    const { title, btnRegister } = params;
    if (title !== '') {
      this.setState((state) => ({
        ...state,
        title: title,
        textBtnJoin: btnRegister,
      }));
    }
  };

  render() {
    const { data, title } = this.state;
    const { modulAuth, email, modulSsoFacebook, modulSso, codeCase, redirectBack, searchParam } =
      this.props;
    const { loadingSend } = modulAuth;
    return (
      <div className='wrapper-login'>
        <ModalConfirm />
        <div className='login-container '>
          <Card className='py-2 px-2'>
            <CardBody>
              <Row className='mb-3'>
                <a href='https://giftano.com/'>
                  <img
                    src='https://giftano.imgix.net/assets/Giftano-Logo-with-text.png?auto=format'
                    className='auth-logo mx-auto d-block'
                    alt={'Giftano logo'}
                  />
                </a>
              </Row>

              <div className=' d-flex justify-content-center'>
                <h4 className='my-3 text-black text-sm-center text-capitalize'>{title}</h4>
              </div>

              <Form onSubmit={this.handleSubmit} className='form-login'>
                <FormGroup className='mb-2 form-group'>
                  <Label>Email</Label>
                  <InputField
                    type='text'
                    className='login-input'
                    placeholder='Enter your email'
                    ref={this.fields[0]}
                    name='user'
                    validation={'required'}
                    onChange={this.handleChange}
                    value={email ? email : data.user}
                    disabled={modulSso.loadingSend || modulSsoFacebook.loadingSend || loadingSend}
                    message='Please enter your email/username'
                  />
                </FormGroup>

                <FormGroup className='mb-2 form-group'>
                  <Label>Password</Label>
                  <InputField
                    className='login-input'
                    type='password'
                    placeholder='Enter your password'
                    ref={this.fields[1]}
                    name='password'
                    onChange={this.handleChange}
                    validation={'required'}
                    value={data.password}
                    disabled={modulSso.loadingSend || modulSsoFacebook.loadingSend || loadingSend}
                    message='Please enter your password'
                  />
                </FormGroup>
                <div className='d-flex justify-content-end align-items-center'>
                  <h6 className='forgot-link '>
                    <NavLink
                      to='/forgot-password'
                      className=' fs-14 nav-link mr-0 mb-0 pr-1 text-right text-gf-primary'
                    >
                      Forgot Password?
                    </NavLink>
                  </h6>
                </div>
                <FormGroup className='text-right form-group'>
                  <Button
                    type='submit'
                    color='primary'
                    className='w-100 mt-3 btn-login'
                    disabled={
                      loadingSend || (modulSsoFacebook.loadingSend && data.recaptcha !== '')
                    }
                  >
                    {loadingSend || modulSsoFacebook.loadingSend ? 'Loading...' : 'Sign in'}
                  </Button>
                </FormGroup>
              </Form>
              <div className='text-center d-flex justify-content-center'></div>
              <p className='my-3 login-divider text-center'>or</p>
              <div className='mt-2'>
                <SSO_GOOGLE callback={this.responseGoole} />
              </div>
              <div className='mt-3'>
                <Facebook textButton='Continue with Facebook' />
              </div>
              {/* <div className="apple-login-container">
                <AppleLogin
                  clientId="com.giftano.account"
                  scope="name email"
                  render={(props) => (
                    <button className="btn btn-apple" {...props}>
                      <FaApple style={{ marginRight: '.5em' }} /> Continue with
                      Apple
                    </button>
                  )}
                  redirectURI={window.location.origin}
                  usePopup={true}
                  designProp={{
                    width: 375,
                    height: 45,
                    border_radius: 50,
                  }}
                  callback={this.responseApple}
                  disabled={
                    modulSso.loadingSend ||
                    modulSsoFacebook.loadingSend ||
                    loadingSend
                  }
                />
              </div> */}

              <ReCaptcha
                ref={(ref) => (this.recaptcha = ref)}
                sitekey={RECHAPTCHA_CLIENT}
                action='action_name'
                verifyCallback={this.setRecaptcha}
              />
              <div className='bg-white text-secondary text-center mt-3 px-0'>
                <p>
                  Do not have an account?
                  <NavLink
                    to={`/sign-up` + searchParam}
                    className='nav-link mr-0 mb-0 pr-1 text-gf-primary'
                  >
                    Sign up now
                  </NavLink>
                </p>
              </div>
              <div className='mt-3'>
                <ButtonDinamyc
                  codeCase={codeCase}
                  redirectBack={redirectBack}
                  changeTitleState={(param) => this.changeTitleState(param)}
                />
              </div>
            </CardBody>
          </Card>
          <p className='text-center mt-4 mb-3'>v.{PackageInfo.version}</p>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ auth, modulApp, modulRecaptcha, modulSsoFacebook, modulSso }) => ({
  modulAuth: auth,
  modulApp,
  modulRecaptcha,
  modulSsoFacebook,
  modulSso,
});

const mapDispatchToProps = (dispatch) => ({
  __dummyAuth: () => [dispatch(__dummyAuth())],
  __postDataSso: (params) => [dispatch(__postDataSso(params))],
  __postDataAuth: (params) => [dispatch(__postDataAuth(params))],
  __getDataSession: () => [dispatch(__getDataSession())],
  __postDataRecaptcha: (params) => [dispatch(__postDataRecaptcha(params))],
  __openModal: (params) => [dispatch(__openModal(params))],
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
