import { CatchError } from '../CatchError';
import { GetAllGifts, GetAllGiftsNotLinked } from './GiftCRUD';

const initialState = {
  data: {
    vouchers: [],
    ecards: []
  },
  data_not_linked: {
    vouchers: [],
    ecards: []
  },
  is_loading: false,
  is_loading_not_linked: false,
  is_loaded: false,
  is_loaded_not_linked: false
};

const actionType = {
  SET_DATA: 'GIFT_SET_DATA',
  SET_DATA_NOT_LINKED: 'GIFT_SET_DATA_NOT_LINKED',
  START_LOADING: 'GIFT_START_LOADING',
  START_LOADING_NOT_LINKED: 'GIFT_START_LOADING_NOT_LINKED',
  STOP_LOADING: 'GIFT_STOP_LOADING',
  STOP_LOADING_NOT_LINKED: 'GIFT_STOP_LOADING_NOT_LINKED'
};

export const GiftReducer = (state = initialState, action) => {
  let newState = { ...state };
  switch (action.type) {
    case actionType.SET_DATA:
      newState.data = action.data || [];
      newState.is_loaded = true;
      break;
    case actionType.SET_DATA_NOT_LINKED:
      newState.data_not_linked = action.data || [];
      newState.is_loaded_not_linked = true;
      break;
    case actionType.START_LOADING:
      newState.is_loading = true;
      break;
    case actionType.STOP_LOADING:
      newState.is_loading = false;
      break;
    case actionType.START_LOADING_NOT_LINKED:
      newState.is_loading_not_linked = true;
      break;
    case actionType.STOP_LOADING_NOT_LINKED:
      newState.is_loading_not_linked = false;
      break;
    default:
  }
  return newState;
};

export const GiftActions = {
  loadGifts: (member_id) => async (dispatch) => {
    dispatch({ type: actionType.START_LOADING });
    try {
      let res = await GetAllGifts(member_id);
      if (res.status === 1) {
        dispatch({ type: actionType.SET_DATA, data: res.data });
      }
    } catch (err) {
      // console.log('error load gifts', err);
      CatchError(err);
    }
    dispatch({ type: actionType.STOP_LOADING });
  },
  loadGiftsNotLinked: (session_id) => async (dispatch) => {
    dispatch({ type: actionType.START_LOADING_NOT_LINKED });
    try {
      let res = await GetAllGiftsNotLinked(session_id);
      if (res.status === 1) {
        dispatch({ type: actionType.SET_DATA_NOT_LINKED, data: res.data });
      }
    } catch (err) {
      CatchError(err);
    }
    dispatch({ type: actionType.STOP_LOADING_NOT_LINKED });
  }
};
