import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';
import { Button, Form, FormGroup } from 'reactstrap';
import { InputField, notifDanger } from '../../../components';
import LoginSso from '../../../components/loginsso/LoginSso';
import { __getDataEmail } from '../../../modules/Auth/Email_act';

class FormEmail extends Component {
  constructor(props) {
    super(props);
    this.fields = [createRef()];
    this.state = {
      data: {
        user: '',
      },
      step: 1,
    };
  }

  componentDidMount() {
    const { user } = this.props;
    // console.log(this.props);
    if (user) {
      const { data } = this.state;
      this.setState((state) => ({
        ...state,
        data: {
          ...data,
          user: user,
        },
      }));
    }
  }

  componentDidUpdate(prevProps) {
    const { modulCheckEmail, composed } = this.props;
    const { is_exist, code } = modulCheckEmail;
    const { data } = this.state;
    const { allow_register } = composed;
    if (code !== prevProps.modulCheckEmail.code && code === 200) {
      const { user } = data;
      if (is_exist) {
        return this.props.callback({ name: 'user', value: user, step: 2 });
      } else {
        if (allow_register) {
          // allow register
          return this.props.callback({ name: 'user', value: user, step: 3 });
        } else {
          // disabled register
          notifDanger('Your account is not registered');
        }
      }
    }
  }

  handleSumit = (e) => {
    e.preventDefault();
    var valid = true;
    var isValid = true;
    let { data } = this.state;

    // trim data
    data.user = data.user.trim();
    // console.log(this.fields[0]);
    for (let index = 0; index < this.fields.length; index++) {
      valid = this.fields[index].current.validate();
      if (!valid) {
        isValid = false;
      }
    }

    // console.log(isValid);
    if (isValid) {
      //   funtion to send data
      const { user } = data;
      // check email
      return this.props.__getDataEmail({ user: user });
      // return this.props.callback({ name: 'user', value: user, step: 2 });
    }
  };
  handleChange = (name, value) => {
    const { data } = this.state;
    this.setState((state) => ({
      ...state,
      data: {
        ...data,
        [name]: value,
      },
    }));
  };
  render() {
    const { data } = this.state;
    const { user } = data;
    const { modulCheckEmail, composed, modulSso } = this.props;
    const { loadingPage } = modulCheckEmail;
    const { btnSso, title, formLogin, sign_button_text } = composed;
    return (
      <div>
        <div className=' d-flex justify-content-center'>
          <h4 className='my-3 text-black text-center'>{title}</h4>
        </div>
        {formLogin ? (
          <Form onSubmit={this.handleSumit}>
            <FormGroup>
              <InputField
                type='text'
                onFocus={true}
                clasname='login-input'
                placeholder='Enter your email'
                name='user'
                validation='required'
                value={user}
                message='Please enter your email'
                ref={this.fields[0]}
                onChange={this.handleChange}
                label='Email'
                autoComplete={'on'}
              />
            </FormGroup>
            <FormGroup className='text-right form-group'>
              <Button
                type='submit'
                color='primary'
                disabled={loadingPage || modulSso.loadingSend}
                className='w-100 mt-3 btn-login'
              >
                {loadingPage || modulSso.loadingSend ? 'loading...' : sign_button_text}
              </Button>
            </FormGroup>
          </Form>
        ) : (
          ''
        )}

        {this.props.codeCase === '4' && (
          <>
            <p className='my-3 login-divider text-center'>or</p>
            <Button
              block
              color='primary'
              onClick={() => {
                window.location =
                  process.env.REACT_APP_BUILD_MODE === 'PRODUCTION'
                    ? 'https://business.giftano.com/create-account'
                    : 'https://sandbox.giftano-for-business.pages.dev/create-account';
              }}
            >
              Create New Account!
            </Button>
          </>
        )}

        <div className={btnSso ? 'd-block' : 'd-none'}>
          <LoginSso
            disabled={loadingPage || modulSso.loadingSend}
            btnSso={btnSso}
            formLogin={formLogin}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ modulCheckEmail, modulSso }) => ({
  modulCheckEmail: modulCheckEmail,
  modulSso: modulSso,
});

const mapDispatchToProps = (dispatch) => ({
  __getDataEmail: (params) => [dispatch(__getDataEmail(params))],
});

export default connect(mapStateToProps, mapDispatchToProps)(FormEmail);
