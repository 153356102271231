const defaultState = {
  loadingPage: true,
  loadingSend: true,
  restData: {},
  code: 500,
  message: '',
};

let response = {};
const Recaptcha_lib = (state = defaultState, action = {}) => {
  switch (action.type) {
    case 'SUCCESS_RECAPTCHA_VERIFY':
      response = {
        ...state,
        restData: action.data ? action.data : {},
        code: action.code ? action.code : 500,
        loadingPage: false,
        loadingSend: false,
        message: action.message ? action.message : '',
      };

      break;
    case 'LOADING_RECAPTCHA_VERIFY':
      response = {
        ...state,
        loadingPage: true,
        code: action.code ? action.code : 500,
        loadingSend: true,
      };
      break;
    case 'ERROR_RECAPTCHA_VERIFY':
      response = {
        ...state,
        message: action.message ? action.message : '',
        code: action.code ? action.code : 500,
      };
      break;
    default:
      return {
        ...state,
        loadingPage: false,
        loadingSend: false,
      };
  }

  return response;
};

export default Recaptcha_lib;
