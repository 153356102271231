import React, { Component } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import PageSetupAccount from '../pages/v2/signup';
import ComplateAccount from '../pages/v2/complateAccount';

import ButtonDinamyc from '../components/dinamyc/ButtonDinamyc';
import PageResetPassword from '../pages/v2/resetPassword';
import { Page404 } from '../pages';

export class blankPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      buttons: [],
      background: {
        url: 'https://giftano.imgix.net/assets/giftano-account.90d01e95e357473f85ab.png?auto=format',
      },
    };
  }

  componentDidMount() {
    const { modulContentStrapi } = this.props;
    const { code } = modulContentStrapi;
    if (code === 200) {
      this.setContent();
    }
    // console.log(modulContentStrapi);
  }

  setContent = () => {
    const { modulContentStrapi } = this.props;
    const { restData } = modulContentStrapi;
    const { portal_buttons, Background } = restData;
    this.setState((state) => ({
      ...state,
      buttons: portal_buttons,
      background: Background,
    }));
  };

  changeTitleState = (values) => {
    const { login_bg } = values;
    if (login_bg) {
      this.setState((state) => ({
        ...state,
        classBackground: login_bg,
      }));
    }
  };
  render() {
    const { codeCase, redirectBack } = this.props;
    const { background } = this.state;
    return (
      <Container fluid className='layoutWrapper auth-page'>
        <Row className='h-100'>
          <Col
            lg='6'
            md='12'
            className='bg-account'
            style={{ backgroundImage: `url(${background.url})` }}
          ></Col>
          <Col lg='6' md='8' sm='12' className='login-block'>
            <BrowserRouter basename='/'>
              <Switch>
                <Route
                  path={'/setup/:key'}
                  exact
                  name='Login Page'
                  title='Login Page'
                  render={() => <PageSetupAccount {...this.props} />}
                />
                <Route
                  path={'/set/:key'}
                  exact
                  name='Login Page'
                  title='Login Page'
                  render={() => <ComplateAccount {...this.props} />}
                />
                <Route
                  path={'/reset-password/:key'}
                  name='Login Page'
                  title='Signup Page'
                  render={() => <PageResetPassword showLogo={false} {...this.props} />}
                />
                <Route render={() => <Page404 {...this.props} />} />
              </Switch>
            </BrowserRouter>
          </Col>
        </Row>
        <ButtonDinamyc
          codeCase={codeCase}
          redirectBack={redirectBack}
          render={false}
          changeTitleState={(param) => this.changeTitleState(param)}
        />
      </Container>
    );
  }
}

const mapStateToProps = (modulContentStrapi) => ({
  modulContentStrapi,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(blankPage);
