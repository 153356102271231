import React, { Component } from 'react';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import { FormControl } from '@material-ui/core';

export default class Addorment extends Component {
  constructor(props) {
    super(props);
    this.setState = {};
  }
  handleInputChange = (e) => {
    this.props.onChange(e);
  };
  render() {
    const { textAdorment, name, defaultValue } = this.props;
    return (
      <div>
        <FormControl style={{ width: '100%' }}>
          <Input
            id="standard-adornment-weight"
            onChange={(e) => this.handleInputChange(e)}
            defaultValue={defaultValue}
            name={name}
            startAdornment={
              <InputAdornment position="star">
                {textAdorment !== '' ? '+' + textAdorment : '-'}
              </InputAdornment>
            }
            aria-describedby="standard-weight-helper-text"
            inputProps={{
              'aria-label': 'weight'
            }}
          />
        </FormControl>
      </div>
    );
  }
}

Addorment.defaultProps = {
  textAdorment: ''
};
