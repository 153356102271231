import React, { Component } from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import { FormGroup } from "react-bootstrap";
import { Label, FormText } from "reactstrap";

class ReactSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      options: [],
    };
  }

  componentDidUpdate(prevProps) {
    const { options } = this.props;

    if (options !== prevProps.options) {
      this.setState((state) => ({
        ...state,
        options: [...options],
      }));
    }
  }

  handleChange = (name, event) => {
    let data = { name: name, row: event };
    this.props.onCallback(data);
  };
  render() {
    const {
      name,
      isDisabled,
      label,
      options,
      message,
      defaultValue,
      className,
      placeholder,
      isLoading,
    } = this.props;
    // const { options } = this.state;

    return (
      <FormGroup className={className}>
        {label && <Label>{label}</Label>}
        {!isLoading ? (
          <Select
            classNamePrefix='Rs'
            isDisabled={isDisabled}
            name={name}
            className={className}
            placeholder={placeholder !== "" ? placeholder : label}
            options={options}
            onChange={(e) => this.handleChange(name, e)}
            defaultValue={defaultValue}
            // menuIsOpen={true}
          />
        ) : (
          "Loading..."
        )}
        {message === "" ? "" : <FormText color='danger'>{message}</FormText>}
      </FormGroup>
    );
  }
}

ReactSelect.defaultProps = {
  name: "example_name",
  label: "",
  options: [],
  isDisabled: false,
  onCallback: () => {},
  isLoading: false,
  defaultValue: "",
  message: "",
  className: "",
};

ReactSelect.propTypes = {
  name: PropTypes.string,
  options: PropTypes.array,
  isDisabled: PropTypes.bool,
  onCallback: PropTypes.func,
  label: PropTypes.string,
  defaultValue: PropTypes.string,
  message: PropTypes.string,
  isLoading: PropTypes.bool,
  className: PropTypes.string,
};

export default ReactSelect;
