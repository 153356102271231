// import { CONNECTION } from "../../config/Connections";
// import { CONFIG_ } from "../../config/Config";
import axios from "axios";

// var get = "";
// var post = "";
// var isLive = false;
export const __getDataSession = () => {
  return (dispatch) => {
    axios
      .get("https://account.dev/get/", {
        withCredentials: true,
      })
      .then((response) => {
        console.log(response);
      })
      .catch((response) => {
        console.log(response);
      });
    return dispatch({
      type: "SUCCESS_SESSION_AUTH",
      message: "Success get data",
      data: [],
    });
  };
};
