import { CONNECTION } from "../../config/Connections";
import { CONFIG_ } from "../../config/Config";

const PATH = process.env.REACT_APP_PATH_COUNTRY;
var get = "countries";
// var post = "country";
var isLive = true;
export const __getDataCountry = () => {
  return (dispatch) => {
    let config = CONFIG_({ url: get, method: "GET", live: isLive, path: PATH });
    dispatch({ type: "LOADING_COUNTRY" });
    CONNECTION(config)
      .then((response) => {
        const { code, data } = response;
        if (code === 200) {
          return dispatch({
            type: "SUCCESS_COUNTRY",
            message: "Success get data",
            data: data,
            code: code,
          });
        }
        return dispatch({ type: "ERROR_COUNTRY", message: "failed" });
      })
      .catch((response) => {
        console.log(response);
        return dispatch({ type: "ERROR_COUNTRY", message: "Network error.." });
      });
  };
};
