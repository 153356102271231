import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'reactstrap';
import { __openModal as openModal } from '../../modules/Modals';
import Confirmation from './Confirmation';
import PropTypes from 'prop-types';

const mapStateToProps = ({ modal, modulContentStrapi }) => ({
  modal: modal,
  modulContentStrapi: modulContentStrapi,
});

const mapDispatchToProps = (dispatch) => ({
  __openModal: (params) => [dispatch(openModal(params))],
});

class ButtonDinamyc extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rb_modalButton: false,
    };
  }

  componentDidMount() {
    const { codeCase, modulContentStrapi } = this.props;
    const { code } = modulContentStrapi;
    if (codeCase && code === 200) {
      this.caseChangeTitle(codeCase);
    }
  }

  caseChangeTitle = (codeCase = '') => {
    const { modulContentStrapi } = this.props;
    const { restData } = modulContentStrapi;
    const { modes } = restData;

    var textTitle = 'Sign in';
    var btnText = 'Sign Up Now';
    var btnSso = true;
    var formLogin = true;
    var classBackground = 'bg-default';
    var getMode = {};
    var loginBg = {};
    var allowRegister = true;
    var rb_modalButton = false;
    var button_text_sign_in_ = 'continue';
    var button_text_sign_up_ = 'Sign Up';
    var title_sign_up_ = 'Create Your Account';
    if (codeCase !== '') {
      if (modes.length) {
        getMode = modes.filter((key) => key.mode.toString() === codeCase);
        if (getMode.length) {
          const {
            allow_login_sso,
            allow_register,
            button_text,
            form_login,
            login_bg,
            rb_modal_button,
            button_text_sign_in,
            button_text_sign_up,
            title_sign_in,
            title_sign_up,
          } = getMode[0];
          textTitle = title_sign_in;
          btnText = button_text;
          btnSso = allow_login_sso;
          formLogin = form_login;
          allowRegister = allow_register;
          loginBg = login_bg;
          rb_modalButton = rb_modal_button;
          button_text_sign_in_ = button_text_sign_in;
          button_text_sign_up_ = button_text_sign_up;
          title_sign_up_ = title_sign_up;
          this.setState((state) => ({
            ...state,
            rb_modalButton: rb_modalButton,
          }));
        }
      }
    }

    return this.props.changeTitleState({
      title: textTitle,
      btnText: btnText,
      btnSso: btnSso,
      formLogin: formLogin,
      classBackground: classBackground,
      login_bg: loginBg,
      allow_register: allowRegister,
      sign_button_text: button_text_sign_in_,
      signup_button_text: button_text_sign_up_,
      title_text_sign_up: title_sign_up_,
    });
  };

  render() {
    const { redirectBack, buttonType, render } = this.props;
    const { rb_modalButton } = this.state;
    // console.log(rb_modalButton);
    if (render) {
      return (
        <div className='text-center w-100 mb-3'>
          <Confirmation redirectBack={redirectBack} />
          {(() => {
            if (buttonType) {
              if (!rb_modalButton) {
                return redirectBack !== '' ? (
                  <NavLink className='btn-cta-guest text-gf-primary' href={redirectBack}>
                    Return to Previous page
                  </NavLink>
                ) : (
                  ''
                );
              } else {
                return redirectBack !== '' ? (
                  <NavLink
                    className='btn-cta-guest text-gf-primary'
                    onClick={() =>
                      this.props.__openModal({
                        modal: 'CONFIRM_LOGIN_DINAMYC',
                        open: true,
                      })
                    }
                  >
                    Checkout as guest
                  </NavLink>
                ) : (
                  ''
                );
              }
            }
          })()}
        </div>
      );
    } else {
      return '';
    }
  }
}
ButtonDinamyc.defaultProps = {
  buttonType: true,
};

ButtonDinamyc.propTypes = {
  codeCase: PropTypes.string,
  modulContentStrapi: PropTypes.object,
  buttonType: PropTypes.bool,
  changeTitleState: PropTypes.func,
  redirectBack: PropTypes.string,
  render: PropTypes.any,
};

export default connect(mapStateToProps, mapDispatchToProps)(ButtonDinamyc);
