import { CONFIG_ } from '../../config/Config';
import { STRAPI, CONNECTION, flattenObj } from '../../config/Connections';
const fromInternal = false;
export const __getDataStrapi = () => async (dispatch) => {
  // console.log('Load content...');
  dispatch({ type: 'LOADING_STRAPI_CONTENT' });

  if (fromInternal) {
    /**
     * @description
     * Form Sandbox or production
     */
    const config = CONFIG_({
      method: 'GET',
      url: 'content/strapi/get/account',
    });
    const res = await CONNECTION(config);
    const { code, data } = res;

    if (code === 200) {
      // console.log('Content loaded');
      return dispatch({
        type: 'SUCCESS_STRAPI_CONTENT',
        code: 200,
        data: { ...data },
        message: '',
      });
    }
    return dispatch({
      type: 'ERROR_STRAPI_CONTENT',
      code: 200,
      data: { ...data },
      message: '',
    });
  } else {
    /**
     * @description
     * Directly Strapi
     */
    const res = await STRAPI({
      method: 'GET',
      path: 'account?populate%5B0%5D=modes&populate%5B1%5D=portal_buttons.icon&populate%5B3%5D=verify_success&populate%5B4%5D=verify_invalid&populate%5B5%5D=reset_password_success&populate%5B6%5D=reset_password_invalid&populate%5B7%5D=background',
    });
    const { status, data } = res;
    if (status === 200) {
      // console.log('Content loaded');
      return dispatch({
        type: 'SUCCESS_STRAPI_CONTENT',
        code: 200,
        data: flattenObj(data) || [],
        message: '',
      });
    }

    return dispatch({
      type: 'ERROR_STRAPI_CONTENT',
      code: 200,
      data: { ...data },
      message: '',
    });
  }
};
