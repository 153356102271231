import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Col, Row, Card, CardBody } from 'reactstrap';
import SVG from 'react-inlinesvg';
import { getProtocol } from '../../config/Helper';
import { FiAlertTriangle, FiLogOut } from 'react-icons/fi';
import { FaUser } from 'react-icons/fa';
import { Button } from 'react-bootstrap';
// import { CommunicationStayCurrentLandscape } from 'material-ui/svg-icons';
// import InputField from '../../components/form/FormFields';
export class Portal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      buttons: [],
      background: {
        url: 'https://giftano.imgix.net/assets/giftano-account.90d01e95e357473f85ab.png?auto=format',
      },
    };
  }

  componentDidMount() {
    // throw new Error('Required'); // Throws a new Error object

    const { modulContentStrapi, modulUserInfo } = this.props;
    const { code } = modulContentStrapi;
    if (code === 200) {
      this.setContent();
    }

    if (modulUserInfo.code === 200) {
      this.setUserInfo();
    }
    // console.log('jajal', this.state);
  }

  setContent = () => {
    const { modulContentStrapi } = this.props;
    const { restData } = modulContentStrapi;
    const { portal_buttons, Background } = restData;
    var pButtons = portal_buttons.map((item) => {
      var icon = item.icon;
      return {
        ...item,
        icon: icon[0],
      };
    });

    this.setState((state) => ({
      ...state,
      buttons: pButtons ? pButtons : [],
      background: Background
        ? Background
        : {
            url: 'https://giftano.imgix.net/assets/giftano-account.90d01e95e357473f85ab.png?auto=format',
          },
    }));
  };
  setUserInfo = () => {
    const { modulUserInfo } = this.props;
    const { restData } = modulUserInfo;
    const { data } = restData;
    this.setState((state) => ({
      ...state,
      data: data,
    }));
  };

  getIcons = (value) => {
    const { buttons } = this.state;
    let getButton = [];
    if (buttons.length) {
      getButton = buttons.filter((item) => item.app_name === value);
      if (getButton.length) {
        const { icon } = getButton[0];
        return icon ? icon.url : '';
      }
    }

    return '/assets/user-regular.svg';
  };

  getName = (value) => {
    const { buttons } = this.state;
    let getButton = [];
    if (buttons.length) {
      getButton = buttons.filter((item) => item.app_name === value);
      if (getButton.length) {
        const { title } = getButton[0];
        return title;
      }
    }

    return '';
  };

  render() {
    const { apps, loading, errorMessage, userInfo } = this.props;
    const images = [
      '/assets/user-regular.svg',
      '/assets/user-regular.svg',
      '/assets/user-regular.svg',
      '/assets/user-regular.svg',
      '/assets/user-regular.svg',
      '/assets/user-regular.svg',
      '/assets/user-regular.svg',
      '/assets/user-regular.svg',
    ];

    let i = 0;
    // console.log('propsie', this.props);
    // console.log(apps.length);
    // return (
    //   <div className='wrapper-login poertal-page'>
    //     <div className='login-container'>
    //       <div className='p-3'>
    //         <div className='col-4 card'>

    //         </div>
    //         <div className='col-8'>

    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // );
    const colClass =
      errorMessage === ''
        ? 'd-flex flex-wrap align-content-start  p-sm-1 px-lg-4 wrapper-apps'
        : 'd-flex flex-wrap align-content-center justify-content-center  p-sm-1 px-lg-4 wrapper-apps';

    // console.log(apps);

    return (
      <div className='wrapper-login portal-page'>
        <div className='login-container'>
          <Card className='p-3 card-portal' style={{ height: '100%' }}>
            <CardBody
              className='portal-container c d-flex flex-column flex-md-row flex-lg-row flex-xl-row flex-xxl-row p-0'
              style={{
                justifyContent: 'unset',
                minHeight: '100%',
                height: '100%',
              }}
            >
              {/* <CardBody className='portal-container'> */}

              {/* <div style={{height: '100%'}}> */}
              <div
                className='profile-scaffold d-flex align-items-center align-content-start flex-row flex-md-column flex-lg-column flex-xl-column flex-xxl-column pb-3 c'
                style={{
                  paddingRight: '12px',
                  paddingLeft: '12px',
                  minHeight: 'max-content',
                  width: '33.3333333333%',
                }}
              >
                <div className='d-flex flex-row flex-md-column flex-lg-column overflow-auto w-100'>
                  <div className='profile-scaffold__img flex-shrink-0'>
                    <FaUser size='50px' color='var(--gf-primary-300)' />
                  </div>
                  <div className='portal-email-and-fullname  p-0'>
                    <div className='col px-4'>
                      <h3 className='text-primary profile-name overflow-auto flex-grow text-start text-md-center text-lg-center text-xxl-center'>
                        Hi,
                        {userInfo.full_name !== '' || userInfo.full_name
                          ? ' ' + userInfo.full_name + '!'
                          : ' '}
                      </h3>
                      <h6 className='profile-email text-wrapper'>
                        {userInfo.email !== '' || userInfo.email ? '' + userInfo.email : ' '}
                      </h6>
                    </div>
                  </div>
                  <Link
                    to={'/logout'}
                    className='btn btn-link text-warning  py-2 d-none d-md-block d-lg-block'
                    style={{ textDecoration: 'none' }}
                  >
                    <FiLogOut className='me-2' />
                    LOG OUT
                  </Link>
                </div>
                <img
                  className='d-none d-md-block d-lg-block mt-auto'
                  src='https://giftano.imgix.net/assets/giftano-logo-white-lg.png?auto=format'
                  alt='giftano-logo-white'
                  style={{ bottom: '0', top: '0', position: 'relative' }}
                />
                <img
                  className='d-block d-md-none ms-auto'
                  src='https://giftano.imgix.net/assets/giftano-white-square.png?auto=format'
                  alt='giftano-logo-white'
                  style={{
                    bottom: '0',
                    top: '0',
                    right: '0',
                    position: 'relative',
                    margin: '0',
                    marginLeft: 'auto',
                    marginRight: '15px',
                    paddingLeft: '',
                  }}
                />
              </div>

              {!loading ? (
                apps.length > 0 ? (
                  <div
                    className='menu-grid'
                    style={{
                      overflow: 'auto',
                      width: '66.66666667%',
                      height: '100%',
                    }}
                  >
                    <div
                      className={colClass}
                      style={{
                        height: '100%',
                        overflow: 'auto',
                        minHeight: 'fit-content',
                      }}
                    >
                      <Col lg='4' md='4' xs='6' className='text-center p-1 d-none portal-block'>
                        <a
                          className='btn btn-image btn-image--home mb-3'
                          href='https://giftano.com'
                        >
                          <img
                            src='/assets/giftano-white.png'
                            alt='giftano.com'
                            title='giftano.com'
                          />
                        </a>
                        <p className='text-black'>Giftano.com</p>
                      </Col>

                      {errorMessage !== '' ? (
                        <div className=' align-item-center p-2 justify-content-center text-center'>
                          <h4 className='py-0 font-weight-bold text-center mb-4  text-warning align-content-center'>
                            <FiAlertTriangle className='fs-20 mr-2' /> Permission Denied!
                          </h4>
                          <p className='mb-4 fs-14'>{errorMessage}</p>
                          <div className=' text-center d-flex justify-content-center'>
                            <Button
                              size='sm'
                              rounded={'true'}
                              href={'https://giftano.com'}
                              className='w-50 fs-12 py-1'
                              style={{
                                alignContent: 'center',
                                alignItems: 'center',
                                display: 'flex',
                                justifyContent: 'center',
                                alignSelf: 'center',
                                justifyItems: 'center',
                              }}
                            >
                              Go to Giftano.com
                            </Button>
                          </div>
                        </div>
                      ) : (
                        ''
                      )}
                      {errorMessage === ''
                        ? apps.map((element, key) => {
                            let urlReq = element.domain || element.app_domain;
                            let urlG = urlReq + (urlReq.indexOf('?') > -1 ? '&' : '?');
                            // console.log(urlG);
                            return (
                              <Col className='text-center p-3 portal-block' key={i++} md='4' xs='6'>
                                <a
                                  className='btn btn-image mb-3'
                                  href={getProtocol(
                                    urlG + 'session_id=' + btoa(localStorage.getItem('session_id'))
                                  )}
                                >
                                  {this.getIcons(element.slug) === '' ? (
                                    <SVG
                                      src={
                                        element.image
                                          ? element.image
                                          : process.env.PUBLIC_URL + images[key]
                                      }
                                      width={30}
                                      height={30}
                                      alt={element.name || 'Giftano.com'}
                                      title={element.name || 'Giftano.com'}
                                    />
                                  ) : (
                                    <img
                                      src={this.getIcons(element.type)}
                                      alt={element.slug}
                                      className='mb-2 mt-3'
                                    />
                                  )}
                                  <p className='text-titile-app'>
                                    {this.getName(element.type) !== ''
                                      ? this.getName(element.type)
                                      : 'Giftano.com'}
                                  </p>
                                </a>
                              </Col>
                            );
                          })
                        : ''}
                    </div>
                  </div>
                ) : (
                  <div
                    className='menu-grid'
                    style={{
                      overflow: 'auto',
                      width: '66.66666667%',
                      height: '100%',
                    }}
                  >
                    <div
                      className={colClass}
                      style={{
                        height: '100%',
                        overflow: 'auto',
                        minHeight: 'fit-content',
                      }}
                    >
                      <Col lg='4' md='4' xs='6' className='text-center p-1 d-none portal-block'>
                        <a
                          className='btn btn-image btn-image--home mb-3'
                          href='https://giftano.com'
                        >
                          <img
                            src='/assets/giftano-white.png'
                            alt='giftano.com'
                            title='giftano.com'
                          />
                        </a>
                        <p className='text-black'>Giftano.com</p>
                      </Col>

                      {errorMessage !== '' ? (
                        <div className=' align-item-center p-2 justify-content-center text-center'>
                          <h4 className='py-0 font-weight-bold text-center mb-4  text-warning align-content-center'>
                            <FiAlertTriangle className='fs-20 mr-2' /> Permission Denied!
                          </h4>
                          <p className='mb-4 fs-14'>{errorMessage}</p>
                          <div className=' text-center d-flex justify-content-center'>
                            <Button
                              size='sm'
                              rounded={'true'}
                              href={'https://giftano.com'}
                              className='w-50 fs-12 py-1'
                              style={{
                                alignContent: 'center',
                                alignItems: 'center',
                                display: 'flex',
                                justifyContent: 'center',
                                alignSelf: 'center',
                                justifyItems: 'center',
                              }}
                            >
                              Go to Giftano.com
                            </Button>
                          </div>
                        </div>
                      ) : (
                        <Col
                          sm='12'
                          className=' h-100 d-flex flex-wrap align-content-center justify-content-center  p-sm-1 px-lg-4 wrapper-apps  w-100'
                        >
                          <Row className='justify-content-center  d-flex  align-content-center w-100'>
                            <h6 className='text-primary text-center  w-100'>
                              You dont have application, to access.
                            </h6>
                          </Row>
                        </Col>
                      )}
                    </div>
                  </div>
                )
              ) : (
                <Row className='justify-content-center mb-4'>
                  <h6>Loading...</h6>
                </Row>
              )}
              {/* </div> */}
              {loading ? (
                <Row className='justify-content-center mb-4'>
                  <Col md='5' className='text-center p-3 portal-block protal-loading'>
                    <div className='loading mb-3'></div>
                    <p className='loading m-0'></p>
                  </Col>
                </Row>
              ) : (
                ''
              )}

              <Link
                to={'/logout'}
                className='btn btn-link text-warning  py-2 d-block d-md-none d-lg-none mb-2'
                style={{ textDecoration: 'none' }}
              >
                <FiLogOut className='me-2' />
                LOG OUT
              </Link>
            </CardBody>
          </Card>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ modulContentStrapi, modulUserInfo }) => ({
  modulContentStrapi: modulContentStrapi,
  modulUserInfo: modulUserInfo,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Portal);
