import React, { Component } from 'react';
import { LinkedIn } from 'react-linkedin-login-oauth2';
import { Button } from 'reactstrap';

// const LINKEDIN_CLIENTID = process.env.REACT_APP_LINKEDIN_ID;

class Linkedin extends Component {
  responseLinkedin = (params) => {
    return params;
  };
  render() {
    return (
      <div className="linkedin">
        <LinkedIn clientId="" onFailure={this.responseLinkedin} onSuccess={this.responseLinkedin}>
          <Button className="btn-block btn-linked text-center w-100 text-white">LinkedIn</Button>
        </LinkedIn>
      </div>
    );
  }
}

export default Linkedin;
