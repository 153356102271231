/* eslint-disable no-use-before-define */
import React, { Component } from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
// import { makeStyles } from "@material-ui/core/styles";

// ISO 3166-1 alpha-2
function countryToFlag(isoCode) {
  return typeof String.fromCodePoint !== "undefined"
    ? isoCode
        .toUpperCase()
        .replace(/./g, (char) =>
          String.fromCodePoint(char.charCodeAt(0) + 127397)
        )
    : isoCode;
}

// const useStyles = makeStyles({
//   option: {
//     fontSize: 15,
//     "& > span": {
//       marginRight: 10,
//       fontSize: 18,
//     },
//   },
// });

export default class AutoComplate extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleChange = (events, values, name) => {
    // console.log(values, name);
    this.props.onCallback(values, name);
  };

  render() {
    const { options, defaultValues, isLoading, name } = this.props;
    // console.log(Object.keys(defaultValues).length);
    return (
      <>
        {!isLoading ? (
          <Autocomplete
            options={options}
            style={{ width: "100%" }}
            getOptionLabel={(option) => option.label}
            name={name}
            disablePortal={true}
            onChange={(events, values) =>
              this.handleChange(events, values, name)
            }
            defaultValue={
              Object.keys(defaultValues).length > 0 ? defaultValues : {}
            }
            renderOption={(option) => (
              <React.Fragment>
                <span className="mr-2">{countryToFlag(option.code)}</span>
                {option.label} ({option.code}) +{option.phone}
              </React.Fragment>
            )}
            renderInput={(params) => (
              <TextField {...params} placeholder="Country" variant="outlined" />
            )}
          />
        ) : (
          ""
        )}
      </>
    );
  }
}

Autocomplete.defaultProps = {
  name: "exmaple_name",
  label: "",
  isLoading: true,
  defaultValues: {},
  options: [],
};

// From https://bitbucket.org/atlassian/atlaskit-mk-2/raw/4ad0e56649c3e6c973e226b7efaeb28cb240ccb0/packages/core/select/src/data/countries.js
