import { CONNECTION } from "../../config/Connections";
import { CONFIG_ } from "../../config/Config";
import { CatchError } from "../CatchError";

var post = "logout";
var isLive = false;
var path = process.env.REACT_APP_PATH_AUTH;

export const __postDataLogout = (params) => {
  const { session_id } = params;
  return (dispatch) => {
    let config = CONFIG_({
      url: post,
      data: params,
      method: "POST",
      path: path,
      live: isLive,
      headers: {
        session_id: session_id,
      },
    });
    dispatch({ type: "LOADING_LOGOUT" });
    CONNECTION(config)
      .then((response) => {
        const { code, data } = response;
        if (code === 200) {
          if (data) {
            // console.log(response);
            localStorage.clear();
            return dispatch({
              type: "SUCCESS_LOGOUT",
              message: "Logout Success",
              code: code,
            });
          } else {
            return dispatch({
              type: "SUCCESS_LOGOUT",
              message: "Has been logout",
              code: code,
            });
          }
        }
        return dispatch({ type: "ERROR_LOGOUT", message: "failed logot" });
      })
      .catch((response) => {
        // console.log(response);
        CatchError(response);
        return dispatch({ type: "ERROR_LOGOUT", message: "Network error.." });
      });
  };
};
