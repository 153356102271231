import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Card, CardBody, Row } from 'reactstrap';
import FormEmail from './FormEmail';
import FormPassword from './FormPassword';
import FormCreateAccount from './FormCreateAccount';
import FormSetBirthday from './FormSetBirthday';
import FormSetGender from './FormSetGender';
import FormVeriifyCode from './FormVeriifyCode';
import PageForgotPassword from '../forgotPassword';
import ButtonDinamyc from '../../../components/dinamyc/ButtonDinamyc';
// import { __postDataLoginwithoutpassword } from '../../../modules/Auth';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { __postDataRecaptcha } from '../../../modules/Verify';
import { notifDanger } from '../../../components';
import ModalConfirm from '../../login/ModalConfirm';
// import { __postDataLoginwithoutpassword } from '../../../modules/Auth';
// import { loadReCaptcha } from 'react-recaptcha-v3';
// const RECHAPTCHA_CLIENT = process.env.REACT_APP_RECHAPTCHA;

const Index = (props) => {
  const dispatch = useDispatch();
  // console.log(dispatch);
  const { executeRecaptcha } = useGoogleReCaptcha();
  // console.log(executeRecaptcha);
  const [result, setResult] = useState({
    data: {
      user: '',
      password: '',
      appname: '',
      recaptcha: '',
    },
    newAccount: {
      user: '',
    },
    composed: {
      title: 'Sign in or Sign up',
      btnText: 'Create account',
      btnSso: true,
      formLogin: true,
    },
    step: 0,
  });

  const callbackFormStep = (params) => {
    const { name, value, step } = params;
    const { data } = result;
    setResult(() => ({
      ...result,
      data: {
        ...data,
        [name]: value,
      },
      step: step,
    }));
  };

  // const modulAuth = useSelector((state) => state.auth);
  // console.log(modulAuth);

  // const modulRecaptcha = useSelector((state) => state.modulRecaptcha);
  // console.log(modulRecaptcha);

  // const modulApp = useSelector((state) => state.modulApp);
  // console.log(modulApp);

  // const modulSsoFacebook = useSelector((state) => state.modulSsoFacebook);
  // console.log(modulSsoFacebook);

  // const modulSso = useSelector((state) => state.modulSso);
  // console.log(modulSso);

  const { step, composed, data } = result;
  const { redirectBack, codeCase } = props;
  // console.log(redirectBack);

  const changeTitleState = (params) => {
    setResult(() => {
      return {
        ...result,
        composed: {
          ...params,
        },
      };
    });
  };

  const callbackPreviousForm = (page = 1) => {
    setResult(() => {
      return {
        ...result,
        step: page,
      };
    });
  };

  const callbackToLogIn = async (params) => {
    dispatch({ type: 'LOADING_RECAPTCHA_VERIFY' });
    dispatch({ type: 'LOADING_AUTH' });
    const { name, value, step } = params;
    const { data } = result;
    setResult(() => {
      return {
        ...result,
        data: {
          ...data,
          [name]: value,
        },
        newAccount: {
          [name]: value,
        },
        step: step,
      };
    });

    try {
      const token = await executeRecaptcha('LOADING_RECAPTCHA_VERIFY');
      let dataSent = {
        ...data,
        recaptcha: token,
        [name]: value,
      };
      // return await this.recaptcha.execute();
      // return this.props.__postDataRecaptcha(dataSent);
      return dispatch(__postDataRecaptcha(dataSent));
      // console.log(dataSent);
    } catch (error) {
      notifDanger('Recaptcha not ready, please reload the page!');
      // console.log(error);
    }
    dispatch({ type: 'ERROR_AUTH', message: 'ERROR VALIDATE TOKEN', code: 400 });
    dispatch({
      type: 'ERROR_RECAPTCHA_VERIFY',
      message: 'ERROR VALIDATE TOKEN',
      code: 400,
    });
  };

  const callbackCreateAccount = (params) => {
    const { name, value, step } = params;
    const { data } = result;

    setResult(() => {
      return {
        ...data,
        newAccount: {
          ...data,
          [name]: value,
        },
        step: step,
      };
    });
  };

  // const handleLoginWithoutPassword = () => {
  //   const { data } = result;
  //   const { user } = data;
  //   return dispatch(__postDataLoginwithoutpassword({ email: user }));
  // };

  return (
    <div className='wrapper-login'>
      <ModalConfirm />

      <div className='login-container'>
        <Card>
          <CardBody>
            <Row className='mb-3'>
              <a href='https://giftano.com/'>
                <img
                  src='https://giftano.imgix.net/assets/Giftano-Logo-with-text.png?auto=format'
                  className='auth-logo mx-auto d-block'
                  alt={'Giftano logo'}
                  style={{ height: '30px' }}
                />
              </a>
            </Row>
            {(() => {
              switch (step) {
                case 1:
                  return (
                    <FormEmail
                      callback={(params) => callbackFormStep(params)}
                      user={result.user}
                      codeCase={codeCase}
                      composed={composed}
                    />
                  );
                case 2:
                  return (
                    <FormPassword
                      callbackPreviousForm={(param) => callbackPreviousForm(param)}
                      callback={(params) => callbackToLogIn(params)}
                      callbackNextStep={(params) => callbackFormStep(params)}
                      user={data.user}
                    />
                  );
                case 3:
                  return (
                    <FormCreateAccount
                      callbackPreviousForm={(param) => callbackPreviousForm(param)}
                      callback={(params) => callbackCreateAccount(params)}
                      composed={composed}
                      newAccount={data}
                    />
                  );
                case 5:
                  return (
                    <FormSetBirthday callback={(params) => this.callbackCreateAccount(params)} />
                  );
                case 6:
                  return (
                    <FormSetGender callback={(params) => this.callbackCreateAccount(params)} />
                  );
                case 7:
                  return (
                    <FormVeriifyCode
                      callbackPreviousForm={(param) => callbackPreviousForm(param)}
                      callback={(params) => callbackFormStep(params)}
                      user={data.user}
                    />
                  );
                case 8:
                  return (
                    <PageForgotPassword
                      callbackPreviousForm={(param) => callbackPreviousForm(param)}
                      callback={(params) => callbackFormStep(params)}
                      user={data.user}
                    />
                  );

                default:
                  return (
                    <FormEmail
                      callback={(params) => callbackFormStep(params)}
                      callbackPreviousForm={(param) => this.callbackPreviousForm(param)}
                      codeCase={codeCase}
                      user={data.user}
                      composed={composed}
                    />
                  );
              }
            })()}
            <ButtonDinamyc
              codeCase={codeCase}
              redirectBack={redirectBack}
              changeTitleState={(param) => changeTitleState(param)}
              render={true}
            />
          </CardBody>
        </Card>
      </div>
    </div>
  );
};

export default Index;
