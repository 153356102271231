export const ENDPOINT = process.env.REACT_APP_ENDPOINT;

export const HEADER = {
  // apikey: process.env.REACT_APP_API_KEY,
  session_id: getSessionID('session_id') ? getSessionID('session_id') : '',
  // dc: "",
};
export const AUTH = {
  auth: {
    username: process.env.REACT_APP_USERNAME,
    password: process.env.REACT_APP_PASSWORD,
  },
};

export const MODE = process.env.REACT_APP_BUILD_MODE;

// IDEA: FALSE IS LOCALHOST, TRUE IS ENDPOINT
// !parseInt(process.env.REACT_APP_API_LIVE)

export const CONFIG_ = ({
  url = '',
  method = 'GET',
  data = {},
  headers = {},
  path = '',
}) => {
  if (MODE !== 'productions') {
    return {
      url: '/api/' + path + url,
      data: data,
      method: method,
      headers: {
        ...HEADER,
        ...headers,
      },
    };
  }
  return {
    url: '/api/' + path + url,
    data: data,
    method: method,
    headers: {
      ...HEADER,
      ...headers,
    },
  };
};

export const CONFIG_STRAPI = ({
  url = '',
  method = 'GET',
  data = {},
  headers = {},
  path = '',
}) => {
  if (MODE !== 'productions') {
    return {
      url: path + url,
      data: data,
      method: method,
      headers: {
        ...HEADER,
        ...headers,
      },
    };
  }
  return {
    url: '/api/' + path + url,
    data: data,
    method: method,
    headers: {
      ...HEADER,
      ...headers,
    },
  };
};

export const CONFIG_2 = () => {};

export const __userID = (key = 'session_id') => {
  return getSessionID(key);
};

function getSessionID(key) {
  return localStorage.getItem(key);
}

export const localstorage_session_id = 'session_id';
