import { CONNECTION } from '../../config/Connections';
import { CONFIG_ } from '../../config/Config';
import { __postDataAuth } from '../Auth/Auth_act';
import { notifDanger } from '../../components';
// import { notifDanger } from '../../components';

// var get = "recaptcha";
var post = 'recaptcha';
var isLive = true;
var path = process.env.REACT_APP_PATH_VERIFY;

export const __postDataRecaptcha = (params) => {
  const { recaptcha } = params;
  return (dispatch) => {
    let config = CONFIG_({
      url: post,
      data: { token: recaptcha },
      method: 'POST',
      live: isLive,
      path: path,
    });
    dispatch({ type: 'LOADING_RECAPTCHA_VERIFY' });
    dispatch({ type: 'LOADING_AUTH' });
    CONNECTION(config)
      .then((response) => {
        const { success } = response;
        if (success) {
          dispatch({
            type: 'SUCCESS_RECAPTCHA_VERIFY',
            code: 200,
            message: 'Success',
          });
          return dispatch(__postDataAuth(params));
        }
        notifDanger('Upps.. something wrong, Please try again');
        dispatch({ type: 'ERROR_AUTH', message: 'Network error..', code: 400 });
        return dispatch({
          type: 'ERROR_RECAPTCHA_VERIFY',
          message: 'failed',
          code: 400,
        });
      })
      .catch((response) => {
        console.log(response);
        return dispatch({
          type: 'ERROR_RECAPTCHA_VERIFY',
          message: 'Network error..',
          code: 400,
        });
      });
  };
};
