import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { compareAppLegacy, getUrl, __validUrl } from '../../config/Helper';
import { manualGettingApplication } from '../../modules/App';
import {
  // manualGettingPerrmissionByApp,
  manualGetUserInfo,
  // __manualGetProfleForGetRoleID,
} from '../../modules/Auth';
import { manualPermission } from '../../modules/Auth/Auth_act';
import { __getDataStrapi } from '../../modules/Content';
import legaDrafApp from './legacyDrafApp.json';
const mode = process.env.REACT_APP_BUILD_MODE;
// import { manualGetProfile } from "../../modules/Auth/Profile_act";
// import { __getDataPermission } from "./../../modules/Auth/Permission_act";
import { captureLogSentry } from '../log/Sentry';
import md5 from 'md5';

const Authenticate = (AuthWraper) => {
  const AuthCore = (props) => {
    const {
      modulAuth,
      modulSso,
      modulRegister,
      modulProfile,
      modulSsoFacebook,
      modulLoginWithoutPassword,
      modulSignUp,
      modulContentStrapi,
    } = props;

    // console.log(modulAuth);
    // console.log(modulAuth);
    const { session_id } = modulAuth;
    const [redirect, setRedirect] = useState(false);
    const [appName, setAppname] = useState('');
    const [apps, setApps] = useState([]);
    const [auth, setAuth] = useState(false);
    const [sessionId, setSessionId] = useState(session_id);
    const [loading, setLoading] = useState(true);
    const [redirectTo, setRedirectTo] = useState('');
    const [paramsUser, setParamsUser] = useState('');
    const [permission, setPermission] = useState('');
    const [userInfo, setUserInfo] = useState({
      full_name: '',
      email: '',
    });

    const [update_profile, setUpdate_profile] = useState(true);

    const [codeCase, setCodeCase] = useState('1');
    const [redirectBack, setRedirectBack] = useState('');
    const [getParam, setGetParam] = useState('');
    const [error, setError] = useState('');

    const appsLegacy =
      mode !== 'PRODUCTION' ? legaDrafApp['development'] : legaDrafApp['production'];

    // listen to get variable redirect
    useEffect(() => {
      const url = window.location.search;
      const params = new URLSearchParams(window.location.search); // id=123
      var redirectParam = '';
      try {
        redirectParam = params.get('redirect') ? atob(params.get('redirect')) : ''; // 123
      } catch (error) {
        redirectParam = '';
      }
      if (url && getParam !== '') {
        setGetParam(url);
      }
      var mode = params.get('mode');
      if (mode !== '' && mode) {
        setCodeCase(mode);
      }
      var redirectBack = params.get('rb');
      if (redirectBack !== '' && redirectBack) {
        var linkRB = false;

        try {
          linkRB = __validUrl(atob(redirectBack));
        } catch (error) {
          linkRB = false;
        }

        setRedirectBack(linkRB ? atob(redirectBack) : '');
      }

      if (redirectParam !== '') {
        setLoading(true);
        let url = getUrl(redirectParam);
        let appName = getUrl(redirectParam, 1);
        setRedirectTo(url);
        setAppname(appName !== '' ? appName : '');
      }
    }, []);

    // console.log(redirectTo);

    // get content strapi
    useEffect(() => {
      // const { code } = modulContentStrapi;
      if (modulContentStrapi.code !== 200) {
        props.__getDataStrapi();
      }
    }, [modulContentStrapi.code]);

    // listen session id on localstorage
    useEffect(() => {
      // console.log(auth);
      // console.log(sessionId);

      if (sessionId !== null) {
        // console.log(sessionId);
        setLoading(true);
        getAutorization();
      } else {
        setLoading(false);
        setAuth(false);
      }
    }, [sessionId]);

    // listen to redirect triger apps
    useEffect(() => {
      if (apps.length > 1) {
        setLoading(false);
        setAuth(true);
        return;
      }

      if (apps.length === 1) {
        // var pp = permission.data
        //   ? Object.keys(permission.data).length
        //   : permission.profile;
        // console.log(apps);
        let url = `https://${apps[0].app_domain}?redirect=member&session_id=${btoa(sessionId)}`;

        // let url = "asune nng kene";
        setLoading(false);
        setAuth(true);
        setRedirectTo(url);
        setRedirect(true);
        return;
      }
    }, [apps]);

    // listen login without password
    useEffect(() => {
      const { restData, code } = modulLoginWithoutPassword;
      if (code === 200 && Object.keys(restData).length) {
        const { session_id } = restData;
        if (session_id) {
          setSessionId(session_id);
          localStorage.setItem('session_id', session_id);
        }
      }
    }, [modulLoginWithoutPassword]);

    // listen Login SSO Facebook
    useEffect(() => {
      const { restData, code, params } = modulSsoFacebook;
      const { session_id } = restData;
      if (Object.keys(restData).length && code === 200) {
        setSessionId(session_id);
        localStorage.setItem('session_id', session_id);
        // getAutorization();
        // setLoading(true);
        setParamsUser(params);
      }
    }, [modulSsoFacebook]);

    // listen login form
    useEffect(() => {
      const { restData, code } = modulAuth;
      const { session_id } = restData;
      if (code === 200) {
        // setLoading(true);
        setSessionId(session_id);
        localStorage.setItem('session_id', session_id);
      }
    }, [modulAuth]);

    // listen to sso google
    useEffect(() => {
      const { restData, code } = modulSso;
      const { session_id, profile } = restData;
      if (code === 200) {
        setParamsUser(profile ? profile : '');
        setSessionId(session_id);

        localStorage.setItem('session_id', session_id);
      }
    }, [modulSso]);

    // listen register
    useEffect(() => {
      const { code } = modulProfile;
      const { restData } = modulRegister;
      if (code === 201) {
        const { session_id } = restData;
        setSessionId(session_id);
        localStorage.setItem('session_id', session_id);
      }
    }, [modulProfile]);

    // listen modulSignUp
    useEffect(() => {
      const { restData, codeSendEmail, code } = modulSignUp;
      if (codeSendEmail === 200 && Object.keys(restData).length > 0) {
        const { session_id } = restData;
        setSessionId(session_id);
        localStorage.setItem('session_id', session_id);
      }
      if (code === 200 && Object.keys(restData).length > 0) {
        const { session_id } = restData;
        setSessionId(session_id);
        localStorage.setItem('session_id', session_id);
      }
    }, [modulSignUp]);

    // listen to logout
    const getAutorization = async () => {
      let permision = permission;
      // let roleid = '';
      // ceking redirect is true
      setLoading(true);

      const params = new URLSearchParams(window.location.search); // id=123

      var redirectParam = '';
      try {
        redirectParam = params.get('redirect') ? atob(params.get('redirect')) : ''; // 123
      } catch (error) {
        redirectParam = '';
        console.log('failed encode');
      }
      let urlReq = '';
      let appname = '';

      if (redirectParam !== '') {
        urlReq =
          getUrl(redirectParam) === undefined || !getUrl(redirectParam)
            ? 'https://giftano.com'
            : getUrl(redirectParam);
        appname = getUrl(redirectParam, 1);
        setRedirectTo(urlReq);
        setAppname(appname !== '' ? appname : '');
      }

      // console.log(urlReq);
      // any redirect appname
      if (appname !== '' && sessionId) {
        // get role id
        //
        permision = await manualGetUserInfo({
          session_id: sessionId,
        });

        if (permision.code === 200) {
          setPermission(permision);
          // console.log(permision);
          const { data } = permision;
          const { profiles, user_info } = data;
          setUserInfo(user_info);
          const mineApps = profiles[appname] ? profiles[appname] : {};

          if (Object.keys(profiles).length) {
            if (Object.keys(mineApps).length) {
              // console.log(urlReq);
              if (appname === 'member') {
                let url = `${urlReq + (urlReq.indexOf('?') > -1 ? '&' : '?')}session_id=${btoa(
                  sessionId
                )}`;
                setRedirectTo(url);
                setRedirect(true);
                setLoading(false);
                return setAuth(true);
              } else {
                // profile true and app name not member
                setRedirect(true);
                setLoading(false);
                setAuth(true);
                let goto =
                  urlReq + (urlReq.indexOf('?') > -1 ? '&' : '?') + 'session_id=' + btoa(sessionId);
                setRedirectTo(goto);
                return permision;
              }
            } else {
              if (appname === 'member') {
                let url = `${urlReq + (urlReq.indexOf('?') > -1 ? '&' : '?')}session_id=${btoa(
                  sessionId
                )}`;
                setRedirectTo(url);
                setRedirect(true);
                setLoading(false);
                setAuth(true);
                return permision;
              } else if (appname === 'b2b-dashboard') {
                captureLogSentry('Account Not Found - ' + urlReq + ' - ' + btoa(sessionId) + ' - ');
                setRedirectTo(
                  process.env.REACT_APP_BUILD_MODE === 'PRODUCTION'
                    ? 'https://business.giftano.com/create-account?account=not-found'
                    : 'https://sandbox.b2b-dashboard.pages.dev/create-account?account=not-found'
                );
                setRedirect(true);
                setLoading(false);
                setAuth(true);
                return permision;
              } else if (appname === 'legacy-system') {
                let url = `${urlReq + (urlReq.indexOf('?') > -1 ? '&' : '?')}session_id=${btoa(
                  sessionId
                )}`;
                setRedirect(true);
                setLoading(false);
                setAuth(true);
                setRedirectTo(url);
                return permision;
              }
            }
          } else {
            if (appname === 'member') {
              return getApps();
            } else if (appname === 'b2b-dashboard') {
              captureLogSentry('Account Not Found - ' + urlReq + ' - ' + btoa(sessionId) + ' - ');
              setRedirectTo(
                process.env.REACT_APP_BUILD_MODE === 'PRODUCTION'
                  ? 'https://business.giftano.com/create-account?account=not-found'
                  : 'https://sandbox.b2b-dashboard.pages.dev/create-account?account=not-found'
              );
              setRedirect(true);
              setLoading(false);
              setAuth(true);
              return permision;
            }
          }

          setError(`${"You don't have permission to access "}` + urlReq);
          setAuth(true);
          setLoading(false);
          return permision;
        }

        setAuth(false);
        setLoading(false);
        // console.log('remove session');
        localStorage.removeItem('session_id');
        return;
      }
      // nothing redirect
      else if (sessionId && appname === '') {
        // bisa di sederhanakan - redundant functionalitas
        return getApps();
      }

      // console.log("Clear");
      setAuth(false);
      setLoading(false);
      return permision;
    };

    // get apps
    const getApps = async (message = '') => {
      let permision = await manualGetUserInfo({
        session_id: localStorage.getItem('session_id'),
      });
      // permision = await manualGettinPermission({ session_id: sessionId });
      const { code, data } = permision;
      // var string = 'member,';
      if (code === 200) {
        let legacyAccess = false;

        const { profiles, user_info, update_profile } = data;
        var legacy_metadata = user_info.legacy_metadata ? user_info.legacy_metadata : {};
        var user_level = legacy_metadata.user_level ? legacy_metadata.user_level : null;

        // console.log('testing', update_profile);
        setUpdate_profile(update_profile);
        setUserInfo(user_info);
        if (message !== '' && user_info) {
          const { email } = user_info;
          let emailLog = email ? md5(email) : '';
          captureLogSentry(message + emailLog);
        }
        var additional_access = legacy_metadata.additional_access
          ? legacy_metadata.additional_access
          : ['2'];
        let appLegacy = [];

        if (user_level) {
          additional_access.push(user_level);
        }
        let dataAppSlug = Object.keys(profiles);
        // console.log(dataAppSlug);
        if (dataAppSlug.length > 0) {
          let profileAccess = [];
          let dataApps = [];
          dataAppSlug.forEach((element) => {
            // check profile access
            // console.log(element);
            if (element !== 'legacy-system') {
              profileAccess = profiles[element].profile_accesses
                ? profiles[element].profile_accesses
                : [];

              if (profileAccess) {
                dataApps.push({ ...profiles[element], type: element });
              }
            } else {
              legacyAccess = true;
            }

            // legacy access true and additional access
            if (legacyAccess && additional_access.length) {
              additional_access.map((legacy) => {
                let appLeg = appsLegacy[legacy];

                if (appLeg) {
                  appLeg.map((appL) => {
                    return appLegacy.push({ ...appL, type: appL.slug });
                  });
                }

                return appLegacy;
              });
            }
          });

          var finalAppLegacy = compareAppLegacy(appLegacy);
          var finalApp = dataApps.concat(finalAppLegacy);

          return setApps(finalApp);
        } else {
          let getApps = await manualGettingApplication({ param: 'member' });
          if (getApps.code === 200) {
            setPermission(permision);
            let getAppData = getApps.data.map((item) => {
              return {
                ...item,
                app_domain: item.domain,
              };
            });
            return setApps(getAppData);
          }
        }
      }

      setAuth(false);
      setLoading(false);
      setPermission(permision);

      return permision;
    };

    return (
      <AuthWraper
        {...props}
        store={props.store}
        sessionId={sessionId}
        redirect={redirect}
        redirectTo={redirectTo}
        appName={appName}
        auth={auth}
        apps={apps}
        loading={loading || modulContentStrapi.code === 500}
        codeCase={codeCase}
        redirectBack={redirectBack}
        searchParam={getParam}
        errorMessage={error}
        paramsUser={paramsUser}
        userInfo={userInfo}
        update_profile={update_profile}
      />
    );
  };
  const mapStateToProps = ({
    auth,
    modulSso,
    modulRegister,
    modulProfile,
    modulSsoFacebook,
    modulLoginWithoutPassword,
    modulSignUp,
    modulContentStrapi,
  }) => ({
    modulAuth: auth,
    modulSso: modulSso,
    modulRegister: modulRegister,
    modulProfile: modulProfile,
    modulSsoFacebook: modulSsoFacebook,
    modulLoginWithoutPassword: modulLoginWithoutPassword,
    modulSignUp: modulSignUp,
    modulContentStrapi: modulContentStrapi,
  });

  const mapDispatchToProps = (dispatch) => ({
    __manualPermission: (params) => [dispatch(manualPermission(params))],
    __getDataStrapi: () => [dispatch(__getDataStrapi())],
  });
  return connect(mapStateToProps, mapDispatchToProps)(AuthCore);
};
export default Authenticate;
