// modul for check account

const defaultState = {
	loadingPage: false,
	loadingSend: false,
	is_exist: null,
	code: 500,
	message: "",
};

let response = {};
const Email_lib = (state = defaultState, action = {}) => {
	switch (action.type) {
		case "SUCCESS_EMAIL_AUTH":
			response = {
				...state,
				is_exist: action.data ? action.data : false,
				code: action.code ? action.code : 500,
				loadingPage: false,
				loadingSend: false,
				message: action.message ? action.message : "",
			};

			break;
		case "LOADING_EMAIL_AUTH":
			response = {
				...state,
				code: 500,
				loadingPage: true,
				loadingSend: true,
			};
			break;
		case "ERROR_EMAIL_AUTH":
			response = {
				...state,

				message: action.message ? action.message : "",
				code: action.code ? action.code : 500,
			};
			break;
		default:
			return {
				loadingPage: false,
				loadingSend: false,
				is_exist: null,
				code: 500,
			};
	}

	return response;
};

export default Email_lib;
