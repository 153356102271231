import React, { Component } from "react";
import { connect } from "react-redux";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import { __postDataSso } from "../../modules/Auth";
import { __openModal } from "../../modules/Modals";

export class ModalConfirm extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	clickHandle = () => {
		const { modal } = this.props;
		const { dataModal } = modal;
		this.props.__postDataSso(dataModal);
	};

	render() {
		const { modal } = this.props;
		const { CONFIRM_LOGIN } = modal;
		return (
			<Modal isOpen={CONFIRM_LOGIN}>
				<ModalHeader>Login Confirmation</ModalHeader>
				<ModalBody>
					<h3 className='tex-center'>
						To improve your Giftano experience we suggest switching to a
						preferred email.
					</h3>
					<p className='tex-center'>
						Using a personal email makes it easier to:
					</p>
					<p className='tex-center'>
						- Access your gifts sent and gifts received
					</p>
					<p className='tex-center'>- Access your order history</p>
				</ModalBody>
				<ModalFooter>
					<Button color='primary' onClick={() => this.clickHandle()}>
						Continue
					</Button>
				</ModalFooter>
			</Modal>
		);
	}
}

const mapStateToProps = ({ modal }) => ({
	modal: modal,
});

const mapDispatchToProps = (dispatch) => ({
	__openModal: (params) => [dispatch(__openModal(params))],
	__postDataSso: (params) => [dispatch(__postDataSso(params))],
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalConfirm);
