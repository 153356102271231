const initialState = {
  id: undefined,
  is_loaded: false
};

const localStore = JSON.parse(localStorage.getItem('GiftMembershipSession'));

export const SessionReducer = (state = localStore || initialState, action) => {
  let newState = { ...state };

  switch (action.type) {
    case 'SESSION_SET':
      if (action.session_id) {
        newState.id = action.session_id;
      } // else use local storage
      newState.is_loaded = true;
      localStorage.setItem('GiftMembershipSession', JSON.stringify(newState));
      return newState;
    case 'SESSION_REMOVE':
      localStorage.removeItem('GiftMembershipSession');
      return { ...initialState };
    default:
      return { ...state };
  }
};

export const SessionActions = {
  setSession: (param) => (dispatch) => dispatch({ type: 'SESSION_SET', session_id: param }),
  removeSession: () => (dispatch) => dispatch({ type: 'SESSION_REMOVE' })
};
