import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, CardBody, Row } from 'reactstrap';

export class Error404 extends Component {
  render() {
    return (
      <div className='wrapper-login error-page'>
        <div className='login-container'>
          <Card className='p-2'>
            <CardBody>
              <Row className='mb-3 text-center'>
                <a href='https://giftano.com/' className='mb-5'>
                  <img
                    src='https://giftano.imgix.net/assets/Giftano-Logo-with-text.png?auto=format'
                    className='auth-logo mx-auto d-block'
                    alt={'Giftano logo'}
                  />
                </a>
                <h1 className='error-404'>404</h1>
                <h4 className='error-message'>Page Not Found</h4>
              </Row>
            </CardBody>
          </Card>
        </div>
      </div>
    );
  }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Error404);
