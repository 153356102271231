import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';
import { Button, Form, FormGroup, Label } from 'reactstrap';
import { InputField } from '../../../components';
// import { LoginSso } from "../../../components/loginsso/LoginSso";
import { BsArrowLeft } from 'react-icons/bs';
import { __postSendEmailSignup } from '../../../modules/Signup';

class FormCreateAccount extends Component {
  constructor(props) {
    super(props);
    this.fields = [createRef(), createRef()];
    this.state = {
      data: {
        user: '',
        checked: '',
      },
      step: 1,
    };
  }

  componentDidMount() {
    const { newAccount } = this.props;
    const { user } = newAccount;
    if (user) {
      this.setData();
    }
  }

  componentDidUpdate(prevProps) {
    const { newAccount } = prevProps;
    if (newAccount !== this.props.newAccount) {
      this.setData();
    }
  }

  setData = () => {
    const { newAccount } = this.props;
    const { data } = this.state;
    this.setState((state) => ({
      ...state,
      data: {
        ...data,
        ...newAccount,
      },
    }));
  };

  handleSumit = (e) => {
    e.preventDefault();
    var valid = true;
    var isValid = true;
    let { data } = this.state;

    // trim data
    data.user = data.user.trim();

    for (let index = 0; index < this.fields.length; index++) {
      valid = this.fields[index].current.validate();
      if (!valid) {
        isValid = false;
      }
    }
    if (isValid) {
      //   funtion to send data
      const { user } = data;
      // check email
      // console.log(user);
      this.props.__postSendEmailSignup({ email: user });
    }
  };
  handleChange = (name, value) => {
    const { data } = this.state;
    console.log(value);
    this.setState((state) => ({
      ...state,
      data: {
        ...data,
        [name]: value,
      },
    }));
  };

  handleChangeCheckbox = (name) => {
    const { data } = this.state;
    this.setState((state) => ({
      ...state,
      data: {
        ...data,
        [name]: data.checked === '0' || data.checked === '' ? '1' : '',
      },
    }));
  };
  render() {
    const { data } = this.state;
    const { user, checked } = data;
    const { modulSignUp, composed } = this.props;
    const { loadingSendEmail, codeSendEmail } = modulSignUp;
    const { signup_button_text, title_text_sign_up } = composed;
    return (
      <div>
        <div className=' d-flex justify-content-between align-items-center mt-4 mb-3'>
          <h6 className='btn-back m-0' onClick={() => this.props.callbackPreviousForm(1)}>
            <BsArrowLeft /> Back
          </h6>
        </div>
        <div className=' d-flex justify-content-center'>
          <h4 className='my-3 text-black'>{title_text_sign_up}</h4>
        </div>

        {codeSendEmail === 500 ? (
          <Form onSubmit={this.handleSumit}>
            <FormGroup>
              <InputField
                type='text'
                clasname='login-input'
                placeholder='Enter your email'
                name='user'
                validation='required|match:email'
                value={user}
                message='Please enter your email/username'
                ref={this.fields[0]}
                onChange={this.handleChange}
                label="We could't find any account linked with following email"
                disabled={true}
              />
            </FormGroup>
            <FormGroup>
              <InputField
                type='checkbox'
                clasname='login-input'
                placeholder='Enter your email'
                name='checked'
                validation='required'
                checked={'1'}
                ref={this.fields[1]}
                onChange={this.handleChangeCheckbox}
                label={
                  <Label>
                    {'By signing up, I agree to Giftano`s '}
                    <a
                      href='https://giftano.com/legal#terms-and-conditions'
                      target='_blank'
                      rel='noreferrer'
                      className='text-primary'
                      style={{ textDecoration: 'none' }}
                    >
                      Terms Terms of Service
                    </a>
                    {` and `}
                    <a
                      target='_blank'
                      rel='noreferrer'
                      className='text-primary'
                      style={{ textDecoration: 'none' }}
                      href='https://giftano.com/legal#privacy-policy'
                    >
                      Privacy
                    </a>
                  </Label>
                }
                value={checked}
                message={`Please indicate that you agree with Giftano’s Terms of Service & Privacy Policy.`}
              />
            </FormGroup>
            <FormGroup>
              <Label>Click the following button to create your account with us.</Label>
            </FormGroup>
            <FormGroup className='text-right form-group'>
              <Button
                type='submit'
                color='primary'
                className='w-100 mt-0 btn-login'
                disabled={loadingSendEmail}
              >
                {loadingSendEmail ? 'loading...' : signup_button_text}
              </Button>
            </FormGroup>
          </Form>
        ) : (
          <div>
            <h6 className='text-center'>
              Please check your email, to setup your account or Sign in
            </h6>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ modulSignUp }) => ({
  modulSignUp,
});
const mapDispatchToProps = (dispatch) => ({
  __postSendEmailSignup: (params) => [dispatch(__postSendEmailSignup(params))],
});

export default connect(mapStateToProps, mapDispatchToProps)(FormCreateAccount);
