import { CONNECTION } from "../../config/Connections";
import { CONFIG_ } from "../../config/Config";

var path = process.env.REACT_APP_PATH_AUTH;
var post = "reset-password";
var isLive = false;

export const __postDataValidateKey = (params) => {
  const { action, key } = params;

  let formData = new FormData();
  formData.append("action", action);
  formData.append("key", key);
  return (dispatch) => {
    let config = CONFIG_({
      url: post,
      data: formData,
      method: "POST",
      live: isLive,
      path: path,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
    dispatch({ type: "LOADING_VALIDATEKEY_RESET" });
    CONNECTION(config)
      .then((response) => {
        const { code, message } = response;
        if (code === 200) {
          return dispatch({
            type: "SUCCESS_VALIDATEKEY_RESET",
            message: "Success post data",
            code: code,
          });
        }
        return dispatch({
          type: "ERROR_VALIDATEKEY_RESET",
          message: message,
          code: code,
        });
      })
      .catch((response) => {
        console.log(response);
        return dispatch({
          type: "ERROR_VALIDATEKEY_RESET",
          message: "Network error..",
        });
      });
  };
};
