import React, { useEffect, useState } from 'react';
import { Card, CardBody, Row } from 'reactstrap';
import FormCreateBirthday from './FormCreateBirthday';
import FormCreatePassword from './FormCreatePassword';
import FormCreateGender from './FormCreateGender';
import FormCreateFullname from './FormCreateFullname';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { __postDataValidateLink } from '../../../modules/Signup';
// import { Link } from 'react-router-dom/cjs/react-router-dom';
import moment from 'moment-timezone';

// moment.tz.setDefault('Etc/Utc');
const Index = (props) => {
  const dispatch = useDispatch();
  const modulSignUp = useSelector((state) => state.modulSignUp);
  const { key } = useParams();
  const [role, setRole] = useState(1);
  const { loading, redirect, auth, redirectTo, update_profile } = props;
  const { code } = modulSignUp;

  const expired_date = new URLSearchParams(window.location.search).get('e');

  // console.log(dateString, dateObject);
  const [data, setData] = useState({
    birthday: '',
    first_name: '',
    gender: '',
    last_name: '',
    middle_name: '',
    password: '',
    session_id: '',
    is_new_account: false,
  });

  const isValidDate = (e) => e instanceof Date && !isNaN(e);

  useEffect(() => {
    try {
      const dateString = atob(expired_date);
      const dateObject = new Date(dateString);
      // console.log(moment.utc('2024-01-28T23:00:00+07:00').format('MM DD YYYY H:mm'));
      // console.log(moment.utc(new Date()).format('MM DD YYYY H:mm'));

      // console.log(isValidDate(dateObject));
      if (isValidDate(dateObject)) {
        const isExpireDate = moment.utc(dateString);
        const dateNow = moment.utc(new Date());

        // console.log(dateNow < isExpireDate);

        if (key !== '' && dateNow < isExpireDate) {
          dispatch(__postDataValidateLink({ is_verify: true, key: key }));
        }
      }
    } catch (error) {
      console.log('Something wrong');
    }
  }, [dispatch, key]);

  useEffect(() => {
    const { restData, codeLinkVerify } = modulSignUp;
    if (Object.keys(restData).length > 0 && codeLinkVerify === 200) {
      const { session_id, is_new_account } = restData;
      setData((d) => ({
        ...d,
        session_id: session_id,
        is_new_account: is_new_account,
      }));
    }
  }, [dispatch, modulSignUp]);

  const callbackAndSetData = (params = {}, role = 0) => {
    setData(() => ({
      ...data,
      ...params,
    }));

    setRole(role);
  };

  //   redirect
  if (!loading && auth && redirect && code === 200) {
    return (window.location = redirectTo);
  }

  // console.log(modulSignUp);

  return (
    <div className='wrapper-login'>
      <div className='login-container'>
        <Card className='p-2'>
          <CardBody>
            <Row className='mb-3'>
              <a href='https://giftano.com/'>
                <img
                  src='https://giftano.imgix.net/assets/Giftano-Logo-with-text.png?auto=format'
                  className='auth-logo mx-auto d-block'
                  alt={'Giftano logo'}
                />
              </a>
            </Row>
            {data.session_id !== '' &&
            data.is_new_account &&
            modulSignUp.codeLinkVerify === 200 &&
            update_profile ? (
              <GetLayout
                role={role}
                callbackAndSetData={(params, role) => callbackAndSetData(params, role)}
                dataUser={data}
              />
            ) : (
              <div className='text-center'>
                <h5 className='text-center mt-4'>Your link is expired or invalid.</h5>
                <h6 className='text-muted'>
                  Click <a href='https://account.giftano.com/'>here</a> to access your Giftano
                  Account
                </h6>
              </div>
            )}
          </CardBody>
        </Card>
      </div>
    </div>
  );
};

const GetLayout = (props) => {
  const { role } = props;
  return (
    <>
      {(() => {
        switch (role) {
          case 1:
            return (
              <FormCreatePassword
                callback={(params, role) => props.callbackAndSetData(params, role)}
                dataUser={props.dataUser}
              />
            );
          case 2:
            return (
              <FormCreateFullname
                callback={(params, role) => props.callbackAndSetData(params, role)}
                dataUser={props.dataUser}
              />
            );
          case 3:
            return (
              <FormCreateBirthday
                callback={(params, role) => props.callbackAndSetData(params, role)}
                dataUser={props.dataUser}
              />
            );

          case 4:
            return (
              <FormCreateGender
                callback={(params, role) => props.callbackAndSetData(params, role)}
                dataUser={props.dataUser}
              />
            );

          default:
            return (
              <FormCreatePassword
                callback={(params, role) => props.callbackAndSetData(params, role)}
                dataUser={props.dataUser}
              />
            );
        }
      })()}
    </>
  );
};

export default Index;
