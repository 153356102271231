const defaultState = {
  loadingPage: false,
  loadingSend: false,
  restData: { message: '', modules: [], profile: false, session_id: '', data: {} },
  code: 500,
  message: ''
};

let response = {};
const Permission_lib = (state = defaultState, action = {}) => {
  const { data } = action;

  switch (action.type) {
    case 'SUCCESS_PERMISSION_AUTH':
      response = {
        ...state,
        restData: action.data ? { ...state.restData, ...data } : state.restData,
        code: action.code ? action.code : 500,
        loadingPage: false,
        loadingSend: false,
        message: action.message ? action.message : ''
      };

      break;
    case 'LOADING_PERMISSION_AUTH':
      response = {
        ...state,
        loadingPage: true,
        loadingSend: true
      };
      break;
    case 'ERROR_PERMISSION_AUTH':
      // localStorage.clear()
      response = {
        ...state,
        loadingPage: false,
        loadingSend: false,
        message: action.message ? action.message : '',
        code: action.code ? action.code : 400
      };
      break;
    default:
      return {
        ...state
      };
  }

  return response;
};

export default Permission_lib;
