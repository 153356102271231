const defaultState = {
  loadingPage: true,
  loadingSend: true,
  restData: {},
  code: 500,
  message: '',
  params: '',
};

let response = {};
const Facebook_lib = (state = defaultState, action = {}) => {
  switch (action.type) {
    case 'SUCCESS_FACEBOOK_AUTH':
      response = {
        ...state,
        restData: action.data ? action.data : {},
        params: action.params ? action.params : '',
        code: action.code ? action.code : 500,
        loadingPage: false,
        loadingSend: false,
        message: action.message ? action.message : '',
      };

      break;
    case 'LOADING_FACEBOOK_AUTH':
      response = {
        ...state,
        loadingPage: true,
        loadingSend: true,
      };
      break;
    case 'ERROR_FACEBOOK_AUTH':
      response = {
        ...state,
        message: action.message ? action.message : '',
        code: action.code ? action.code : 500,
        loadingPage: false,
        loadingSend: false,
      };
      break;
    default:
      return {
        ...state,
        loadingPage: false,
        loadingSend: false,
      };
  }

  return response;
};

export default Facebook_lib;
