import { useGoogleLogin } from '@react-oauth/google';
import React from 'react';
import { Button } from 'reactstrap';
import googleicon from '../../../assets/flat-color-icons_google.png';
// import { gapi } from 'gapi-script';

const GoogleOauthApp = (props) => {
  const { disabled, pleaseWait } = props;
  const login = useGoogleLogin({
    onSuccess: (codeResponse) => props.callback(codeResponse),
    flow: 'auth-code',
    scope: 'email',
  });

  // console.log(window.location.href + 'callback')
  return (
    <Button
      type='button'
      id='google'
      disabled={disabled}
      className='btn-google m-0 btn-block btn-outline-dark w-100'
      onClick={() => login()}>
      <img
        src={googleicon}
        alt='Google icon'
      />

      {pleaseWait
        ? 'Loading...'
        : props.label
        ? props.label
        : 'Sign in with Google'}
    </Button>
  );
};

export default GoogleOauthApp;
