import React, { Component } from 'react';
import FacebookLogin from 'react-facebook-login';
import { connect } from 'react-redux';
import { __postDataFacebook } from '../../modules/Auth';
import { FaFacebook } from 'react-icons/fa';
const FACEBOOK_CLIENTID = process.env.REACT_APP_FACEBOOK_ID;

class Facebook extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  reponseFacebook = (response) => {
    // console.log(response);
    if (Object.keys(response).length > 1) {
      const { accessToken, name, picture, email } = response;
      const user = {
        first_name: name,
        avatar: picture.data.url,
        emails: [{ name: name, email: email }],
      };
      const data = {
        token: accessToken,
        type_of_account: 'facebook',
      };
      this.props.__postDataFacebook({
        dataSent: data,
        dataUser: `profile=${btoa(JSON.stringify(user))}`,
      });
    }
  };
  handleClick = (response) => {
    // console.log(response);
    return response;
  };
  render() {
    const { modulSsoFacebook, modulSso } = this.props;
    const { loadingSend } = modulSsoFacebook;
    return (
      <FacebookLogin
        appId={FACEBOOK_CLIENTID}
        callback={(r) => this.reponseFacebook(r)}
        icon={<FaFacebook style={{ marginRight: '.5em' }} />}
        cssClass='btn btn-facebook'
        fields='name,email,picture'
        isDisabled={loadingSend || modulSso.loadingSend}
        // isDisabled={true}
        textButton={`${!loadingSend ? 'Sign in with Facebook' : 'loading...'}`}
      />
    );
  }
}

const mapStateToProps = ({ modulSsoFacebook, modulSso }) => ({
  modulSsoFacebook: modulSsoFacebook,
  modulSso: modulSso,
});

const mapDispatchToProps = (dispatch) => ({
  __postDataFacebook: (params) => [dispatch(__postDataFacebook(params))],
});

Facebook.defaultProps = {
  disabled: true,
};

export default connect(mapStateToProps, mapDispatchToProps)(Facebook);
