import React, { Component, createRef } from 'react'
import { BsArrowLeft } from 'react-icons/bs'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
// import { Link, NavLink } from "react-router-dom";
import { Button, Form, FormGroup } from 'reactstrap'
import { InputField } from '../../../components'
import { __postDataLoginwithoutpassword } from '../../../modules/Auth'

class FormPassword extends Component {
  constructor(props) {
    super(props)
    this.fields = [createRef()]
    this.state = {
      data: {
        password: '',
      },
    }
  }

  componentDidUpdate(prevProps) {
    const { modulLoginWithoutPassword } = this.props
    const { codeSendEmail } = modulLoginWithoutPassword
    const { user } = this.props

    if (
      codeSendEmail !== prevProps.modulLoginWithoutPassword.codeSendEmail &&
      codeSendEmail === 200
    ) {
      return this.props.callbackNextStep({
        name: 'user',
        value: user,
        step: 7,
      })
    }
  }

  handleSubmit = (e) => {
    e.preventDefault()
    var valid = true
    var isValid = true
    let { data } = this.state

    // trim data
    data.password = data.password.trim()

    for (let index = 0; index < this.fields.length; index++) {
      valid = this.fields[index].current.validate()
      if (!valid) {
        isValid = false
      }
    }
    if (isValid) {
      //   funtion to send data
      const { password } = data
      // console.log(password);
      return this.props.callback({
        name: 'password',
        value: password,
        step: 2,
      })
    }
  }
  handleChange = (name, value) => {
    const { data } = this.state
    this.setState((state) => ({
      ...state,
      data: {
        ...data,
        [name]: value,
      },
    }))
  }

  handleSendEmail = () => {
    const { user } = this.props
    this.props.__postDataLoginwithoutpassword({ email: user })
  }

  render() {
    const { data } = this.state
    const { password } = data
    const { modulAuth, modulLoginWithoutPassword } = this.props
    const { loadingSend } = modulAuth
    const { loadingSendEmail } = modulLoginWithoutPassword
    return (
      <div>
        <div className=" d-flex justify-content-between align-items-center mt-4 mb-3">
          <h6
            className="font-weight-600 m-0 btn-back"
            onClick={() => this.props.callbackPreviousForm(1)}
          >
            <BsArrowLeft /> Back
          </h6>
        </div>
        <div className=" d-flex justify-content-center">
          <h4 className="my-3 text-black">Enter your password!</h4>
        </div>

        <Form className="mb-0" onSubmit={this.handleSubmit}>
          <FormGroup>
            <InputField
              type="password"
              classname="login-input mb-0"
              placeholder="Enter your password"
              name="password"
              validation="required"
              value={password}
              message="Please enter your password"
              ref={this.fields[0]}
              onChange={this.handleChange}
              onFocus={true}
              label=""
            />
          </FormGroup>
          <div className="d-flex justify-content-end align-items-center">
            <h6 className="p-0 mt-0 m-0 ">
              <Link
                onClick={() => this.props.callbackPreviousForm(8)}
                className=" fs-14 nav-link mr-0 mb-0 pr-1 text-right text-primary btn-forgot-password"
                to={"#"}
              >
                Forgot Password?
              </Link>
            </h6>
          </div>

          <FormGroup className="text-right form-group">
            <Button
              type="submit"
              color="primary"
              disabled={loadingSend || loadingSendEmail}
              className="w-100 mt-3 btn-login"
            >
              {loadingSend || loadingSendEmail ? 'loading...' : 'Sign in'}
            </Button>
          </FormGroup>
        </Form>
        <FormGroup className="text-center form-group">
          <Button
            type="button"
            color="primary"
            size="sm"
            outline
            className="w-100 mt-0 mx-auto btn-login"
            disabled={loadingSendEmail}
            onClick={() => this.handleSendEmail()}
          >
            {loadingSendEmail ? 'loading...' : 'Sign in with OTP'}
          </Button>
        </FormGroup>
      </div>
    )
  }
}

const mapStateToProps = ({ auth, modulLoginWithoutPassword }) => ({
  modulAuth: auth,
  modulLoginWithoutPassword: modulLoginWithoutPassword,
})

const mapDispatchToProps = (dispatch) => ({
  __postDataLoginwithoutpassword: (params) => [
    dispatch(__postDataLoginwithoutpassword(params)),
  ],
})

export default connect(mapStateToProps, mapDispatchToProps)(FormPassword)
