import { CONNECTION } from '../../config/Connections';
import { CONFIG_ } from '../../config/Config';

var path = process.env.REACT_APP_PATH_AUTH;
var get = '';
var post = 'login-sso';
// var isLive = true;
export const __getDataFacebook = () => {
  return (dispatch) => {
    let config = CONFIG_({ url: get, method: 'GET', path: path });
    dispatch({ type: 'LOADING_FACEBOOK_AUTH' });
    CONNECTION(config)
      .then((response) => {
        const { code, data } = response;
        if (code === 200) {
          return dispatch({
            type: 'SUCCESS_FACEBOOK_AUTH',
            message: 'Success get data',
            data: data,
          });
        }
        return dispatch({ type: 'ERROR_FACEBOOK_AUTH', message: 'failed' });
      })
      .catch((response) => {
        // console.log(response);
        return dispatch({
          type: 'ERROR_FACEBOOK_AUTH',
          message: 'Network error..',
          errors: response,
        });
      });
  };
};

export const __postDataFacebook = (params) => {
  return (dispatch) => {
    const { dataSent, dataUser } = params;
    let config = CONFIG_({
      method: 'POST',
      data: dataSent,
      path: path,
      url: post,
    });
    dispatch({ type: 'LOADING_FACEBOOK_AUTH' });
    CONNECTION(config)
      .then((response) => {
        const { code, data } = response;
        if (code === 200) {
          return dispatch({
            type: 'SUCCESS_FACEBOOK_AUTH',
            message: 'Success post data',
            data: data,
            code: 200,
            params: dataUser,
          });
        }

        return dispatch({ type: 'ERROR_FACEBOOK_AUTH', message: 'failed' });
      })
      .catch((response) => {
        // console.log(response);
        return dispatch({
          type: 'ERROR_FACEBOOK_AUTH',
          message: 'Network error..',
          errors: response,
        });
      });
  };
};
