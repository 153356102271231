const defaultState = {
  loadingPage: true,
  loadingSetupaccount: false,
  loadingSendEmail: true,
  loadingLinkVerify: true,
  restData: {},
  code: 500,
  codeSendEmail: 500,
  codeLinkVerify: 500,
  message: '',
};

let response = {};
const Signup_lib = (state = defaultState, action = {}) => {
  switch (action.type) {
    case 'SUCCESS_SIGNUPLINK':
      response = {
        ...state,
        restData: action.data ? action.data : {},
        codeLinkVerify: action.code ? action.code : 500,
        loadingPage: false,
        loadingLinkVerify: false,
        loadingSetupaccount: false,
        message: action.message ? action.message : '',
      };
      break;
    case 'SUCCESS_SIGNUP':
      response = {
        ...state,
        restData: action.data ? action.data : {},
        code: action.code ? action.code : 500,
        loadingPage: false,
        loadingSendEmail: false,
        loadingSetupaccount: false,
        message: action.message ? action.message : '',
      };

      break;
    case 'SUCCESS_SIGNUPSENDEMAIL':
      response = {
        ...state,
        restData: action.data ? action.data : {},
        codeSendEmail: action.code ? action.code : 500,
        loadingPage: false,
        loadingSendEmail: false,
        message: action.message ? action.message : '',
      };

      break;

    case 'LOADING_SIGNUP':
      response = {
        ...state,
        loadingPage: true,
        loadingSendEmail: true,
        loadingSetupaccount: true,
      };
      break;
    case 'ERROR_SIGNUP':
      response = {
        ...state,
        message: action.message ? action.message : '',
        code: action.code ? action.code : 500,
      };
      break;
    case 'RESET_SIGNUP':
      response = {
        ...state,
        codeSendEmail: 500,
        message: action.message ? action.message : '',
        code: action.code ? action.code : 500,
      };
      break;
    default:
      return {
        ...state,
        codeSendEmail: 500,
        loadingPage: true,
        loadingSendEmail: false,
      };
  }

  return response;
};

export default Signup_lib;
