import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';
import { Button, Form, FormGroup, FormText, Label } from 'reactstrap';
import { InputField } from '../../../components';
// import { LoginSso } from "../../../components/loginsso/LoginSso";
import { __postSignUpNewAccount } from '../../../modules/Signup';

class FormCreateGender extends Component {
  constructor(props) {
    super(props);
    this.fields = [createRef()];
    this.state = {
      data: {
        user: '',
        tou: '',
      },
      error: '',
    };
  }

  handleSumit = (e) => {
    e.preventDefault();
    var valid = true;
    var isValid = true;
    const { dataUser } = this.props;
    let { data } = this.state;
    const { user } = data;
    // trim data

    for (let index = 0; index < this.fields.length; index++) {
      valid = this.fields[index].current.validate();
      if (!valid) {
        isValid = false;
      }
    }

    if (user === '') {
      this.setState((state) => ({
        ...state,
        error: 'Please select your gender',
      }));
      isValid = false;

      return;
    }

    if (isValid) {
      //   funtion to send data
      this.setState((state) => ({
        ...state,
        error: '',
      }));
      const { user } = data;

      let dataSent = {
        ...dataUser,
        gender: user,
      };

      this.props.__postSignUpNewAccount(dataSent);
    }
  };
  handleChange = (name, value) => {
    const { data } = this.state;
    this.setState((state) => ({
      ...state,
      data: {
        ...data,
        [name]: value,
      },
    }));
  };
  render() {
    const { data, error } = this.state;
    const { user } = data;
    const { modulSignUp } = this.props;
    const { loadingSetupaccount } = modulSignUp;
    return (
      <div>
        <div className=' d-flex justify-content-center'>
          <h4 className='my-3 text-black'>You are almost there! </h4>
        </div>
        <div className=' d-flex justify-content-center'>
          <Label className='my-3 form-subtitle'>What is your gender?</Label>
        </div>
        <Form onSubmit={this.handleSumit}>
          <FormGroup className='d-flex justify-content-center '>
            <InputField
              type='radio'
              clasname='login-input'
              name='user'
              validation='required'
              value='male'
              message='Please enter your email/username'
              ref={this.fields[0]}
              onChange={this.handleChange}
              label='Male'
              checked={user}
            />
            <InputField
              type='radio'
              clasname='login-input'
              name='user'
              validation='required'
              value='female'
              message='Please enter your email/username'
              ref={this.fields[0]}
              onChange={this.handleChange}
              label='Female'
              checked={user}
            />
            <InputField
              type='radio'
              clasname='login-input'
              name='user'
              validation='required'
              value='not-to-say'
              message='Please enter your email/username'
              ref={this.fields[0]}
              onChange={this.handleChange}
              label='Prefer not to say'
              checked={user}
            />
          </FormGroup>

          <h6>
            {'By clicking "Create Account", you aggree to Giftano'}
            <a href='https://giftano.com/docs/Terms.pdf'>Terms of Use</a>.
          </h6>

          <h6 className='mt-4'>
            To learn more about how Giftano Collects, uses, shares and protects your personal data,
            pelase ses our
            <a href='https://giftano.com/docs/privacy-policy.pdf'>Privacy Policy</a>
          </h6>

          <FormGroup>
            <FormText color='danger'>{error}</FormText>
          </FormGroup>

          <FormGroup className='text-right form-group'>
            <Button
              type='submit'
              color='primary'
              className='w-100 mt-3 btn-login'
              disabled={loadingSetupaccount}
            >
              {!loadingSetupaccount ? 'Create Account' : 'loading...'}
            </Button>
          </FormGroup>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = ({ modulSignUp }) => ({
  modulSignUp,
});

const mapDispatchToProps = (dispatch) => ({
  __postSignUpNewAccount: (params) => [dispatch(__postSignUpNewAccount(params))],
});

export default connect(mapStateToProps, mapDispatchToProps)(FormCreateGender);
