import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
export default class Datepicker extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  handleChange = (data) => {
    // const { target } = data;
    // const { name, value } = target;
    this.props.onCallback(data);
  };

  render() {
    const { value, disabled, name } = this.props;
    return (
      <div>
        <TextField
          id="date-local"
          type="date"
          name={name}
          style={{ width: '100%', padding: 0 }}
          defaultValue={value}
          InputLabelProps={{
            shrink: true
          }}
          onChange={this.handleChange}
          disabled={disabled}
          format={'DD/MM/YYYY'}
        />
      </div>
    );
  }
}

Datepicker.propTypes = {
  value: PropTypes.string,
  disabled: PropTypes.bool,
  name: PropTypes.string,
  onCallback: PropTypes.func
};

Datepicker.defaultProps = {
  value: '',
  disabled: false,
  name: '',
  onCallback: () => {}
};
