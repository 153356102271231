const defaultState = {
  loadingPage: true,
  loadingSend: true,
  restData: [],
  code: 500,
  message: ''
};

let response = {};
const Register_lib = (state = defaultState, action = {}) => {
  const { data } = action;

  switch (action.type) {
    case 'SUCCESS_REGISTER':
      if (data) {
        _setAdmin('user_status', action.code);
        _setAdmin('session_id', data.session_id);
      }
      response = {
        ...state,
        restData: action.data ? action.data : {},
        code: action.code ? action.code : 500,
        loadingPage: false,
        loadingSend: false,
        message: action.message ? action.message : ''
      };

      break;
    case 'LOADING_REGISTER':
      response = {
        code: action.code ? action.code : 500,
        loadingPage: true,
        loadingSend: true
      };
      break;
    case 'ERROR_REGISTER':
      response = {
        ...state,
        loadingPage: false,
        loadingSend: false,
        message: action.message ? action.message : '',
        code: action.code ? action.code : 500
      };
      break;
    default:
      return {
        ...state,
        loadingPage: false,
        loadingSend: false
      };
  }

  return response;
};
const _setAdmin = (key, value) => {
  return localStorage.setItem(key, value);
};

export default Register_lib;
