import React, { Component } from 'react';
import PhoneInput from 'react-phone-input-2';

export default class ReactInputPhone extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleChange = (value) => {
    // console.log(value);
    return value;
  };
  render() {
    return (
      <div>
        <PhoneInput country="sg" onChange={this.handleChange} />
      </div>
    );
  }
}
