import React, { Component, createRef } from 'react';
import { BsArrowLeft } from 'react-icons/bs';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
// import { NavLink } from "react-router-dom";
import { Button, Form, FormGroup } from 'reactstrap';
import { InputField } from '../../../components';
import { hideemail } from '../../../config/Helper';
import {
  __postDataLoginwithoutpassword,
  __postVerifyLoginwithoutpassword,
} from '../../../modules/Auth';

class FormVerifyCode extends Component {
  constructor(props) {
    super(props);
    this.fields = [
      createRef(),
      createRef(),
      createRef(),
      createRef(),
      createRef(),
      createRef(),
    ];
    this.state = {
      data: {
        satu: '',
        dua: '',
        tiga: '',
        empat: '',
        lima: '',
        enam: '',
      },
    };

    this.handleChange = this.handleChange.bind(this);
  }

  handleSubmit = (e) => {
    e.preventDefault();
    var valid = true;
    var isValid = true;
    let { data } = this.state;

    // trim data

    for (let index = 0; index < this.fields.length; index++) {
      valid = this.fields[index].current.validate();
      if (!valid) {
        isValid = false;
      }
    }
    if (isValid) {
      //   funtion to send data
      const { satu, dua, tiga, empat, lima, enam } = data;
      let key = `${satu}${dua}${tiga}${empat}${lima}${enam}`;
      const { user } = this.props;
      this.props.__postVerifyLoginwithoutpassword({
        email: user,
        key: key.toLocaleUpperCase(),
        is_verify: true,
      });
    }
  };
  handleChange = (name, value, e, props, isPaste) => {
    // console.log(isPaste);
    if (!isPaste) {
      var desc = e.target.parentNode.nextSibling;
      var val = value.length ? value : '';
      if (val.length > 1) {
        val = val.slice(-1);
      }
      if (desc !== null && val.length === 1) {
        desc.firstChild.focus();
      }

      if (val !== '') {
        const { data } = this.state;
        this.setState((state) => ({
          ...state,
          data: {
            ...data,
            [name]: val ? val.toLocaleUpperCase() : '',
          },
        }));
      }
    }
  };

  handleResendEmail = () => {
    const { user } = this.props;
    this.props.__postDataLoginwithoutpassword({ email: user });
  };

  handlePaste = (name, value, e, props, isPaste) => {
    if (isPaste) {
      this.setState((state) => ({
        ...state,
        data: {
          satu: value[0] ? value[0].toLocaleUpperCase() : '',
          dua: value[1] ? value[1].toLocaleUpperCase() : '',
          tiga: value[2] ? value[2].toLocaleUpperCase() : '',
          empat: value[3] ? value[3].toLocaleUpperCase() : '',
          lima: value[4] ? value[4].toLocaleUpperCase() : '',
          enam: value[5] ? value[5].toLocaleUpperCase() : '',
        },
      }));
    }
  };

  render() {
    const { data } = this.state;
    const { user } = this.props;
    const { modulLoginWithoutPassword } = this.props;
    const { loadingVerify, loadingSendEmail } = modulLoginWithoutPassword;
    return (
      <div>
        <div className=' d-flex justify-content-between align-items-center mt-4 mb-3'>
          <h6
            className='font-weight-600 m-0 btn-back'
            onClick={() => this.props.callbackPreviousForm(0)}
          >
            <BsArrowLeft /> Back
          </h6>
        </div>
        <div className=' d-flex justify-content-center'>
          <h4 className='my-3 text-black'>OTP Verification </h4>
        </div>
        <div className=' d-flex justify-content-center text-center'>
          <p>
            Please enter the verification code sent to the email address
            {` ${hideemail(user)}`}
          </p>
        </div>
        <Form className='form-input-otp' onSubmit={this.handleSubmit}>
          <div className='d-flex d-block mx-auto otp-container'>
            <FormGroup>
              <InputField
                type='text'
                clasname='login-input'
                name='satu'
                validation='required'
                defaultValue={data.satu}
                autoFocus={true}
                message='Please enter your password'
                ref={this.fields[0]}
                onChange={this.handleChange}
                errorMessage={false}
                useDefaultValue={true}
                onPaste={this.handlePaste}
                onFocus={true}
              />
            </FormGroup>
            <FormGroup className='px-2'>
              <InputField
                type='text'
                clasname='login-input'
                name='dua'
                validation='required'
                defaultValue={data.dua}
                ref={this.fields[1]}
                errorMessage={false}
                useDefaultValue={true}
                onPaste={this.handlePaste}
                onChange={this.handleChange}
              />
            </FormGroup>
            <FormGroup className='px-2'>
              <InputField
                type='text'
                clasname='login-input'
                name='tiga'
                validation='required'
                defaultValue={data.tiga}
                ref={this.fields[2]}
                errorMessage={false}
                useDefaultValue={true}
                onChange={this.handleChange}
              />
            </FormGroup>
            <FormGroup className='px-2'>
              <InputField
                type='text'
                clasname='login-input'
                name='empat'
                validation='required'
                defaultValue={data.empat}
                ref={this.fields[3]}
                errorMessage={false}
                useDefaultValue={true}
                onChange={this.handleChange}
              />
            </FormGroup>
            <FormGroup className='px-2'>
              <InputField
                type='text'
                clasname='login-input'
                name='lima'
                validation='required'
                defaultValue={data.lima}
                ref={this.fields[4]}
                errorMessage={false}
                useDefaultValue={true}
                onChange={this.handleChange}
              />
            </FormGroup>
            <FormGroup className='px-2'>
              <InputField
                type='text'
                clasname='login-input'
                name='enam'
                validation='required'
                defaultValue={data.enam}
                ref={this.fields[5]}
                errorMessage={false}
                useDefaultValue={true}
                onChange={this.handleChange}
              />
            </FormGroup>
          </div>

          <FormGroup className='text-right form-group'>
            <Button
              type='submit'
              color='primary'
              disabled={loadingVerify}
              className='w-100 mt-3 btn-login'
            >
              {loadingVerify ? 'loading...' : 'Verify'}
            </Button>
          </FormGroup>
        </Form>
        <div className=' text-center mt-3'>
          <p className='mb-0'>{`Didn't receive the OTP?`}</p>
          <Link
            disabled={loadingSendEmail || loadingVerify}
            className='text-primary nav-link mt-0'
            onClick={() => this.handleResendEmail()}
            to='#'
          >
            {loadingSendEmail ? 'loading..' : ' Resend Code'}
          </Link>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ auth, modulLoginWithoutPassword }) => ({
  modulAuth: auth,
  modulLoginWithoutPassword: modulLoginWithoutPassword,
});

const mapDispatchToProps = (dispatch) => ({
  __postVerifyLoginwithoutpassword: (params) => [
    dispatch(__postVerifyLoginwithoutpassword(params)),
  ],
  __postDataLoginwithoutpassword: (params) => [
    dispatch(__postDataLoginwithoutpassword(params)),
  ],
});

export default connect(mapStateToProps, mapDispatchToProps)(FormVerifyCode);
