import { CONNECTION } from '../../config/Connections';
import { CONFIG_ } from '../../config/Config';
import { __postDataProfile } from '../Profile/Profile_act';
import { notifWarning } from '../../components';

let path = process.env.REACT_APP_PATH_AUTH;
// var get = "register";
var post = 'register';
var isLive = true;
var port = 8080;

export const __postDataRegister = (params) => {
  const {
    confirm_password,
    password,
    username,
    firstname,
    lastname,
    phone_number,
    dial_code,
    country,
    birthday
  } = params;
  const dataRegister = {
    confirm_password: confirm_password,
    email: username,
    full_name: firstname + ' ' + lastname,
    is_member: true,
    password: password,
    role_id: '',
    username: username,
    phone_number: phone_number,
    created_by: '1',
    dial_code: dial_code,
    country_code: country
  };

  const dataMember = {
    addresses: [],
    avatar: '',
    birthday: birthday,
    dates: [
      {
        date: birthday,
        notes: 'My Birthday',
        title: 'My Birthday'
      }
    ],
    emails: [
      {
        email: username,
        name: firstname + ' ' + lastname,
        is_primary: true
      }
    ],
    first_name: firstname,
    gender: '',
    last_name: lastname,
    middle_name: '',
    phone_numbers: [
      {
        country_code: country,
        dial_code: dial_code,
        phone_number: phone_number,
        is_primary: true
      }
    ],
    public_id: '',
    user_id: ''
  };
  return (dispatch) => {
    let config = CONFIG_({
      url: post,
      data: dataRegister,
      method: 'POST',
      live: isLive,
      port: port,
      path: path
    });
    dispatch({ type: 'LOADING_REGISTER' });
    CONNECTION(config)
      .then((response) => {
        const { code, data, error } = response;
        if (code === 200) {
          const { ok } = data;
          dispatch({
            type: 'SUCCESS_REGISTER',
            code: code,
            data: { session_id: ok }
          });
          return dispatch(
            __postDataProfile({
              data: dataMember,
              session_id: ok
            })
          );
        }
        notifWarning(error ? error : 'Register failed');
        return dispatch({ type: 'ERROR_REGISTER', message: 'failed' });
      })
      .catch((response) => {
        console.log(response);
        return dispatch({ type: 'ERROR_REGISTER', message: 'Network error..' });
      });
  };
};
