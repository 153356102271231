const defaultState = {
  loadingPage: true,
  loadingSend: true,
  restData: {},
  code: 500,
  message: "",
};

let response = {};
const Sso_lib = (state = defaultState, action = {}) => {
  const { data } = action;

  switch (action.type) {
    case "SUCCESS_SSO_AUTH":
      if (data) {
        _setAdmin("user_status", action.code);
        _setAdmin("session_id", data.session_id);
        // _setAdmin("user", JSON.stringify(data.user));
      }
      response = {
        ...state,
        restData: action.data ? action.data : {},
        code: action.code ? action.code : 500,
        loadingPage: false,
        loadingSend: false,
        message: action.message ? action.message : "",
      };

      break;
    case "LOADING_SSO_AUTH":
      response = {
        ...state,
        loadingPage: true,
        loadingSend: true,
      };
      break;
    case "ERROR_SSO_AUTH":
      response = {
        ...state,
        message: action.message ? action.message : "",
        code: action.code ? action.code : 500,
      };
      break;
    default:
      return {
        ...state,
        loadingPage: false,
        loadingSend: false,
      };
  }

  return response;
};

const _setAdmin = (key, value) => {
  return localStorage.setItem(key, value);
};

// const _getLocalStorage = (key) => {
//   return localStorage.getItem(key);
// };

export default Sso_lib;
