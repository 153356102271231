import { captureMessage } from '@sentry/react';

const APPMODE = process.env.REACT_APP_BUILD_MODE;
export const captureLogSentry = (message) => {
  // console.log(message);

  if (APPMODE !== 'local') {
    try {
      captureMessage(message);
    } catch (error) {
      console.error('logging error');
    }
  }
};
