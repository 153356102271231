// IDEA: Toast library
// IDEA: https://jossmac.github.io/react-toast-notifications/

import { toast, Slide } from 'react-toastify'
// import 'react-toastify/dist/ReactToastify.css'
toast.configure()

export const notifSuccess = (message) => {
  toast.info(message, {
    position: toast.POSITION.TOP_RIGHT,
    transition: Slide,
    icon:false
  })
}

export const notifDetault = (message) => {
  toast(message, {
    position: 'top-right',
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    transition: Slide,
  })
}

export const notifWarning = (message) => {
  toast.warning(message, {
    position: toast.POSITION.TOP_RIGHT,
    transition: Slide,
    icon:false

  })
}
export const notifDanger = (message) => {
  toast.error(message, {
    position: 'top-right',
    transition: Slide,
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    icon:false

  })
}
