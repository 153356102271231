import { CatchError } from '../CatchError';
import { GetAllTransactions, GetAllTransactionsNotLinked } from './OrdersCRUD';

const initialState = {
  data: [],
  data_not_linked: [],
  is_loading: false,
  is_loading_not_linked: false,
  is_loaded: false,
  is_loaded_not_linked: false
};

const actionTypes = {
  SET_DATA: 'ORDERS_SET_DATA',
  SET_DATA_NOT_LINKED: 'ORDERS_SET_DATA_NOT_LINKED',
  START_LOADING: 'ORDERS_START_LOADING',
  START_LOADING_NOT_LINKED: 'ORDERS_START_LOADING_NOT_LINKED',
  STOP_LOADING: 'ORDERS_STOP_LOADING',
  STOP_LOADING_NOT_LINKED: 'ORDERS_STOP_LOADING_NOT_LINKED'
};

export const OrdersReducers = (state = initialState, action) => {
  let newState = { ...state };
  switch (action.type) {
    case actionTypes.SET_DATA:
      newState.data = action.data || [];
      newState.is_loaded = true;
      break;
    case actionTypes.SET_DATA_NOT_LINKED:
      newState.data_not_linked = action.data || [];
      newState.is_loaded_not_linked = true;
      break;
    case actionTypes.START_LOADING:
      newState.is_loading = true;
      break;
    case actionTypes.START_LOADING_NOT_LINKED:
      newState.is_loading_not_linked = true;
      break;
    case actionTypes.STOP_LOADING:
      newState.is_loading = false;
      break;
    case actionTypes.STOP_LOADING_NOT_LINKED:
      newState.is_loading_not_linked = false;
      break;
    default:
  }
  return newState;
};

export const OrdersActions = {
  loadOrders: (member_id) => async (dispatch) => {
    dispatch({ type: actionTypes.START_LOADING });
    try {
      let res = await GetAllTransactions(member_id);
      if (res.status === 1) {
        //convert data to array
        let data = Object.keys(res.data).map((key) => {
          return {
            ...res.data[key],
            _id: key
          };
        });
        // console.log('data ret order', data, res);
        dispatch({ type: actionTypes.SET_DATA, data: data });
      }
    } catch (err) {
      CatchError(err);
    }
    dispatch({ type: actionTypes.STOP_LOADING });
  },
  loadOrdersNotLinked: (session_id) => async (dispatch) => {
    dispatch({ type: actionTypes.START_LOADING_NOT_LINKED });
    try {
      let res = await GetAllTransactionsNotLinked(session_id);
      if (res.status === 1) {
        //convert data to array
        let data = Object.keys(res.data).map((key) => {
          return {
            ...res.data[key],
            _id: key
          };
        });
        // console.log('data ret order not linked', data, res);
        dispatch({ type: actionTypes.SET_DATA_NOT_LINKED, data: data });
      }
    } catch (err) {
      CatchError(err);
    }
    dispatch({ type: actionTypes.STOP_LOADING_NOT_LINKED });
  }
};
