import React, { Component, createRef } from 'react';
import { Button, Form, FormGroup, Label } from 'reactstrap';
import { InputField } from '../../../components';
// import { LoginSso } from "../../../components/loginsso/LoginSso";

export default class FormEmail extends Component {
  constructor(props) {
    super(props);
    this.fields = [createRef()];
    this.state = {
      data: {
        user: ''
      },
      step: 1
    };
  }

  handleSumit = (e) => {
    e.preventDefault();
    var valid = true;
    var isValid = true;
    let { data } = this.state;

    // trim data
    data.user = data.user.trim();

    for (let index = 0; index < this.fields.length; index++) {
      valid = this.fields[index].current.validate();
      if (!valid) {
        isValid = false;
      }
    }
    if (isValid) {
      //   funtion to send data
      const { user } = data;
      // check email
      return this.props.callback({ name: 'user', value: user, step: 5 });
    }
  };
  handleChange = (name, value) => {
    const { data } = this.state;
    this.setState((state) => ({
      ...state,
      data: {
        ...data,
        [name]: value
      }
    }));
  };
  render() {
    const { data } = this.state;
    const { user } = data;
    return (
      <div>
        <div className=" d-flex justify-content-center">
          <h4 className="my-3 text-black">Setting up your account</h4>
        </div>
        <div className=" d-flex justify-content-center">
          <Label className="my-3 form-subtitle">What is your gender?</Label>
        </div>
        <Form onSubmit={this.handleSumit}>
          <FormGroup className="d-flex justify-content-center ">
            <InputField
              type="radio"
              clasname="login-input"
              name="user"
              validation="required"
              value={user}
              message="Please enter your email/username"
              ref={this.fields[0]}
              onChange={this.handleChange}
              label="Male"
            />
            <InputField
              type="radio"
              clasname="login-input"
              name="user"
              validation="required"
              value={user}
              message="Please enter your email/username"
              ref={this.fields[0]}
              onChange={this.handleChange}
              label="Famale"
            />
            <InputField
              type="radio"
              clasname="login-input"
              name="user"
              validation="required"
              value={user}
              message="Please enter your email/username"
              ref={this.fields[0]}
              onChange={this.handleChange}
              label="Prefer not to say"
            />
          </FormGroup>

          <h6>{' By clicking "Create Account", you aggree to Giftano Terms of Use.'}</h6>
          <a href="#">Terms of Use</a>
          <h6 className="mt-4">
            To learn more about how Giftano Collects, uses, shares and protects your personal data,
            pelase ses our Privacy Policy
          </h6>
          <a href="#">Terms of Use</a>
          <InputField
            type="radio"
            clasname="login-input"
            name="user"
            validation="required"
            value={user}
            message="Please enter your email/username"
            ref={this.fields[0]}
            onChange={this.handleChange}
            label="I would prefer not to receive marketing message form Giftano"
          />

          <FormGroup className="text-right form-group">
            <Button type="submit" color="primary" className="w-100 mt-3 btn-login">
              Create Account
            </Button>
          </FormGroup>
        </Form>
      </div>
    );
  }
}
