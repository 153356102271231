import { combineReducers } from "redux";

import { modulTest } from "./Testing";
import {
  modulAuth,
  modulPermission,
  modulSso,
  modulSession,
  modulSsoFacebook,
  modulCheckEmail,
  modulLoginWithoutPassword,
  modulUserInfo,
} from "./Auth";
import { GiftReducer } from "./Gift/GiftRedux";
import { OrdersReducers } from "./Orders/OrdersRedux";
import { ContactReducers } from "./Contact/ContactRedux";
import { ProfileReducer } from "./Profile/ProfileRedux";
import { modulRegister } from "./Register";
import { SessionReducer } from "./Session/SessionRedux";
import { modulProfile } from "./Profile";
import { modulCountry } from "./Country";
import { memberVerify } from "./Member";
import { modulResetPassword, modulValidateKey } from "./Reset";
import { modulLogout } from "./Logout";
import { PaymentReducer } from "./Payment/PaymentRedux";
import { modulApp, modulGoToApp } from "./App";
import { modulRecaptcha } from "./Verify";
import { modulSample } from "./Sample";
import { modulModal } from "./Modals";
import { modulSignUp } from "./Signup";
import { modulContentStrapi } from "./Content";

export const rootReducer = combineReducers({
  test: modulTest,
  auth: modulAuth,
  gift: GiftReducer,
  orders: OrdersReducers,
  contact: ContactReducers,
  profile: ProfileReducer,
  modulRegister: modulRegister,
  session: SessionReducer,
  modulPermission: modulPermission,
  modulProfile: modulProfile,
  modulCountry: modulCountry,
  modulSso: modulSso,
  memberVerify: memberVerify,
  modulResetPassword: modulResetPassword,
  modulValidateKey: modulValidateKey,
  modulLogout: modulLogout,
  payment: PaymentReducer,
  modulSession: modulSession,
  modulApp: modulApp,
  modulRecaptcha: modulRecaptcha,
  modulGoToApp: modulGoToApp,
  modulSample: modulSample,
  modulSsoFacebook: modulSsoFacebook,
  modal: modulModal,
  modulCheckEmail: modulCheckEmail,
  modulLoginWithoutPassword: modulLoginWithoutPassword,
  modulSignUp: modulSignUp,
  modulUserInfo: modulUserInfo,
  modulContentStrapi: modulContentStrapi,
});
