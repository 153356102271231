import React, { useEffect, useState } from 'react';
import { Card, CardBody, Row } from 'reactstrap';
import FormCreateBirthday from './FormCreateBirthday';
import FormCreatePassword from './FormCreatePassword';
import FormCreateGender from './FormCreateGender';
import FormCreateFullname from './FormCreateFullname';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { __getDataInfo } from '../../../modules/Auth';
const Index = (props) => {
  const dispatch = useDispatch();
  const modulUserInfo = useSelector((state) => state.modulUserInfo);
  const modulSignUp = useSelector((state) => state.modulSignUp);

  const { key } = useParams();
  const parmas = new URLSearchParams();
  console.log(parmas);
  const [role, setRole] = useState(1);
  const { loading, redirect, auth, redirectTo, apps } = props;
  const { code } = modulSignUp;
  const [casess, setCasess] = useState({
    change_password: false,
    update_profile: false,
  });
  const [data, setData] = useState({
    birthday: '',
    first_name: '',
    gender: '',
    last_name: '',
    middle_name: '',
    session_id: '',
    is_new_account: false,
  });

  useEffect(() => {
    if (key) {
      //clear local storage
      localStorage.clear();
      // validate session id
      dispatch(__getDataInfo(atob(key)));
    }
  }, [dispatch, key]);

  useEffect(() => {
    const { restData, code } = modulUserInfo;
    // console.log(modulUserInfo);
    if (code === 200 && key) {
      const { profiles, change_password, update_profile } = restData;
      const { member } = profiles;
      // console.log('kesini lagi');

      if (member) {
        setCasess({
          change_password: change_password,
          update_profile: update_profile,
        });
        return setData((d) => ({
          ...d,
          is_new_account: false,
          middle_name: member.middle_name,
          first_name: member.first_name,
          last_name: member.last_name,
          birthday: member.birthday,
          gender: member.gender,
          session_id: atob(key),
        }));
      }

      return setData((d) => ({
        ...d,
        is_new_account: true,
        session_id: atob(key),
      }));
    }
  }, [dispatch, modulUserInfo, key]);

  useEffect(() => {
    if (!casess.change_password && casess.update_profile && role <= 2) {
      // console.log('rene');
      setRole(2);
    }
  }, [casess, setRole]);

  const callbackAndSetData = (params = {}, role = 0) => {
    setData(() => ({
      ...data,
      ...params,
    }));

    setRole(role);
  };
  //   redirect
  if (!loading && auth && code === 200) {
    if (redirect && apps.length === 1) {
      return (window.location = redirectTo);
    } else {
      let app = apps.filter((e) => e.slug === 'member')[0];
      let url = `https://${app.domain}?redirect=member&session_id=${key}`;
      return (window.location = url);
    }
  }

  // console.log(casess);

  return (
    <div className='wrapper-login'>
      <div className='login-container'>
        <Card className='p-2'>
          <CardBody>
            <Row className='mb-3'>
              <a href='https://giftano.com/'>
                <img
                  src='https://giftano.imgix.net/assets/Giftano-Logo-with-text.png?auto=format'
                  className='auth-logo mx-auto d-block'
                  alt={'Giftano logo'}
                />
              </a>
            </Row>
            {key !== '' && modulUserInfo.code === 200 ? (
              casess.change_password || casess.update_profile ? (
                <GetLayout
                  role={role}
                  callbackAndSetData={(params, role) => callbackAndSetData(params, role)}
                  dataUser={data}
                  casess={casess}
                  updateProfile={modulUserInfo.restData.update_profile}
                />
              ) : (
                <>
                  <h5 className='text-center'>Page Not Found</h5>
                </>
              )
            ) : (
              <h5 className='text-center'>Link Is not valid</h5>
            )}
          </CardBody>
        </Card>
      </div>
    </div>
  );
};

const GetLayout = (props) => {
  const { role, casess } = props;
  return (
    <>
      {(() => {
        switch (role) {
          case 1:
            return (
              <FormCreatePassword
                callback={(params, role) => props.callbackAndSetData(params, role)}
                casess={casess}
                dataUser={props.dataUser}
                updateProfile={props.updateProfile}
              />
            );
          case 2:
            return (
              <FormCreateFullname
                callback={(params, role) => props.callbackAndSetData(params, role)}
                dataUser={props.dataUser}
              />
            );
          case 3:
            return (
              <FormCreateBirthday
                callback={(params, role) => props.callbackAndSetData(params, role)}
                dataUser={props.dataUser}
              />
            );

          case 4:
            return (
              <FormCreateGender
                callback={(params, role) => props.callbackAndSetData(params, role)}
                dataUser={props.dataUser}
              />
            );

          default:
            return (
              <FormCreatePassword
                callback={(params, role) => props.callbackAndSetData(params, role)}
                dataUser={props.dataUser}
              />
            );
        }
      })()}
    </>
  );
};

export default Index;
