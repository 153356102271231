export const __linkValidate = (params) => {
  var re = /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9]+\.[a-zA-Z]{2,5}\.{0,1}/;
  if (!re.test(params)) {
    return false;
  }

  return true;
};

export const __emailValidate = (params) => {
  var re = /^\w+([-]?\w+)*@\w+([-]?\w+)*(\.\w{2,3})+$/;

  if (!re.test(params)) {
    return false;
  }
  return true;
};

export const __validEmail = (params) => {
  var re = /^\w+([-]?\w+)*@\w+([-]?\w+)*(\.\w{2,3})+$/;
  if (re.test(params)) return true;
  return false;
};

export const __validUrl = (params) => {
  var re = /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
  if (re.test(params)) {
    return true;
  }
  return false;
};

export const __validInt = (params) => {
  var re = /^[0-9]$/;
  if (re.test(params)) {
    return true;
  }

  return false;
};

export const __validateJustNumber = (params) => {
  var re = /^\d+$/gs;
  if (re.test(params)) {
    return true;
  }
  return false;
};

export const __validateDecimal = (params) => {
  if (params) {
    return true;
  }
  return false;
};

// string hanya huruf kapital dan kecil
// validate string_1
export const __validateString = (params) => {
  var re = /^[A-Za-z]+$/;
  if (re.test(params)) return true;
  return false;
};

// string hanya angka dan huruf
// validate string_2
export const __validateStringAndInt = (params) => {
  var re = /^[A-Za-z0-9]+$/;
  if (re.test(params)) return true;
  return false;
};
// string huruf angka huruf allowed space
// validate string_3
export const __validateStringAndIntAndSpace = (params) => {
  var re = /^[A-Za-z0-9\s]+$/;
  if (re.test(params)) return true;
  return false;
};

// string huruf angka huruf disallowed space, and charackter
// validate string_4
export const __validateStringAndCharackter_1 = (params) => {
  var re = /^[a-zA-Z0-9-_?\s]+$/;
  if (re.test(params)) return true;
  return false;
};

// string role password lowercase, number and upprecase combination
export const __validateRolePassword = (params) => {
  var valid = true;
  var number = /[0-9]/;
  var lowercase = /[a-z]/;
  var upprecase = /[A-Z]/;

  if (!number.test(params)) {
    valid = false;
  }

  if (!lowercase.test(params)) {
    valid = false;
  }

  if (!upprecase.test(params)) {
    valid = false;
  }

  return valid;
};
