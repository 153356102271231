import { CONNECTION } from '../../config/Connections';
import { CONFIG_ } from '../../config/Config';
import { notifDanger, notifSuccess } from '../../components';

var post = 'app';
var isLive = true;
var get = 'apps';
var path = process.env.REACT_APP_PATH_MANAGE_APP;

export const __detailDataGotoApp = (params) => {
  return (dispatch) => {
    const { param } = params;
    let config = CONFIG_({
      url: post + '/' + param,
      method: 'GET',
      live: isLive,
      path: path,
    });
    dispatch({ type: 'LOADING_GOTOAPP_APP' });
    CONNECTION(config)
      .then((response) => {
        const { code, data } = response;
        if (code === 200) {
          notifSuccess('Waiting for redirect...');
          return dispatch({
            type: 'SUCCESS_GOTOAPP_APP',
            message: 'Success get data',
            code: code,
            data: data,
          });
        }
        notifDanger('Something Wrong');
        return dispatch({ type: 'ERROR_GOTOAPP_APP', message: 'failed' });
      })
      .catch((response) => {
        console.log(response);
        return dispatch({
          type: 'ERROR_GOTOAPP_APP',
          message: 'Network error..',
        });
      });
  };
};

export const __getAppbyslug = (params) => {
  return (dispatch) => {
    let config = CONFIG_({
      url: encodeURI(get + '?q=slug=' + params),
      method: 'GET',
      live: isLive,
      path: path,
    });

    dispatch({ type: 'LOADING_GOTOAPP_APP' });
    CONNECTION(config)
      .then((response) => {
        // console.log(response);
        const { code, data, message } = response;
        if (code === 200) {
          return dispatch({
            type: 'SUCCESS_GOTOAPP_APP',
            data: data,
            code: code,
            message: message,
          });
        }
      })
      .catch((response) => {
        // console.log(response);
        const { message } = response;
        return dispatch({ type: 'ERROR_GOTOAPP_APP', message: message });
      });
  };
};
