import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Card,
  CardBody,
  Form,
  Row,
  FormGroup,
  FormText,
  Label,
} from 'reactstrap';
import { InputField } from '../../components';

import {
  __postDataValidateKey,
  __postResetPassword,
} from '../../modules/Reset';
// const locationWindow = window.location.origin;

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.fields = [createRef(), createRef()];
    this.state = {
      data: {
        action: 'reset',
        password: '',
        confirm_password: '',
      },
      error: '',
    };
  }

  componentDidMount() {
    const { match, loading } = this.props;
    const { params } = match;
    const { key } = params;
    const { data } = this.state;

    if (key !== undefined && !loading) {
      this.props.__postDataValidateKey({
        action: 'validatekey',
        key: key,
      });
      this.setState((state) => ({
        ...state,
        data: {
          ...data,
          key: key,
        },
      }));
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    var valid = true;
    var isValid = true;
    let { data, error } = this.state;
    let { password, confirm_password } = data;
    //trim password
    data.password = data.password.trim();
    data.confirm_password = data.confirm_password.trim();

    for (let index = 0; index < this.fields.length; index++) {
      valid = this.fields[index].current.validate();
      if (!valid) {
        isValid = false;
      }
    }

    if (isValid) {
      if (password !== confirm_password) {
        return this.setState((state) => ({
          ...state,
          error: 'Repassword not valid',
        }));
      }

      this.setState((state) => ({
        ...state,
        error: '',
      }));
    }
    if (isValid && error === '') {
      //   funtion to send data
      this.props.__postResetPassword(data);
    }
  };

  handleChange = (name, value) => {
    const { data } = this.state;
    this.setState((state) => ({
      ...state,
      data: {
        ...data,
        [name]: value,
      },
    }));
  };

  render() {
    const { modulValidateKey, modulResetPassword, showLogo } = this.props;
    const { loadingPage, code } = modulValidateKey;
    const { error } = this.state;
    return (
      <div className='wrapper-login '>
        <div className='login-container'>
          <Card className='py-2 px-2'>
            <CardBody>
              <Row className='mb-3'>
                {showLogo ? (
                  <a
                    href='https://giftano.com/'
                    className='w-100 '>
                    <img
                      src='https://giftano.imgix.net/assets/Giftano-Logo-with-text.png?auto=format'
                      className='auth-logo mx-auto d-block'
                      alt={'Giftano logo'}
                    />
                  </a>
                ) : (
                  ''
                )}
              </Row>
              {!loadingPage ? (
                <>
                  {code === 200 && modulResetPassword.code !== 200 ? (
                    <div className=''>
                      <h4 className=' mb-4 mt-4 text-center'>
                        Reset your Password
                      </h4>
                      <Form onSubmit={this.handleSubmit}>
                        <FormGroup>
                          <InputField
                            type='password'
                            label='Create your new password'
                            placeholder='Your password'
                            ref={this.fields[0]}
                            name='password'
                            onChange={this.handleChange}
                            validation={'required|match:password|min:8'}
                            disabled={modulResetPassword.loadingPage}
                          />
                        </FormGroup>

                        <FormGroup>
                          <InputField
                            type='password'
                            label='Repeat your password'
                            placeholder='Your confirm password'
                            ref={this.fields[1]}
                            name='confirm_password'
                            onChange={this.handleChange}
                            validation={'required|match:password|min:8'}
                            disabled={modulResetPassword.loadingPage}
                          />
                        </FormGroup>
                        <Label>
                          Your password needs to be at least 8 characters long
                        </Label>
                        {error !== '' ? (
                          <FormText color='danger'>{error}</FormText>
                        ) : (
                          ''
                        )}
                        <FormGroup className='text-right'>
                          <Button
                            type='submit'
                            color='primary'
                            className='w-100 my-3 btn-login'
                            disabled={modulResetPassword.loadingPage}>
                            {!modulResetPassword.loadingPage
                              ? 'Save'
                              : 'Loading...'}
                          </Button>
                        </FormGroup>
                      </Form>
                    </div>
                  ) : (
                    <>
                      {code === 400 ? (
                        <div className='login-container  p-3 '>
                          <Row>
                            <a href='https://giftano.com/'>
                              <img
                                src='https://giftano.imgix.net/assets/Giftano-Logo-with-text.png?auto=format'
                                className='auth-logo mx-auto d-block'
                                alt={'Giftano logo'}
                              />
                            </a>
                          </Row>
                          <h4 className='mt-4 mb-4 text-center'>
                            Expired token. Please request a new password reset
                            link.
                          </h4>

                          <a
                            className='btn btn-primary  w-100 btn-google m-0 btn-block btn-outline-primary text-secondary'
                            href='/'>
                            Go to Homepage
                          </a>
                        </div>
                      ) : (
                        ''
                      )}
                      {modulResetPassword.code === 200 ? (
                        <>
                          <div className='container text-center d-flex justify-content-center'>
                            <Row>
                              <div className='login-container p-3 '>
                                <Row>
                                  <img
                                    src='https://account.giftano.com/assets/images/reset-email.png'
                                    className='forgot-logo mx-auto'
                                    alt={'Reset Email'}
                                  />
                                </Row>
                                <h4 className=' mb-4 text-center w-100'>
                                  Your password has been reset
                                </h4>
                                <a
                                  className='btn btn-primary  w-100 btn-google m-0 btn-block btn-outline-primary text-secondary'
                                  href='https://giftano.com/'>
                                  Go to Login Page
                                </a>
                              </div>
                            </Row>
                          </div>
                        </>
                      ) : (
                        ''
                      )}
                    </>
                  )}
                </>
              ) : (
                <h6 className='text-center'>Loading...</h6>
              )}
            </CardBody>
          </Card>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ modulValidateKey, modulResetPassword }) => ({
  modulValidateKey,
  modulResetPassword,
});

const mapDispatchToProps = (dispatch) => ({
  __postDataValidateKey: (params) => [dispatch(__postDataValidateKey(params))],
  __postResetPassword: (params) => [dispatch(__postResetPassword(params))],
});

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
