import React, { Component } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';
import { LoginWithoutPassword, Page404, PageForgotPassword, PageVerify } from '../pages';
import LoginPage from '../pages/v2/login';
// import SignUpPage from "../pages/signUp";
import PageResetPassword from '../pages/v2/resetPassword';
// import PageWithoutPassword from '../pages/v2/withoutPassword'
// import SetupPage from '../pages/v2/signup'
import ButtonDinamyc from '../components/dinamyc/ButtonDinamyc';
// import MatomoProvider from './MatomoProvider';
// import { Loading } from "../components";

class Authentication extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.state = {
      classBackground: {
        url: 'https://giftano.imgix.net/assets/giftano-account.90d01e95e357473f85ab.png?auto=format',
      },
    };
  }

  componentDidMount() {
    const { modulContentStrapi } = this.props;
    const { code } = modulContentStrapi;
    if (code === 200) {
      this.setContent();
    }
    // console.log(modulContentStrapi);
  }

  setContent = () => {
    const { modulContentStrapi } = this.props;
    const { restData } = modulContentStrapi;
    const { portal_buttons, Background } = restData;
    this.setState((state) => ({
      ...state,
      buttons: portal_buttons,
      background: Background,
    }));
  };
  // action to dynamic content
  changeTitleState = (values) => {
    const { login_bg } = values;
    if (login_bg) {
      this.setState((state) => ({
        ...state,
        classBackground: login_bg,
      }));
    }
  };

  render() {
    const { codeCase, redirectBack } = this.props;
    const { classBackground } = this.state;
    return (
      <Container fluid className='layoutWrapper auth-page'>
        {/* <MatomoProvider> */}
        <Row className='h-100'>
          <Col
            lg='6'
            md='12'
            style={{ backgroundImage: `url(${classBackground.url})` }}
            className={'bg-account '}
          ></Col>
          <Col lg='6' md='8' sm='12' className='login-block'>
            <BrowserRouter basename='/'>
              <Switch>
                <Redirect from='/logout' to='/' />
                <Route
                  path={'/'}
                  exact
                  name='Login Page'
                  title='Login Page'
                  render={(props) => <LoginPage {...this.props} {...props} />}
                />
                <Route path='/verify/:token' exact={true} component={PageVerify} />

                <Route
                  path={'/forgot-password'}
                  exact
                  name='Login Page'
                  title='Signup Page'
                  render={() => <PageForgotPassword {...this.props} />}
                />
                <Route
                  path={'/reset-password/:key'}
                  name='Login Page'
                  title='Signup Page'
                  render={(props) => (
                    <PageResetPassword showLogo={true} {...this.props} {...props} />
                  )}
                />
                <Route
                  path={'/loginwithoutpassword/:key'}
                  name='Login Page'
                  title='Signup Page'
                  render={(props) => <LoginWithoutPassword {...this.props} {...props} />}
                />
                <Route path={'/*'} render={() => <Page404 {...this.props} />} />
              </Switch>
            </BrowserRouter>
          </Col>
          <ButtonDinamyc
            codeCase={codeCase}
            redirectBack={redirectBack}
            render={false}
            changeTitleState={(param) => this.changeTitleState(param)}
          />
        </Row>
        {/* </MatomoProvider> */}
      </Container>
    );
  }
}

const mapStateToProps = ({ modulContentStrapi }) => ({
  modulContentStrapi,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Authentication);
