import { CONNECTION } from '../../config/Connections';
import { CONFIG_ } from '../../config/Config';

var get = 'user-check/';
var path = process.env.REACT_APP_PATH_AUTH;
export const __getDataEmail = (params) => {
  return (dispatch) => {
    const { user } = params;
    let config = CONFIG_({
      url: get + user,
      method: 'GET',
      path: path,
    });
    dispatch({ type: 'LOADING_EMAIL_AUTH' });
    CONNECTION(config)
      .then((response) => {
        const { code, is_exist } = response;
        if (code === 200) {
          dispatch({ type: 'RESET_SIGNUP' });
          return dispatch({
            type: 'SUCCESS_EMAIL_AUTH',
            message: 'Success get data',
            data: is_exist,
            code: code,
          });
        }
        return dispatch({
          type: 'SUCCESS_EMAIL_AUTH',
          message: 'failed',
          code: 500,
        });
      })
      .catch((response) => {
        //console.log(response);
        return dispatch({
          type: 'SUCCESS_EMAIL_AUTH',
          message: 'Network error..',
          code: 500,
          errors: response,
        });
      });
  };
};
