import { CONNECTION } from '../../config/Connections';
import { CONFIG_ } from '../../config/Config';

var get = 'permission-modules';
var isLive = true;
var path = process.env.REACT_APP_PATH_AUTH;
var path_profile = process.env.REACT_APP_PATH_PROFILES;
var get_profile = 'get';

export const __getDataPermission = (params) => {
  const { session_id, appname } = params;
  const url = get_profile;
  return (dispatch) => {
    let config = CONFIG_({
      url: url,
      method: 'GET',
      live: isLive,
      path: path_profile,
      headers: { session_id: session_id },
    });

    if (appname) {
      config = CONFIG_({
        url: get + '/' + appname,
        method: 'GET',
        live: isLive,
        path: path,
        headers: { session_id: session_id },
      });
    }

    dispatch({ type: 'LOADING_PERMISSION_AUTH' });
    CONNECTION(config)
      .then((response) => {
        const { code } = response;

        if (code === 200) {
          if (response.profile === false && appname !== '') {
            return dispatch(__getDataPermission({ session_id: session_id }));
          }
          return dispatch({
            type: 'SUCCESS_PERMISSION_AUTH',
            message: 'Success get data',
            data: {
              ...response,
              profile:
                response.profile === undefined ? false : response.profile,
              session_id: session_id,
            },
            code: 200,
          });
        }

        return dispatch({
          type: 'ERROR_PERMISSION_AUTH',
          message: 'failed',
          code: 400,
          data: {
            profile: response.profile === undefined ? false : response.profile,
          },
        });
      })
      .catch((response) => {
        const { code } = response;
        return dispatch({
          type: 'ERROR_PERMISSION_AUTH',
          message: 'Network error..',
          code: code,
          data: {
            profile: response.profile === undefined ? false : response.profile,
          },
        });
      });
  };
};

export const manualGettingPerrmissionByApp = (params) => {
  const { session_id, appname, role_id } = params;
  // const url = get_profile;
  let config = CONFIG_({
    url: get + '/' + appname + '/' + role_id,
    method: 'GET',
    live: isLive,
    path: path,
    headers: { session_id: session_id },
  });
  return CONNECTION(config);
};

export const __manualGetProfleForGetRoleID = (params) => {
  const { session_id, appname } = params;

  const url = get_profile;

  let config = CONFIG_({
    url: url + '/' + appname,
    method: 'GET',
    live: isLive,
    path: path_profile,
    headers: { session_id: session_id },
  });

  return CONNECTION(config);
};

export const manualGettinPermission = (params) => {
  const { session_id } = params;
  const url = get_profile;

  // console.log(url);
  let config = CONFIG_({
    url: url,
    method: 'GET',
    live: isLive,
    path: path_profile,
    headers: { session_id: session_id },
  });
  return CONNECTION(config);
};

export const manualGetUserInfo = (params) => {
  const { session_id } = params;
  const url = 'user-info?without_profile_access=true';

  let config = CONFIG_({
    url: url,
    method: 'GET',
    live: isLive,
    path: path,
    headers: { session_id: session_id },
  });

  return CONNECTION(config);
};
