import { toast } from "react-toastify";
import { CatchError } from "../CatchError";
import {
  AddProfilePayment,
  DeleteProfilePayment,
  GetProfilePayment,
} from "./PaymentCRUD";

const initialState = {
  data: [],
  is_loaded: false,
  is_loading: false,
  is_send: false,
};

const ActionTypes = {
  SET_DATA: "PAYMENT_SET_DATA",
  START_LOADING: "PAYMENT_START_LOADING",
  STOP_LOADING: "PAYMENT_STOP_LOADING",
  START_SEND: "PAYMENT_START_SEND",
  STOP_SEND: "PAYMENT_STOP_SEND",
};

export const PaymentReducer = (state = initialState, action) => {
  let newState = { ...state };
  switch (action.type) {
    case ActionTypes.SET_DATA:
      newState.data = action.data || [];
      newState.is_loaded = true;
      break;
    case ActionTypes.START_LOADING:
      newState.is_loading = true;
      break;
    case ActionTypes.STOP_LOADING:
      newState.is_loading = false;
      break;
    default:
      newState.is_loading = true;
  }
  return newState;
};

export const PaymentActions = {
  loadPayments: () => async (dispatch) => {
    dispatch({ type: ActionTypes.START_LOADING });
    try {
      let res = await GetProfilePayment();
      if (res.code === 200) {
        dispatch({
          type: ActionTypes.SET_DATA,
          data: res.data.payment_methods
            ? res.data.payment_methods.map((i, idx) => ({ ...i, id: idx }))
            : [],
        });
      } else {
        toast.warning(res.message);
      }
    } catch (err) {
      CatchError(err);
    }
    dispatch({ type: ActionTypes.STOP_LOADING });
  },
  addPayment: (nonce) => async (dispatch) => {
    let ret;
    dispatch({ type: ActionTypes.START_SEND });
    try {
      let res = await AddProfilePayment(nonce);
      if (res.code === 200) {
        ret = res;
        toast.success("New Payment method saved");
        dispatch(PaymentActions.loadPayments());
      }
    } catch (err) {
      CatchError(err);
    }
    dispatch({ type: ActionTypes.STOP_SEND });
    return ret;
  },
  deletePayment: (token) => async (dispatch) => {
    let ret;
    dispatch({ type: ActionTypes.START_SEND });
    try {
      let res = await DeleteProfilePayment(token);
      if (res.code === 200) {
        ret = res;
        toast.success("Payment Method Deleted Successfull");
        dispatch(PaymentActions.loadPayments());
      }
    } catch (err) {
      CatchError(err);
    }
    dispatch({ type: ActionTypes.STOP_SEND });
    return ret;
  },
};
