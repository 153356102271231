import { CONNECTION } from '../../config/Connections';
import { CONFIG_ } from '../../config/Config';
import { notifDanger, notifSuccess } from '../../components';

var post = 'login-without-password';
var path = process.env.REACT_APP_PATH_AUTH;
export const __postDataLoginwithoutpassword = (params) => {
  return (dispatch) => {
    let config = CONFIG_({
      url: post,
      data: params,
      method: 'POST',
      path: path
    });
    dispatch({ type: 'LOADING_LOGINWITHOUTPASSWORD_AUTH' });
    CONNECTION(config)
      .then((response) => {
        const { code } = response;
        if (code === 200) {
          notifSuccess('Email has been sent successfully');
          return dispatch({
            type: 'SUCCESS_LOGINWITHOUTPASSWORDSENDEMAIL_AUTH',
            message: 'Success post data',
            code: 200
          });
        }

        notifDanger('Sorry, we’re unable to send OTP to your email.');
        return dispatch({
          type: 'SUCCESS_LOGINWITHOUTPASSWORDSENDEMAIL_AUTH',
          message: 'failed'
        });
      })
      .catch((response) => {
        notifDanger('Sorry, we’re unable to send OTP to your email');
        // console.log(response)
        return dispatch({
          type: 'SUCCESS_LOGINWITHOUTPASSWORDSENDEMAIL_AUTH',
          message: 'Network error..',
          errors: response
        });
      });
  };
};

export const __postVerifyLoginwithoutpassword = (params) => {
  return (dispatch) => {
    let config = CONFIG_({
      url: post,
      data: params,
      method: 'POST',
      path: path
    });
    dispatch({ type: 'LOADING_LOGINWITHOUTPASSWORD_AUTH' });
    CONNECTION(config)
      .then((response) => {
        const { code, data } = response;
        if (code === 200) {
          notifSuccess('Account valid');
          return dispatch({
            type: 'SUCCESS_LOGINWITHOUTPASSWORD_AUTH',
            message: 'Success post data',
            code: 200,
            data: data
          });
        }
        notifDanger('Account invalid');
        return dispatch({
          type: 'SUCCESS_LOGINWITHOUTPASSWORD_AUTH',
          message: 'failed',
          code: 401
        });
      })
      .catch((response) => {
        // console.log(response);
        return dispatch({
          type: 'ERROR_LOGINWITHOUTPASSWORD_AUTH',
          message: 'Network error..',
          errors: response
        });
      });
  };
};
